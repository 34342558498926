import { Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import {
  DefinedProperty,
  useQuickCardStyles,
  VisitCardProps,
  VisitQuickCard,
} from "../../components";
import { NurseVisit } from "../../core";
import { renderTime } from "../../helpers";

export const NurseVisitCard: FunctionComponent<VisitCardProps> = ({
  visit: rawVisit,
  ...props
}) => {
  const classes = useQuickCardStyles();
  const visit = rawVisit as NurseVisit;

  const serviceNames = visit.services.map((service): string => {
    if (typeof service === "string") {
      return service;
    }

    return service.name;
  });

  return (
    <VisitQuickCard {...props} visit={rawVisit}>
      <Grid
        item
        xs={5}
        className={`${classes.content} ${classes.afterDivider}`}
      >
        <DefinedProperty title="Services" noWrap>
          {serviceNames.join(", ")}
        </DefinedProperty>
      </Grid>
      <Grid item xs={1} className={classes.content}>
        <DefinedProperty title="Status">{visit.status}</DefinedProperty>
      </Grid>
      <Grid item xs={2} className={classes.content}>
        <DefinedProperty title="Order Time">
          {renderTime(visit.createdAt)}
        </DefinedProperty>
      </Grid>
    </VisitQuickCard>
  );
};
