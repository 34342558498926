import {
  Interpreter,
  State,
  StateMachine,
  DoneInvokeEvent,
  ErrorExecutionEvent,
} from "xstate";

import { VideoVisit } from "../../../core";

export interface VideoVisitDetailsContext {
  errors?: string[];
  visit: VideoVisit;
}

export interface VideoVisitDetailsSchema {
  states: {
    accepted: {};
    accepting: {};
    fetched: {};
    fetching: {};
    locked: {};
    unfetched: {};
  };
}

// Events

export interface AcceptVisitEvent {
  type: "VV.ACCEPT";
}

// Aggregates

export type VideoVisitDetailsEvents =
  | AcceptVisitEvent
  | DoneInvokeEvent<VideoVisit>
  | ErrorExecutionEvent;

export const isDoneEvent = (
  event: VideoVisitDetailsEvents,
): event is DoneInvokeEvent<VideoVisit> =>
  (event as DoneInvokeEvent<VideoVisit>).data !== undefined;

export type VideoVisitDetailsState = State<
  VideoVisitDetailsContext,
  VideoVisitDetailsEvents,
  VideoVisitDetailsSchema
>;

export type VideoVisitDetailsMachine = StateMachine<
  VideoVisitDetailsContext,
  VideoVisitDetailsSchema,
  VideoVisitDetailsEvents
>;

export type VideoVisitDetailsService = Interpreter<
  VideoVisitDetailsContext,
  VideoVisitDetailsSchema,
  VideoVisitDetailsEvents
>;

// Hooks

type SendAccept = () => void;

interface VideoVisitDetailsHookActions {
  accept: SendAccept;
}

interface VideoVisitDetailsHookSelectors {
  didFail: boolean;
  errors: string[];
  visit: VideoVisit;
  isFetching: boolean;
}

export type VideoVisitDetailsServiceHook = [
  VideoVisitDetailsHookSelectors,
  VideoVisitDetailsHookActions,
];
