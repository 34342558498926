import { ButtonClassKey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

export const MuiButton: Partial<StyleRules<ButtonClassKey>> = {
  root: {
    padding: "12px 16px",
    minWidth: 100,
  },
  textSizeSmall: {
    fontSize: "0.813rem",
    fontWeight: "normal",
    lineHeight: 1.85,
    letterSpacing: 0,
  },
  outlined: {
    padding: "12px 16px",
  },
  text: {
    padding: "12px 16px",
  },
};
