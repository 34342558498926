import { Machine } from "xstate";

import { saveError, saveVisits } from "./actions";
import { FETCH, FETCHING, READY } from "./constants";
import { fetchAllVideoVisits } from "./promises";
import {
  VideoVisitsEvents,
  VideoVisitsContext,
  VideoVisitsSchema,
} from "./types";

export const videoVisitsMachine = Machine<
  VideoVisitsContext,
  VideoVisitsSchema,
  VideoVisitsEvents
>(
  {
    id: "video-visits",
    initial: FETCHING,
    context: {
      errors: [],
      visits: [],
    },
    states: {
      [FETCHING]: {
        invoke: {
          src: fetchAllVideoVisits,
          id: "fetch-all",
          onDone: {
            target: READY,
            actions: "saveVisits",
          },
          onError: {
            target: READY,
            actions: "saveError",
          },
        },
      },
      [READY]: {
        on: {
          [FETCH]: FETCHING,
        },
      },
    },
  },
  {
    actions: {
      saveError,
      saveVisits,
    },
  },
);
