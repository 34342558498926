import { Machine } from "xstate";

import { NurseVisit } from "../../../core";

import { saveError, saveMessage, saveVisit } from "./actions";
import {
  CLOSE,
  CLOSED,
  CLOSING,
  FAIL,
  FETCHING,
  READY,
  SUCCEED,
  UNFETCHED,
  UNSAVED,
} from "./constants";
import { isNurseVisitClosed } from "./guards";
import { closeNurseVisit, fetchDetails } from "./promises";
import {
  NurseVisitDetailsEvents,
  NurseVisitDetailsContext,
  NurseVisitDetailsSchema,
} from "./types";

export const createNurseVisitDetailsMachine = (visit: NurseVisit) =>
  Machine<
    NurseVisitDetailsContext,
    NurseVisitDetailsSchema,
    NurseVisitDetailsEvents
  >(
    {
      id: `nurse-visit-${visit.id}`,
      initial: UNFETCHED,
      context: {
        visit,
      },
      states: {
        [UNFETCHED]: {
          on: {
            "": FETCHING,
          },
        },
        [FETCHING]: {
          invoke: {
            src: fetchDetails,
            id: "fetch-details",
          },
          on: {
            [SUCCEED]: {
              target: READY,
              actions: "saveVisit",
            },
            [FAIL]: {
              target: UNFETCHED,
              actions: "saveError",
            },
          },
        },
        [READY]: {
          always: {
            target: CLOSED,
            cond: {
              type: "isNurseVisitClosed",
            },
          },
          on: {
            [CLOSE]: {
              target: CLOSING,
              actions: "saveMessage",
            },
          },
        },
        [CLOSING]: {
          invoke: {
            src: closeNurseVisit,
            id: "close-visit",
          },
          on: {
            [SUCCEED]: {
              target: CLOSED,
              actions: "saveVisit",
            },
            [FAIL]: {
              target: UNSAVED,
              actions: "saveError",
            },
          },
        },
        [UNSAVED]: {
          on: {
            [CLOSE]: {
              target: CLOSING,
              actions: "saveMessage",
            },
          },
        },
        [CLOSED]: {
          type: "final",
        },
      },
    },
    {
      actions: {
        saveError,
        saveMessage,
        saveVisit,
      },
      guards: {
        isNurseVisitClosed,
      },
    },
  );
