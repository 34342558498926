import { Interpreter, State, StateMachine, DoneInvokeEvent } from "xstate";

import { Clinic, VideoVisit, Patient, ProviderUser, User } from "../../../core";

interface BaseVisitData {
  provider?: ProviderUser;
  clinic?: Clinic;
}

export interface VisitFromPatient extends BaseVisitData {
  patient: Patient;
  user?: User;
}

export interface VisitFromNumber extends BaseVisitData {
  phone?: string;
}

export type VideoVisitData = VisitFromPatient | VisitFromNumber;

export interface CreateVideoVisitContext {
  data?: VideoVisitData;
  errors?: string;
  visit?: VideoVisit;
}

export interface CreateVideoVisitSchema {
  states: {
    creating: {};
    saved: {};
    unsaved: {};
  };
}

// Events

export type CreateEvent = {
  type: "NV.CREATE";
  data: VideoVisitData;
};

export interface FailEvent {
  type: "NV.FAIL";
  errors: string[];
}

export type SucceedEvent = { type: "NV.SUCCEED" };

// Aggregates

export type CreateVideoVisitEvents = CreateEvent | DoneInvokeEvent<any>;

export const isDoneEvent = (
  event: CreateVideoVisitEvents,
): event is DoneInvokeEvent<any> =>
  (event as DoneInvokeEvent<any>).data !== undefined;

export type CreateVideoVisitState = State<
  CreateVideoVisitContext,
  CreateVideoVisitEvents,
  CreateVideoVisitSchema
>;

export type CreateVideoVisitMachine = StateMachine<
  CreateVideoVisitContext,
  CreateVideoVisitSchema,
  CreateVideoVisitEvents
>;

export type CreateVideoVisitService = Interpreter<
  CreateVideoVisitContext,
  CreateVideoVisitSchema,
  CreateVideoVisitEvents
>;

// Hooks

type SendCreate = (visit: VideoVisitData) => void;

interface CreateVideoVisitHookActions {
  create: SendCreate;
}

interface CreateVideoVisitHookSelectors {
  didCreate: boolean;
  didFail: boolean;
  errors: string;
  visit: VideoVisit;
}

export type CreateVideoVisitServiceHook = [
  CreateVideoVisitHookSelectors,
  CreateVideoVisitHookActions,
];
