import { assign } from "xstate";

import {
  CreateVideoVisitContext,
  CreateVideoVisitEvents,
  isDoneEvent,
} from "./types";

export const saveData = assign<CreateVideoVisitContext, CreateVideoVisitEvents>(
  {
    data: (_context, event) => (isDoneEvent(event) ? event.data : undefined),
    errors: undefined,
  },
);

export const saveError = assign<
  CreateVideoVisitContext,
  CreateVideoVisitEvents
>({
  errors: (_context, event) =>
    event.type !== "error.execution" ? "" : event.data.toString(),
});

export const saveVisit = assign<
  CreateVideoVisitContext,
  CreateVideoVisitEvents
>({
  data: undefined,
  errors: undefined,
});
