import {
  AudioTrackPublication,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Track,
  TrackPublication,
  VideoTrackPublication,
} from "twilio-video";

export const isLocalVideoTrack = (track: Track): track is LocalVideoTrack =>
  track.name.includes("camera");

export const isVideoTrack = (track: Track): boolean =>
  track.name.includes("camera");

export const isScreensharePublicationTrack = (
  track: TrackPublication,
): boolean => track.trackName.includes("screen");

export const isAudioPublicationTrack = (
  track: TrackPublication,
): track is AudioTrackPublication =>
  (track as AudioTrackPublication).kind === "audio";

export const isVideoPublicationTrack = (
  track: TrackPublication,
): track is VideoTrackPublication =>
  (track as VideoTrackPublication).kind === "video";

export const isLocalTrackAudio = (
  track: LocalTrack,
): track is LocalAudioTrack => track.kind === "audio";

export const isLocalTrackVideo = (
  track: LocalTrack,
): track is LocalVideoTrack => track.kind === "video";

type LocalMediaTrack = LocalAudioTrack | LocalVideoTrack;

export const isLocalTrackDefined = (
  track?: LocalMediaTrack,
): track is LocalMediaTrack => track !== undefined;
