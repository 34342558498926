import { useDominantSpeaker } from "./use-dominant-speaker";
import { useParticipants } from "./use-participants";
import { useScreenShareParticipant } from "./use-screen-share-participant";
import { useSelectedParticipant } from "./use-selected-participant";
import { useVideoContext } from "./use-video-context";

export const useMainParticipant = () => {
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const dominantSpeaker = useDominantSpeaker();
  const participants = useParticipants();
  const {
    room: { localParticipant },
  } = useVideoContext();

  if (selectedParticipant) {
    return selectedParticipant;
  }

  if (screenShareParticipant) {
    return screenShareParticipant;
  }

  if (dominantSpeaker) {
    return dominantSpeaker;
  }

  if (participants.length > 0) {
    return participants[0];
  }

  return localParticipant;
};
