import React, { FunctionComponent } from "react";
import { Redirect, RouteProps, Switch, useLocation } from "react-router-dom";

import { FlagProvider } from "./components";
import { PATH_LOGIN } from "./constants";
import { useAnalytics } from "./core";
import { AppLayout } from "./layouts";
import { useAppService } from "./state";

export const AuthenticatedSwitch: FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const [{ isLoggedIn, user }] = useAppService();
  const location = useLocation();
  const analytics = useAnalytics();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN,
          state: { from: location },
        }}
      />
    );
  }

  analytics.login(user!);

  return (
    <FlagProvider>
      <AppLayout>
        <Switch {...rest}>{children}</Switch>
      </AppLayout>
    </FlagProvider>
  );
};
