import { TabClassKey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

import { blackPearl } from "../palette";
import { typography } from "../typography";

const breakpoints = createBreakpoints({});

export const MuiTab: Partial<StyleRules<TabClassKey>> = {
  root: {
    ...typography.body1,
    minWidth: 88,
    textTransform: "none",
    [breakpoints.up("sm")]: {
      marginRight: 16,
      minWidth: "initial",
      padding: `8px 16px 8px 0`,
    },
  },
  textColorPrimary: {
    color: blackPearl,
    fontWeight: "lighter",
    "&$selected": {
      color: blackPearl,
      fontWeight: "normal",
    },
  },
};
