// States
export const LOGGED_IN = "loggedIn";
export const LOGGING_OUT = "loggingOut";
export const NOT_LOGGED_IN = "notLoggedIn";
export const MAYBE_VERIFY = "maybeVerify";
export const VERIFYING = "verifying";
export const MAYBE_ONBOARD = "maybeOnboard";
export const ONBOARDING = "onboarding";
export const MAYBE_HYDRATE_CLINIC = "maybeHydrateClinic";
export const HYDRATING_CLINIC = "hydratingClinic";
export const READY = "ready";
export const CHECKING_SESSION = "checkingSession";

// Private Events
export const CHANGE_CLINIC = "APP.CHANGE_CLINIC";
export const CHECK_SESSION = "APP.CHECK_SESSION";
export const END_SESSION = "APP.END_SESSION";
export const FINISH_LOGOUT = "APP.FINISH_LOGOUT";
export const SET_USER = "APP.SET_USER";
export const GET_FULL_CLINIC_FAIL = "CL.FAIL";
export const GET_FULL_CLINIC_SUCCEED = "CL.SUCCEED";
