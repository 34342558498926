import React, { FunctionComponent, useEffect } from "react";

import { usePageTitle } from "../hooks";

export const DashboardPage: FunctionComponent = () => {
  const setTitle = usePageTitle();

  useEffect(() => {
    setTitle("Dashboard");
  });

  return <div>Page</div>;
};
