import { useMachine } from "@xstate/react";
import { useEffect, useMemo } from "react";

import { logService } from "../../../helpers";

import { FETCH, READY } from "./constants";
import { videoVisitsMachine } from "./machine";
import { VideoVisitsServiceHook } from "./types";

// Exposed API Below
export * from "./types";

export const useVideoVisitsService = (): VideoVisitsServiceHook => {
  const [state, send, nvService] = useMachine(videoVisitsMachine);

  useEffect(() => {
    logService("video-visits", nvService);
  }, [nvService]);

  const isReady = state.matches(READY) || false;
  const { errors, visits } = state.context;

  const filteredVisits = useMemo(
    () => visits.filter((visit) => visit.status !== ""),
    [visits],
  );

  const fetch = () => {
    send(FETCH);
  };

  return [{ errors, isReady, visits: filteredVisits }, { fetch }];
};
