const numberFormatter = Intl.NumberFormat("en-US", {
  currency: "USD",
});

const currencyFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const renderCurrency = (value: number): string =>
  currencyFormatter.format(value);

export const renderNumber = (value: number): string =>
  numberFormatter.format(value);
