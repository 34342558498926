import React, { FunctionComponent, useEffect } from "react";

import { usePageTitle } from "../hooks";

interface ComingSoonProps {
  title?: string;
}

export const ComingSoonPage: FunctionComponent<ComingSoonProps> = ({
  title = "Coming Soon",
}) => {
  const setTitle = usePageTitle();

  useEffect(() => {
    setTitle(title);
  });

  return <div>Page Coming Soon</div>;
};
