import { createContext, useContext } from "react";

export enum UpdateState {
  ready = "READY",
  cached = "CACHED",
  update = "UPDATE",
}

export interface UpdateStateContext {
  state: UpdateState;
  handleReset: () => void;
  handleReload: () => void;
}

export const updateContext = createContext<UpdateStateContext>({
  state: UpdateState.ready,
  handleReset: () => null,
  handleReload: () => null,
});

export const useUpdateState = () => useContext(updateContext);
