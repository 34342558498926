import { AUTHENTICATION_MACHINE } from "../../constants";
import { logService } from "../../helpers";

import {
  FAILED,
  FORGET_EMAIL,
  FORGET_PASSWORD,
  LOGIN,
  PASSWORD_RESET,
  RESET_PASSWORD,
  RESET,
  SENT_EMAIL,
  SENT_RESET,
} from "./constants";
import {
  AuthenticationService,
  AuthServiceHook,
  ResetPasswordData,
} from "./types";

// Exposed API Below
export { authMachine } from "./machine";
export * from "./types";

export const useAuthService = (
  authService?: AuthenticationService,
): AuthServiceHook => {
  logService(AUTHENTICATION_MACHINE, authService);

  // Selectors
  const didFail = authService?.state.matches(FAILED) || false;
  const didResetPassword = authService?.state.matches(PASSWORD_RESET) || false;
  const didSendEmail = authService?.state.matches(SENT_EMAIL) || false;
  const didSendReset = authService?.state.matches(SENT_RESET) || false;
  const errors = authService?.state.context.errors || "";

  // Actions
  const forgetEmail = (mobile: string) => {
    authService?.send({
      type: FORGET_EMAIL,
      mobile,
    });
  };

  const forgetPassword = (email: string) => {
    authService?.send({
      type: FORGET_PASSWORD,
      email,
    });
  };

  const login = (email: string, password: string) => {
    authService?.send({
      type: LOGIN,
      email,
      password,
    });
  };

  const reset = () => {
    authService?.send({
      type: RESET,
    });
  };

  const resetPassword = (data: ResetPasswordData) => {
    authService?.send({
      ...data,
      type: RESET_PASSWORD,
    });
  };

  return [
    { didFail, didResetPassword, didSendEmail, didSendReset, errors },
    { forgetEmail, forgetPassword, login, reset, resetPassword },
  ];
};
