export const CLOSED = "closed";
export const CLOSING = "closing";
export const FETCHING = "fetching";
export const READY = "ready";
export const SAVING = "saving";
export const UNFETCHED = "unfetched";
export const UNSAVED = "unsaved";

export const CLOSE = "NV.CLOSE";
export const FAIL = "NV.FAIL";
export const SUCCEED = "NV.SUCCEED";
