import { InputLabelClassKey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

import { inputPlaceholder } from "../palette";

export const MuiInputLabel: Partial<StyleRules<InputLabelClassKey>> = {
  root: {
    color: inputPlaceholder,
  },
};
