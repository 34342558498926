import { createContext, useContext } from "react";

const defContext = {
  isFinished: false,
  canEdit: false,
};

const visitContext = createContext(defContext);

export const useVisitContext = () => useContext(visitContext);

export const VisitsContextProvider = visitContext.Provider;
