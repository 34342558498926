import {
  formatPhoneNumber,
  isApiError,
  register,
  marshallUserProvider,
} from "../../../core";

import { FAIL, SUCCEED } from "./constants";
import { RegistrationContext, SuccesfullRegistration } from "./types";

export const createRegisterPromise = async (
  context: RegistrationContext,
): Promise<SuccesfullRegistration> => {
  try {
    const response = await register({
      email: context.email,
      acceptedTerms: context.acceptedTerms,
      password1: context.password,
      password2: context.confirmation,
      phone1: formatPhoneNumber(context.mobile),
    });
    return {
      token: response.key,
      user: marshallUserProvider(response.user),
    };
  } catch (error) {
    const messages = await error.response.json();

    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};
