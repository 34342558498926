import { useEffect, useState } from "react";
import { RemoteParticipant } from "twilio-video";

import { RoomParticipantEvent } from "./types";
import { useVideoContext } from "./use-video-context";

export const useDominantSpeaker = () => {
  const { room } = useVideoContext();
  const [dominantSpeaker, setDominantSpeaker] = useState(room.dominantSpeaker);

  useEffect(() => {
    const maybeChangeFocus = (newDominantSpeaker?: RemoteParticipant) => {
      if (newDominantSpeaker) {
        setDominantSpeaker(newDominantSpeaker);
      }
    };

    const maybeUnsetSpeaker = (participant: RemoteParticipant) => {
      setDominantSpeaker((prevDominantSpeaker) => {
        return prevDominantSpeaker === participant ? null : prevDominantSpeaker;
      });
    };

    room.on(RoomParticipantEvent.speakerChanged, maybeChangeFocus);
    room.on(RoomParticipantEvent.disconnected, maybeUnsetSpeaker);

    return () => {
      room.off(RoomParticipantEvent.speakerChanged, maybeChangeFocus);
      room.off(RoomParticipantEvent.disconnected, maybeUnsetSpeaker);
    };
  }, [room]);

  return dominantSpeaker;
};
