import { useContext } from "react";

import { logger } from "../../core";
import { VideoContext } from "../../helpers";

export const useVideoContext = () => {
  const context = useContext(VideoContext);

  if (!context) {
    logger.error(
      "useVideoContext must be used within the context of a VideoProvider.",
    );
  }

  return context;
};
