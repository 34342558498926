import React, { FunctionComponent } from "react";

import { EditableField } from "../../components";
import { VideoVisit } from "../../core";

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

export const PrimaryComplaint: FunctionComponent<VisitDetail> = ({
  onChange = () => null,
  visit,
}) => {
  const handleChange = (newValue: string) => {
    onChange({ primaryComplaint: newValue });
  };

  return (
    <EditableField
      label="Chief Complaint"
      onSave={handleChange}
      value={visit.primaryComplaint}
    />
  );
};
