import {
  Interpreter,
  State,
  StateMachine,
  DoneInvokeEvent,
  ErrorExecutionEvent,
} from "xstate";

import {
  Clinic,
  NurseVisit,
  NurseVisitService as Service,
  Patient,
  ProviderUser,
  User,
  VideoVisit,
} from "../../../core";

interface BaseVisitData {
  provider?: ProviderUser;
  clinic?: Clinic;
}

interface VisitFromPatient extends BaseVisitData {
  patient: Patient;
  user?: User;
}

interface VisitFromNumber extends BaseVisitData {
  phone?: string;
}

interface VisitFromVideoVisit extends BaseVisitData {
  patient: Patient;
  user?: User;
  videoVisit: string | VideoVisit;
}

export type NurseVisitData =
  | VisitFromPatient
  | VisitFromNumber
  | VisitFromVideoVisit;

export interface CreateNurseVisitContext {
  data?: NurseVisitData;
  errors?: string;
  services: Service[];
  visit?: NurseVisit;
}

export interface CreateNurseVisitSchema {
  states: {
    creating: {};
    fetchingServices: {};
    ready: {};
    saved: {};
    saving: {};
    unsaved: {};
  };
}

// Events

export type CreateEvent = {
  type: "NV.CREATE";
  data: NurseVisitData;
};

// export interface FailEvent {
//   type: "NV.FAIL";
//   errors: string[];
// }

// export interface ParseServicesEvent {
//   type: "NV.PARSE_SERVICES";
//   services: Service[];
// }

export interface UpdateEvent {
  type: "NV.UPDATE";
  visit: NurseVisit;
}

// export interface SucceedEvent {
//   type: "NV.SUCCEED";
//   visit: NurseVisit;
// }

// Aggregates

export type CreateNurseVisitEvents =
  | CreateEvent
  | UpdateEvent
  | DoneInvokeEvent<any>
  | ErrorExecutionEvent;

export const isDoneEvent = <TData = {}>(
  event: CreateNurseVisitEvents,
): event is DoneInvokeEvent<TData> =>
  (event as DoneInvokeEvent<TData>).data !== undefined;

export type CreateNurseVisitState = State<
  CreateNurseVisitContext,
  CreateNurseVisitEvents,
  CreateNurseVisitSchema
>;

export type CreateNurseVisitMachine = StateMachine<
  CreateNurseVisitContext,
  CreateNurseVisitSchema,
  CreateNurseVisitEvents
>;

export type CreateNurseVisitService = Interpreter<
  CreateNurseVisitContext,
  CreateNurseVisitSchema,
  CreateNurseVisitEvents
>;

// Hooks

type SendCreate = (visit: NurseVisitData) => void;

type SendUpdate = (visit: NurseVisit) => void;

interface CreateNurseVisitHookActions {
  create: SendCreate;
  update: SendUpdate;
}

interface CreateNurseVisitHookSelectors {
  didFail: boolean;
  didFinishedSuccessfull: boolean;
  errors: string;
  services: Service[];
  visit: NurseVisit;
}

export type CreateNurseVisitServiceHook = [
  CreateNurseVisitHookSelectors,
  CreateNurseVisitHookActions,
];
