import { Button, makeStyles, Menu } from "@material-ui/core";
import { Add, KeyboardArrowUp } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, MouseEvent, useState } from "react";

import {
  FLAG_NURSE_VISIT,
  FLAG_START_VIDEO_VISIT,
  FLAG_VIDEO_VISIT,
} from "../../constants";
import { useAnalytics } from "../../core";
import { StartNurseVisit } from "../../nurse-visits";
import { useAppService } from "../../state";
import { narrowDrawerWidth } from "../../styles";
import { StartVideoVisit } from "../../video-visits";

const useAddVisitStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(2),
  },
  menu: {
    width: narrowDrawerWidth - theme.spacing(5),
  },
}));

const NURSE = "nurse";
const VIDEO = "video";

export const StartVisitButton: FunctionComponent = () => {
  const [{ user }] = useAppService();
  const classes = useAddVisitStyles();
  const { track } = useAnalytics();
  const flags = useFlags();
  const nurseVisitAvailable = user?.currentClinic?.nurseVisitAvailable ?? false;
  const canNurse = flags[FLAG_NURSE_VISIT] && nurseVisitAvailable;
  const canVideo = flags[FLAG_VIDEO_VISIT] && flags[FLAG_START_VIDEO_VISIT];
  const canButton = flags.startVisit;

  // available nurseVisitAvailable

  const canSee = canButton && (canNurse || canVideo);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [activeVisit, setActiveVisit] = useState<string | null>(null);

  const isOpen = Boolean(anchorEl);

  const toggleMenu = (event: MouseEvent<HTMLButtonElement> | {}) => {
    if (!isOpen) {
      track("start.click");
    }
    const newTarget = isOpen
      ? null
      : (event as MouseEvent<HTMLButtonElement>).currentTarget;
    setAnchorEl(newTarget);
  };

  const startVisit = (type: string) => () => {
    toggleMenu({});
    track("start.type", { type });
    setActiveVisit(type);
  };

  const cancelVisit = () => {
    setActiveVisit(null);
  };

  if (!canSee) {
    return null;
  }

  let nurseVisit = null;
  if (canNurse) {
    nurseVisit = (
      <StartNurseVisit
        onCancel={cancelVisit}
        onStart={startVisit(NURSE)}
        open={activeVisit === NURSE}
      />
    );
  }

  let videoVisit = null;
  if (canVideo) {
    videoVisit = (
      <StartVideoVisit
        onCancel={cancelVisit}
        onStart={startVisit(VIDEO)}
        open={activeVisit === VIDEO}
      />
    );
  }

  return (
    <>
      <Button
        aria-controls="add-visit-menu"
        aria-haspopup="true"
        classes={{ root: classes.button }}
        endIcon={<KeyboardArrowUp />}
        onClick={toggleMenu}
        startIcon={<Add />}
      >
        Start New Visit
      </Button>
      <Menu
        id="add-visit-menu"
        anchorEl={anchorEl}
        classes={{ paper: classes.menu }}
        keepMounted
        open={isOpen}
        onClose={toggleMenu}
        transformOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        {videoVisit}
        {nurseVisit}
      </Menu>
    </>
  );
};
