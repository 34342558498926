import { Machine } from "xstate";

import {
  saveData,
  saveError,
  saveServices,
  saveVisit,
  updateVisitData,
} from "./actions";
import {
  CREATE,
  CREATING,
  FETCHING_SERVICES,
  READY,
  SAVED,
  SAVING,
  UNSAVED,
  UPDATE,
  PARSE_SERVICES,
} from "./constants";
import { createNurseVisit, fetchServices, updateNurseVisit } from "./promises";
import {
  CreateNurseVisitEvents,
  CreateNurseVisitContext,
  CreateNurseVisitSchema,
} from "./types";

export const createNurseVisitMachine = Machine<
  CreateNurseVisitContext,
  CreateNurseVisitSchema,
  CreateNurseVisitEvents
>(
  {
    id: "create-nurse-visit",
    initial: UNSAVED,
    context: {
      services: [],
    },
    states: {
      [UNSAVED]: {
        on: {
          [CREATE]: {
            target: CREATING,
            actions: "saveData",
          },
        },
      },
      [CREATING]: {
        invoke: {
          src: createNurseVisit,
          id: "create",
          onDone: {
            target: READY,
            actions: "saveVisit",
          },
          onError: {
            target: UNSAVED,
            actions: "saveError",
          },
        },
      },
      [READY]: {
        always: {
          target: FETCHING_SERVICES,
          cond: (context) => context.services.length === 0,
        },
        on: {
          [UPDATE]: {
            target: SAVING,
            actions: "updateVisitData",
          },
        },
      },
      [FETCHING_SERVICES]: {
        invoke: {
          src: fetchServices,
          id: "fetch-services",
          onDone: {
            target: READY,
            actions: "saveServices",
          },
        },
        // on: {
        //   [PARSE_SERVICES]: {
        //     target: READY,
        //     actions: "saveServices",
        //   },
        // },
      },
      [SAVING]: {
        invoke: {
          src: updateNurseVisit,
          id: "update-nurse-visit",
          onDone: {
            target: SAVED,
            actions: "saveVisit",
          },
          onError: {
            target: READY,
            actions: "saveError",
          },
        },
      },
      [SAVED]: {
        type: "final",
      },
    },
  },
  {
    actions: {
      saveData,
      saveError,
      saveServices,
      saveVisit,
      updateVisitData,
    },
  },
);
