import { ChangeEventHandler, useState } from "react";
import { useDebounce } from "react-use";
import { useRifm } from "rifm";

import { validationDelay } from "../config";
import { renderPhoneNumber, validatePhone } from "../core";

export type ValidatedPhoneState = [
  string,
  boolean,
  ChangeEventHandler<HTMLInputElement>,
];

export const useValidatedPhone = (
  initialValue = "",
  allowEmpty = true,
  initialValidation = true,
): ValidatedPhoneState => {
  const [mobile, changeMobile] = useState(initialValue);

  const [isValid, changeValidation] = useState(initialValidation);
  useDebounce(
    () => changeValidation(validatePhone(mobile, allowEmpty)),
    validationDelay,
    [mobile],
  );

  const { value, onChange } = useRifm({
    format: renderPhoneNumber,
    value: mobile,
    onChange: changeMobile,
  });

  return [value, isValid, onChange];
};
