import { Clinic, isUserProvider } from "@relieftelemed/platform";
import { ConditionPredicate } from "xstate";

import { ApplicationContext, ApplicationEvents } from "./types";

export const hasUserOnboarded: ConditionPredicate<
  ApplicationContext,
  ApplicationEvents
> = (context): boolean => {
  return context.user === undefined ? false : isUserProvider(context.user);
};

export const hasUserVerified: ConditionPredicate<
  ApplicationContext,
  ApplicationEvents
> = (context): boolean => {
  return context.user === undefined ? false : context.user.verifiedMobile;
};

const isFullClinicData = (currentClinic?: Clinic): currentClinic is Clinic =>
  currentClinic?.createdAt !== undefined;

export const hasFullClinicData: ConditionPredicate<
  ApplicationContext,
  ApplicationEvents
> = (context): boolean => {
  return isFullClinicData(context.user?.currentClinic);
};
