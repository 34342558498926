import { Machine } from "xstate";

import { filterPatients, savePatients, saveQuery } from "./actions";
import {
  COLD,
  DONE,
  HOT,
  READY,
  SEARCH,
  SEARCH_DONE,
  SEARCHING,
  WARM,
} from "./constants";
import { arePatientsAvailable } from "./guards";
import { createSearchPromise } from "./promises";
import {
  PatientSearchContext,
  PatientSearchEvents,
  PatientSearchSchema,
} from "./types";

export const patientSearchMachine = Machine<
  PatientSearchContext,
  PatientSearchSchema,
  PatientSearchEvents
>(
  {
    id: "patientSearch",
    initial: READY,
    context: {
      query: "",
      results: [],
    },
    states: {
      [READY]: {
        on: {
          [SEARCH]: [
            {
              target: SEARCHING,
              actions: "saveQuery",
            },
          ],
        },
      },
      [SEARCHING]: {
        initial: COLD,
        states: {
          [COLD]: {
            always: [
              {
                target: HOT,
                cond: { type: "arePatientsAvailable" },
              },
              {
                target: WARM,
              },
            ],
          },
          [WARM]: {
            invoke: {
              src: createSearchPromise,
              id: "search",
              onDone: {
                target: HOT,
                actions: "savePatients",
              },
            },
          },
          [HOT]: {
            always: {
              target: DONE,
              actions: "filterPatients",
            },
          },
          [DONE]: {
            type: "final",
          },
        },
        onDone: READY,
      },
    },
  },
  {
    actions: {
      filterPatients,
      saveQuery,
      savePatients,
    },
    guards: {
      arePatientsAvailable,
    },
  },
);
