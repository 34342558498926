// States
export const AUTHENTICATING = "authenticating";
export const AUTHENTICATED = "authenticated";
export const FAILED = "failed";
export const PASSWORD_RESET = "resetPassword";
export const RESETTING = "resetting";
export const SENDING_EMAIL = "sendingEmail";
export const SENT_EMAIL = "sentEmail";
export const SENDING_RESET = "sendingReset";
export const SENT_RESET = "sentReset";
export const UNAUTHENTICATED = "unauthenticated";

// Public Events
export const FORGET_EMAIL = "AUTH.FORGET_EMAIL";
export const FORGET_PASSWORD = "AUTH.FORGET_PASSWORD";
export const LOGIN = "AUTH.LOGIN";
export const RESET = "AUTH.RESET";
export const RESET_PASSWORD = "AUTH.RESET_PASSWORD";

// Private Events
export const DONE = "AUTH.DONE";
export const FAIL = "AUTH.FAIL";
export const SUCCEED = "AUTH.SUCCEED";
