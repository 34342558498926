import { Card, CardHeader, Grid, CardContent, Button } from "@material-ui/core";
import React, { FunctionComponent, useMemo, useState } from "react";

import { DefinedProperty } from "../../components";
import {
  NurseVisit,
  VideoVisit,
  Patient,
  Clinic,
  ProviderUser,
  NurseVisitService,
} from "../../core";
import { NurseDirectDispatch } from "../../nurse-visits";

interface DispatchNurseProps {
  visit: VideoVisit;
}

export const DispatchNurse: FunctionComponent<DispatchNurseProps> = ({
  visit,
}) => {
  const cardContent = useMemo(
    () =>
      visit?.nurseVisit != null ? (
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DefinedProperty title="Status">
                {visit?.nurseVisit?.status}
              </DefinedProperty>
            </Grid>
            <Grid item xs={12}>
              <DefinedProperty title="Services">
                {(visit?.nurseVisit as NurseVisit).services
                  .map((service: NurseVisitService | string) =>
                    typeof service === "string" ? service : service.name,
                  )
                  .join(", ")}
              </DefinedProperty>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <NurseDirectDispatch
          label="Dispatch Nurse"
          clinic={visit.clinic as Clinic}
          patient={visit.patient as Patient}
          provider={visit.provider as ProviderUser}
          videoVisitId={visit!.id}
        />
      ),

    [visit],
  );

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "overline" }}
        title="Nurse Visit"
      />
      {cardContent}
    </Card>
  );
};
