import {
  CardContent,
  makeStyles,
  createStyles,
  Button,
} from "@material-ui/core";
import {
  Patient,
  Clinic,
  ProviderUser,
  NurseVisit,
} from "@relieftelemed/platform";
import React, { FunctionComponent, useState, useEffect } from "react";
import { useQueryClient } from "react-query";

import { Alert, NotificationTrigger } from "../../components";
import { VideoVisit } from "../../core";
import { useAppService } from "../../state";

import { SelectNurseVisitServicesModal } from "./select-services";
import { useCreateNurseVisitMachine } from "./state";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: "center",
      display: "flex",
    },
  }),
);

interface NurseDirectDispatchProps {
  clinic: Clinic;
  patient: Patient;
  provider: ProviderUser;
  label: string;
  videoVisitId: string;
}

export const NurseDirectDispatch: FunctionComponent<NurseDirectDispatchProps> =
  ({ clinic, patient, provider, label, videoVisitId }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [{ user: userStateData }] = useAppService();
    const queryClient = useQueryClient();

    const [
      { didFail, errors, services, visit: nurseVisit, didFinishedSuccessfull },
      { create, update },
    ] = useCreateNurseVisitMachine();

    useEffect(() => {
      if (didFinishedSuccessfull) {
        queryClient.invalidateQueries(["video-visit", videoVisitId]);
      }
    }, [didFinishedSuccessfull, queryClient, videoVisitId]);

    const doOnNurseDispatch = () => {
      create({
        clinic,
        patient,
        phone: userStateData?.phone,
        provider,
        user: userStateData,
        videoVisit: videoVisitId,
      });
      setOpen(true);
    };

    const doOnSubmit = (updatedVisit: NurseVisit) => {
      update(updatedVisit);
      setOpen(false);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleDismissNotice = () => null;

    return (
      <>
        <CardContent className={classes.root}>
          <Button variant="contained" onClick={doOnNurseDispatch}>
            {label}
          </Button>
        </CardContent>
        {nurseVisit && (
          <SelectNurseVisitServicesModal
            nurseVisit={nurseVisit}
            onClose={handleClose}
            onSubmit={doOnSubmit}
            open={open}
            services={services}
          />
        )}
        <NotificationTrigger open={didFail} onClose={handleDismissNotice}>
          <Alert
            message={errors[0] ?? "Generic Error"}
            onDismiss={handleDismissNotice}
          />
        </NotificationTrigger>
      </>
    );
  };
