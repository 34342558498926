import React, { FunctionComponent } from "react";

import { useTimeSince } from "../hooks";

interface ClockTimerProps {
  date: Date;
}

export const ClockTimer: FunctionComponent<ClockTimerProps> = ({ date }) => {
  const formattedTime = useTimeSince(date);

  return <span>{formattedTime}</span>;
};
