import { useTheme } from "@material-ui/core";
import {
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalCellularOff,
} from "@material-ui/icons";
import React, { forwardRef, FunctionComponent } from "react";
import { Participant } from "twilio-video";

import { useIsParticipantReconnecting, useNetworkQuality } from "../../hooks";

interface NetworkQualityProps {
  participant: Participant;
}

export const NetworkQualityIndicator: FunctionComponent<NetworkQualityProps> =
  ({ participant }) => {
    const theme = useTheme();
    const networkQualityLevel = useNetworkQuality(participant);
    const isReconnecting = useIsParticipantReconnecting(participant);

    let icon;
    switch (networkQualityLevel) {
      case 0:
        icon = <SignalCellular0Bar color="error" />;
        break;
      case 1:
        icon = <SignalCellular1Bar htmlColor={theme.palette.warning.main} />;
        break;
      case 2:
        icon = <SignalCellular2Bar htmlColor={theme.palette.warning.main} />;
        break;
      case 3:
        icon = <SignalCellular3Bar htmlColor={theme.palette.success.main} />;
        break;
      case 4:
        icon = <SignalCellular4Bar htmlColor={theme.palette.success.main} />;
        break;
      default:
        break;
    }

    if (isReconnecting) {
      icon = <SignalCellularOff color="error" />;
    }

    return icon ?? null;
  };
