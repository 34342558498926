import { useMemo } from "react";

import { Visit } from "../core";

export enum GROUPINGS {
  OWN = "own",
  CLINIC = "clinic",
  OTHERS = "others",
}

export function useGroupedVisits<TVisit extends Visit = Visit>(
  visits: TVisit[],
  clinicId?: string,
  providerId?: string,
): Record<GROUPINGS, TVisit[]> {
  return useMemo(() => {
    const initialGroupings: Record<GROUPINGS, TVisit[]> = {
      [GROUPINGS.OWN]: [],
      [GROUPINGS.CLINIC]: [],
      [GROUPINGS.OTHERS]: [],
    };

    return visits.reduce((aggregate, visit) => {
      let target = GROUPINGS.OTHERS;
      const visitClinic =
        typeof visit.clinic === "string" ? visit.clinic : visit.clinic?.id;

      if (clinicId && visitClinic === clinicId) {
        target = GROUPINGS.CLINIC;
      }

      const visitProvider =
        typeof visit.provider === "string"
          ? visit.provider
          : visit.provider?.providerId;
      if (providerId && visitProvider === providerId) {
        target = GROUPINGS.OWN;
      }

      const newGroup = aggregate[target].concat([visit]);

      return {
        ...aggregate,
        [target]: newGroup,
      };
    }, initialGroupings);
  }, [clinicId, providerId, visits]);
}
