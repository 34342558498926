import { ButtonProps, makeStyles, CircularProgress } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { Button } from "./button";

const useLoadingStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.light,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
}

export const LoadingButton: FunctionComponent<LoadingButtonProps> = ({
  isLoading,
  ...restProps
}) => {
  const classes = useLoadingStyles();

  return (
    <div className={classes.wrapper}>
      <Button {...restProps} disabled={isLoading} />
      {isLoading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};
