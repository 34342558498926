import Fuse from "fuse.js";
import { assign } from "xstate";

import { SEARCH, SEARCH_DONE } from "./constants";
import {
  PatientSearchContext,
  PatientSearchEvents,
  isDoneEvent,
  SearchEvent,
} from "./types";

export const saveQuery = assign<PatientSearchContext, PatientSearchEvents>({
  query: (_context, event) =>
    event.type === SEARCH ? (event as SearchEvent).query : "",
  results: [],
});

export const savePatients = assign<PatientSearchContext, PatientSearchEvents>({
  patients: (context, event) => {
    if (isDoneEvent(event)) {
      return new Fuse(event.data, {
        findAllMatches: true,
        keys: ["fullName"],
      });
    }

    return context.patients;
  },
});

export const filterPatients = assign<PatientSearchContext, PatientSearchEvents>(
  {
    results: (context) =>
      context.patients!.search(context.query).map((result) => result.item),
  },
);
