import { Button, Grid, Hidden, TextField } from "@material-ui/core";
import React, { FormEventHandler, FunctionComponent } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";

import { ActionButton, FormCard, PageTitle } from "../components";
import { AUTHENTICATION_MACHINE, PATH_LOGIN } from "../constants";
import { useAnalytics } from "../core";
import { useValidatedPhone } from "../hooks";
import { AuthenticationLayout } from "../layouts";
import { useAppService } from "../state";

import { AuthenticationService, useAuthService } from "./state";

export const ForgotEmailPage: FunctionComponent = () => {
  const { track } = useAnalytics();
  const [, { [AUTHENTICATION_MACHINE]: authentication }] = useAppService();
  const [{ didSendEmail }, { forgetEmail }] = useAuthService(
    authentication as AuthenticationService,
  );

  const [mobile, isMobileValid, changeMobile] = useValidatedPhone("");

  const handleSubmit: FormEventHandler<HTMLFormElement & HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    track("auth.forgotEmail", { mobile });
    forgetEmail(mobile);
  };

  if (didSendEmail) {
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN,
          state: {
            didSendEmail: true,
          },
        }}
      />
    );
  }

  return (
    <AuthenticationLayout>
      <PageTitle title="Forgot Email" />
      <FormCard
        title="Forgot Email?"
        instructions="Enter your mobile number. We will send you the email address for that account."
        elevation={0}
        onSubmit={handleSubmit}
        actions={
          <Grid container direction="row-reverse" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <ActionButton type="submit" disabled={!isMobileValid}>
                Send
              </ActionButton>
            </Grid>
            <Hidden smDown>
              <Grid item md={4} />
            </Hidden>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                fullWidth
                variant="text"
                component={RouterLink}
                to={PATH_LOGIN}
              >
                Go to Login
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid item xs={12}>
          <TextField
            autoFocus
            type="tel"
            error={!isMobileValid}
            id="mobile"
            label="Mobile Number"
            onChange={changeMobile}
            required
            value={mobile}
          />
        </Grid>
      </FormCard>
    </AuthenticationLayout>
  );
};
