import { useService } from "@xstate/react";

import { Clinic } from "../core";

import {
  CHANGE_CLINIC,
  CHECK_SESSION,
  END_SESSION,
  LOGGED_IN,
  ONBOARDING,
  VERIFYING,
} from "./constants";
import { appService } from "./machine";
import { ApplicationHooks, ApplicationServices } from "./types";

// Exposed API Below
export * from "./types";

export const useAppService = (): ApplicationHooks => {
  const [state, send] = useService(appService);

  const isLoggedIn = state.matches(LOGGED_IN);
  const needsOnboarding = state.matches({ [LOGGED_IN]: ONBOARDING });
  const needsVerifying = state.matches({ [LOGGED_IN]: VERIFYING });

  const changeClinic = (clinic: Clinic) => {
    send({
      type: CHANGE_CLINIC,
      clinic,
    });
  };

  const logout = () => {
    send(END_SESSION);
  };

  const refreshProvider = () => {
    send(CHECK_SESSION);
  };

  return [
    {
      isLoggedIn,
      needsOnboarding,
      needsVerifying,
      user: state.context.user,
    },
    state.children as unknown as ApplicationServices,
    { changeClinic, logout, refreshProvider },
  ];
};
