import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";

import { HorizontalLogo } from "../styles";

export interface SidebarProps {
  children?: ReactNode;
  footer?: ReactNode;
}

const useSidebarStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: `${theme.spacing(6)}px ${theme.spacing(8)}px`,
  },
  spacer: {
    flexGrow: 1,
  },
}));

export const Sidebar: FunctionComponent<SidebarProps> = ({
  children,
  footer,
}) => {
  const classes = useSidebarStyles();

  return (
    <Grid
      classes={{ root: classes.root }}
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      <Grid item>
        <Box mb={{ xs: "1rem", md: "4rem" }}>
          <HorizontalLogo width="7rem" height="2.18rem" />
        </Box>
      </Grid>
      <Grid item md className={classes.spacer}>
        {children}
      </Grid>
      <Grid item>{footer}</Grid>
    </Grid>
  );
};
