import { makeStyles, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { disabled } from "../styles";

interface CopyrightProps {
  align?: "left" | "right" | "inherit" | "center" | "justify";
  color?: "default" | "textPrimary";
}

const useCopyrightStyles = makeStyles({
  root: {
    fontSize: "0.688rem",
    lineHeight: 1.18,
  },
  colorDefault: {
    color: disabled,
  },
});

export const Copyright: FunctionComponent<CopyrightProps> = ({
  align,
  color = "default",
}) => {
  const { root, colorDefault } = useCopyrightStyles();

  const className = color === "default" ? colorDefault : undefined;
  const colorValue = color === "default" ? undefined : color;

  return (
    <Typography
      className={className}
      classes={{ root }}
      color={colorValue}
      align={align}
    >
      &copy; {new Date().getFullYear()} Relief Telemed, Inc. All Rights
      Reserved.
    </Typography>
  );
};
