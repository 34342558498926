import {
  closeNurseVisit as closeVisit,
  getInsuranceProvidersPromise,
  getNurseVisit,
  getPatientInsurancePromise,
  logger,
  marshallInsurance,
  marshallNurseVisit,
} from "../../../core";

import { FAIL, SUCCEED } from "./constants";
import { FailEvent, NurseVisitDetailsContext, SucceedEvent } from "./types";

export const closeNurseVisit = async (
  context: NurseVisitDetailsContext,
): Promise<SucceedEvent | FailEvent> => {
  try {
    const response = await closeVisit(context.visit!.id)({
      message: context.patientMessage,
    });
    return {
      type: SUCCEED,
      visit: marshallNurseVisit(response),
    };
  } catch (error) {
    const { messages } = error.response.json();
    return {
      type: FAIL,
      errors: Object.values(messages).flat() as string[],
    };
  }
};

const fetchInsurance = async (patientId: string) => {
  try {
    const patienceInsuranceResponse = await getPatientInsurancePromise(
      patientId,
    );
    try {
      const providers = await getInsuranceProvidersPromise();
      const provider = providers.find(
        (maybeProvider) => maybeProvider.id === patienceInsuranceResponse.payer,
      );
      return marshallInsurance(patienceInsuranceResponse, provider);
    } catch (insuranceProvError) {
      logger.warn("Error when fetching for providers", insuranceProvError);
      return undefined;
    }
  } catch (patienceInsuranceError) {
    const { messages } = await patienceInsuranceError.response.json();
    logger.warn(Object.values(messages).flat().join(", "));
    return undefined;
  }
};

export const fetchDetails = async (
  context: NurseVisitDetailsContext,
): Promise<SucceedEvent | FailEvent> => {
  try {
    const nurseVisitResponse = await getNurseVisit(context.visit!.id);
    const nurseVisit = marshallNurseVisit(nurseVisitResponse);

    const patientId =
      typeof nurseVisit.patient === "string"
        ? nurseVisit.patient
        : nurseVisit.patient?.id ?? false;

    logger.debug("Does Nurse Visit have a Patient ID", patientId);

    if (!patientId) {
      logger.debug("No patient ID, so returning a success event");
      return {
        type: SUCCEED,
        visit: nurseVisit,
      };
    }

    logger.debug("Fetching patient insurance details");

    const insurance = await fetchInsurance(patientId);
    logger.debug("Received insurance response", insurance);

    const { payment } = nurseVisit;
    if (payment && insurance) {
      const { groupId, memberId, planId, provider, type } = insurance;
      payment.active = true;
      payment.groupId = groupId;
      payment.memberId = memberId;
      payment.planId = planId;
      payment.provider = provider;
      if (type) {
        payment.type = type;
      }

      logger.debug("Altering nurse visit insurance information", payment);
    }
    return {
      type: SUCCEED,
      visit: {
        ...nurseVisit,
        payment,
      },
    };
  } catch (err) {
    const { messages } = await err.response.json();
    return {
      type: FAIL,
      errors: Object.values(messages).flat() as string[],
    };
  }
};
