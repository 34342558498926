import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

import { createPageTitle } from "../config";

interface PageTitleProps {
  title?: string;
}

export const PageTitle: FunctionComponent<PageTitleProps> = ({ title }) => (
  <Helmet>
    <title>{createPageTitle(title)}</title>
  </Helmet>
);
