import { Grid } from "@material-ui/core";
import {
  ProviderUser,
  useGetProvider,
  useUpdateProvider,
  credentials as allCredentials,
  formatDate,
  formatPhoneNumber,
  Provider,
} from "@relieftelemed/platform";
import React, { FunctionComponent, useEffect } from "react";
import { QueryStatus } from "react-query";
import { useHistory } from "react-router";

import { ProfileForm, ProfileData } from "../../components";
import { PATH_HOME } from "../../constants";
import { usePageTitle } from "../../hooks";
import { useAppService } from "../../state";

import { ChangePassword } from "./change-password";

export const ProfilePage: FunctionComponent = () => {
  const history = useHistory();
  const setTitle = usePageTitle();
  const [{ user }, , { refreshProvider }] = useAppService();
  const {
    data,
    isLoading: isLoadingProvider,
    remove,
  } = useGetProvider((user as ProviderUser).providerId);

  const { mutate, isLoading } = useUpdateProvider({
    onSuccess: () => {
      remove();
      refreshProvider();
      history.push(PATH_HOME);
    },
  });

  useEffect(() => {
    const prefix = user ? `${user.firstName} ${user.lastName}` : "My";
    setTitle(`${prefix} Profile`);
  }, [setTitle, user]);

  if (isLoadingProvider) {
    return <div>Loading...</div>;
  }
  const provider: Provider = { ...data, phone: user?.phone ?? "" } as Provider;

  const handleSubmit = ({
    credentials = [],
    dob,
    fax,
    license,
    phone,
    ...details
  }: ProfileData) => {
    const newPhone = user?.phone === formatPhoneNumber(phone) ?? "";

    mutate({
      credentials: allCredentials.filter((credential) =>
        credentials.includes(credential.id),
      ),
      dob: formatDate(dob),
      fax: formatPhoneNumber(fax) ?? "",
      id: (user as ProviderUser).providerId,
      npIdentificationNumber: details.npiNumber ?? "",
      stateLicenseExpiration: license?.expiration
        ? formatDate(license.expiration)
        : "",
      stateLicenseNumber: license?.number ?? "",
      stateLicensed: license?.state ?? "",
      phone1: formatPhoneNumber(phone) ?? "",
      verifiedMobile: newPhone,
      ...details,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid item xs={12}>
          <ProfileForm provider={provider} onSubmit={handleSubmit} />
        </Grid>
        <Grid item xs={12}>
          <ChangePassword />
        </Grid>
      </Grid>
    </Grid>
  );
};
