import { MenuItem } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { forwardRef, MouseEvent } from "react";

import { Alert, NotificationTrigger } from "../../components";
import { FLAG_VIDEO_VISIT } from "../../constants";

import { CreateVideoVisitModal } from "./create";
import { useCreateVideoVisitMachine } from "./state";

interface StartVideoVisitProps {
  onCancel?: () => void;
  onStart: (event: MouseEvent<HTMLLIElement>) => void;
  open: boolean;
}

export const StartVideoVisit = forwardRef<HTMLLIElement, StartVideoVisitProps>(
  (props, ref) => {
    const { onCancel, onStart, open } = props;

    const flags = useFlags();
    const [{ didCreate, didFail, errors }, { create }] =
      useCreateVideoVisitMachine();

    const canVideo = flags[FLAG_VIDEO_VISIT];

    if (!canVideo) {
      return null;
    }

    if (didCreate && onCancel) {
      onCancel();
    }

    const handleDismissNotice = () => null;

    const handleClose = () => {
      if (onCancel) {
        onCancel();
      }
    };

    return (
      <>
        <MenuItem ref={ref} onClick={onStart}>
          Video Visit
        </MenuItem>
        <CreateVideoVisitModal
          onClose={handleClose}
          onSubmit={create}
          open={open}
        />
        <NotificationTrigger open={didFail} onClose={handleDismissNotice}>
          <Alert
            message={errors ?? "Generic Error"}
            onDismiss={handleDismissNotice}
          />
        </NotificationTrigger>
      </>
    );
  },
);
