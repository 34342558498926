import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { states } from "../core";

const valueRenderer = (value: unknown) => value as string;

export const StateSelector: FunctionComponent<TextFieldProps> = ({
  SelectProps,
  ...props
}) => (
  <TextField
    {...props}
    select
    SelectProps={{ ...SelectProps, renderValue: valueRenderer }}
  >
    {states.map((state) => (
      <MenuItem key={state.id} value={state.id}>
        {state.name}
      </MenuItem>
    ))}
  </TextField>
);
