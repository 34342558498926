import { useMachine } from "@xstate/react";

import { SEARCH, SEARCHING } from "./constants";
import { patientSearchMachine } from "./machine";
import {
  PatientSearchContext,
  PatientSearchEvents,
  PatientSearchServiceHook,
} from "./types";

// Exposed API Below
export * from "./types";

export const usePatientSearchService = (): PatientSearchServiceHook => {
  const [state, send] = useMachine<PatientSearchContext, PatientSearchEvents>(
    patientSearchMachine,
  );

  const isSearching = state.matches(SEARCHING);

  const search = (query: string) => {
    send({
      type: SEARCH,
      query,
    });
  };

  return [
    {
      isSearching,
      results: state.context.results,
    },
    { search },
  ];
};
