import { Machine } from "xstate";

import { VideoVisit } from "../../../core";

import { saveError, saveVisit } from "./actions";
import {
  ACCEPT,
  ACCEPTED,
  ACCEPTING,
  FETCHED,
  FETCHING,
  LOCKED,
  UNFETCHED,
} from "./constants";
import { isVideoVisitAccepted } from "./guards";
import { acceptVideoVisit, fetchDetails } from "./promises";
import {
  VideoVisitDetailsEvents,
  VideoVisitDetailsContext,
  VideoVisitDetailsSchema,
} from "./types";

export const createVideoVisitDetailsMachine = (visit: VideoVisit) =>
  Machine<
    VideoVisitDetailsContext,
    VideoVisitDetailsSchema,
    VideoVisitDetailsEvents
  >(
    {
      id: `video-visit-${visit.id}`,
      initial: UNFETCHED,
      context: {
        visit,
      },
      states: {
        [UNFETCHED]: {
          always: FETCHING,
        },
        [FETCHING]: {
          invoke: {
            src: fetchDetails,
            id: "fetch-details",
            onDone: {
              actions: "saveVisit",
              target: FETCHED,
            },
            onError: {
              target: UNFETCHED,
              actions: "saveError",
            },
          },
        },
        [FETCHED]: {
          always: [
            {
              target: ACCEPTED,
              cond: {
                type: "isVideoVisitAccepted",
              },
            },
            {
              target: LOCKED,
            },
          ],
        },
        [LOCKED]: {
          on: {
            [ACCEPT]: ACCEPTING,
          },
        },
        [ACCEPTING]: {
          invoke: {
            src: acceptVideoVisit,
            id: "accept-visit",
            onDone: {
              target: ACCEPTED,
              actions: "saveVisit",
            },
            onError: {
              target: UNFETCHED,
              actions: "saveError",
            },
          },
        },
        [ACCEPTED]: {
          type: "final",
        },
      },
    },
    {
      actions: {
        saveError,
        saveVisit,
      },
      guards: {
        isVideoVisitAccepted,
      },
    },
  );
