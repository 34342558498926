import { Grid, Typography } from "@material-ui/core";
import {
  VideoVisit,
  VideoVisitStatus,
  ProviderUser,
  patchVideoVisit,
  unmarshallVideoVisit,
  Clinic,
  Patient,
  NurseVisit,
  useGetVideoVisit,
} from "@relieftelemed/platform";
import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router";

import { EditableNote, VideoProvider, VideoRoom } from "../../components";
import { videoSettings } from "../../config";
import { renderRelativeToToday } from "../../helpers";
import { VisitsContextProvider } from "../../hooks";
import { useAppService } from "../../state";

import { Allergies } from "./allergies";
import { BillingCodes } from "./billing-codes";
import { Demographics } from "./demographics";
import { DispatchNurse } from "./dispatch-nurse";
import { DxCode } from "./dx-code";
import { FamilyHistory } from "./family-history";
import { FinalizeVisit } from "./finalize-visit";
import { Medications } from "./medications";
import { PersonalHistory } from "./personal-history";
import { PrimaryComplaint } from "./primary-complaint";
import { PrintButton } from "./print";
import { ReviewOfSystems } from "./review-of-systems";
import { SocialHistory } from "./social-history";

interface ExamRoomRoute {
  id: string;
}

export const ExamRoom: FunctionComponent = () => {
  const { id } = useParams<ExamRoomRoute>();
  const [{ user }] = useAppService();
  const queryClient = useQueryClient();

  const { isLoading, data: visit } = useGetVideoVisit(id);

  const { mutate: updateVisit } = useMutation(
    (updatedVideoVisit: VideoVisit) =>
      patchVideoVisit(id)(unmarshallVideoVisit(updatedVideoVisit)),
    {
      onSuccess: (__data) => queryClient.invalidateQueries(["video-visit", id]),
    },
  );

  const isOwn = useMemo(() => {
    const providerId = (user as ProviderUser).providerId ?? undefined;
    const visitProvider =
      typeof visit?.provider === "string"
        ? visit?.provider
        : visit?.provider?.providerId;

    return providerId === visitProvider;
  }, [user, visit]);

  if (isLoading || !visit) {
    return <div>Loading...</div>;
  }

  const isFinished = visit.status === VideoVisitStatus.COMPLETED;
  const canEdit = isOwn;

  const handleConnect = () => {
    if (visit.connection) {
      if (!visit.providerReady) {
        updateVisit({
          ...visit,
          providerReady: true,
        });
      }
    }
  };

  const handleUpdate = (changes: Partial<VideoVisit>) => {
    updateVisit({
      ...visit,
      ...changes,
    });
  };

  const hasNurseVisit = Boolean(visit.nurseVisit);
  const isNursevisitAvailable =
    typeof visit.clinic === "string" || !visit.clinic
      ? false
      : Boolean(visit.clinic.nurseVisitAvailable);

  let video: ReactNode = null;
  if (isOwn && !isFinished && visit.connection?.providerToken) {
    video = (
      <Grid item xs={12} lg={12}>
        <VideoRoom />
      </Grid>
    );
  }

  let saveButton: ReactNode = null;
  if (isOwn) {
    saveButton = (
      <Grid item xs={12} lg={12}>
        <FinalizeVisit onChange={handleUpdate} />
      </Grid>
    );
  }

  let chartPrint: ReactNode = null;
  if (visit.provider) {
    chartPrint = (
      <Grid item>
        <PrintButton visit={visit} />
      </Grid>
    );
  }

  return (
    <VideoProvider
      options={videoSettings}
      onConnect={handleConnect}
      token={visit?.connection?.providerToken}
    >
      <article>
        <VisitsContextProvider
          value={{
            isFinished,
            canEdit,
          }}
        >
          <Grid container spacing={2}>
            <Grid item container xs={12} spacing={2} alignItems="flex-end">
              <Grid item>
                <Typography variant="h1">
                  Virtual Examination for {visit.patientName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Last edited {renderRelativeToToday(visit.updatedAt)}
                </Typography>
              </Grid>
              {chartPrint}
            </Grid>

            <Grid item xs={12} lg>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Demographics visit={visit} />
                </Grid>
                <Grid item xs={12}>
                  <PrimaryComplaint visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12}>
                  <EditableNote
                    noteType="general"
                    label="History of Present Illness"
                    onSave={handleUpdate}
                    visit={visit}
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Medications visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Allergies visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PersonalHistory visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FamilyHistory visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12}>
                  <ReviewOfSystems visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <BillingCodes visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SocialHistory visit={visit} onChange={handleUpdate} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <EditableNote
                    noteType="assessment"
                    label="Assessment"
                    onSave={handleUpdate}
                    visit={visit}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <EditableNote
                    noteType="treatment_instructions"
                    label="Treatment Plan"
                    onSave={handleUpdate}
                    visit={visit}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <EditableNote
                    noteType="follow_up_instructions"
                    label="Follow-Up"
                    onSave={handleUpdate}
                    visit={visit}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <DxCode visit={visit} onChange={handleUpdate} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                {video}
                {saveButton}
                <Grid item xs={12} lg={12}>
                  {hasNurseVisit || isNursevisitAvailable ? (
                    <DispatchNurse visit={visit} />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </VisitsContextProvider>
      </article>
    </VideoProvider>
  );
};
