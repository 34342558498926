import ky from "ky";
import { useQuery } from "react-query";

import { Diagnosis } from "../core";

export const useConditionsSearch = (searchTerm: string) => {
  return useQuery(
    ["conditions/v3/search", searchTerm],
    (): Promise<Diagnosis[]> =>
      ky
        .get(`https://clinicaltables.nlm.nih.gov/api/conditions/v3/search`, {
          searchParams: {
            terms: searchTerm,
            df: "consumer_name,primary_name",
          },
        })
        .json()
        .then((result: any) => {
          if (!result) {
            return undefined;
          }

          return result[1].map((keyId: string, index: number): Diagnosis => {
            return {
              code: keyId,
              displayName: result[3][index][0],
              name: result[3][index][1],
            };
          });
        }),
    { enabled: searchTerm != null },
  );
};
