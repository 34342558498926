import React, { FunctionComponent } from "react";

import { VisitDetailCard } from "../../components";
import { VideoVisit } from "../../core";

interface VisitDetails {
  visit: VideoVisit;
}

export const Demographics: FunctionComponent<VisitDetails> = ({ visit }) => {
  return (
    <VisitDetailCard
      cardVariant="outlined"
      name={visit.patientName}
      address={
        typeof visit.household === "string"
          ? undefined
          : visit.household?.address?.summary
      }
      dob={typeof visit.patient === "string" ? undefined : visit.patient?.dob}
      gender={
        typeof visit.patient === "string" ? undefined : visit.patient?.gender
      }
      payment={visit.payment}
      phone={
        typeof visit.household === "string"
          ? undefined
          : visit.household?.phones[0].number
      }
    />
  );
};
