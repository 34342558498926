import {
  forgetEmail,
  forgetPassword,
  formatPhoneNumber,
  loginPromise,
  isApiError,
  logger,
  marshallUserProvider,
  resetPasswordPromise,
} from "../../core";

import { DONE } from "./constants";
import {
  AuthenticationContext,
  RequestDoneEvent,
  SuccessResponse,
} from "./types";

export const createForgetEmailPromise = async (
  context: AuthenticationContext,
): Promise<RequestDoneEvent> => {
  try {
    await forgetEmail({ phone: formatPhoneNumber(context.mobile) ?? "" });
  } catch (errorResponse) {
    const { messages } = await errorResponse.response.json();
    const errorsString = Object.values(messages).flat().join(", ");
    logger.error(errorsString);
  }
  return {
    type: DONE,
  };
};

export const createForgetPasswordPromise = async (
  context: AuthenticationContext,
): Promise<RequestDoneEvent> => {
  try {
    await forgetPassword({
      email: context.email,
    });
  } catch (error) {
    const errorResponse = await error.response.json();
    logger.error(Object.values(errorResponse.messages).flat().join(", "));
  }
  return {
    type: DONE,
  };
};

export const createLoginPromise = async (
  context: AuthenticationContext,
): Promise<SuccessResponse> => {
  try {
    const loginResponse = await loginPromise({
      email: context.email,
      password: context.password,
    });
    return {
      token: loginResponse.key,
      user: marshallUserProvider(loginResponse.user),
    };
  } catch (error) {
    const { messages } = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};

export const createResetPasswordPromise = async (
  context: AuthenticationContext,
): Promise<{}> => {
  try {
    await resetPasswordPromise({
      uid: context.email,
      newPassword1: context.password,
      newPassword2: context.confirmation,
      token: context.token,
    });
    return {};
  } catch (error) {
    const { messages } = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};
