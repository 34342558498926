import { Chip, TextField } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import React, { FunctionComponent, useState } from "react";

import { EditableCard } from "../../components";
import { Diagnosis, VideoVisit } from "../../core";
import { useConditionsSearch } from "../../hooks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  }),
);

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

export const DxCode: FunctionComponent<VisitDetail> = ({
  onChange = () => {},
  visit,
}) => {
  const [diagnoses, setDiagnoses] = useState<Diagnosis[]>(
    visit.diagnoses ?? [],
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const { isLoading, data } = useConditionsSearch(searchTerm);

  const classes = useStyles();

  const handleInputChange = (_event: unknown, inputValue: string) => {
    setSearchTerm(inputValue);
  };

  const handleSave = () => {
    onChange({
      diagnoses,
    });
  };

  const handleCancel = () => {
    setDiagnoses(visit.diagnoses ?? []);
  };

  return (
    <EditableCard
      onSave={handleSave}
      onCancel={handleCancel}
      label="Dx Code"
      content={
        <div className={classes.root}>
          {diagnoses?.map((diagnosis) => (
            <Chip key={diagnosis.code} label={diagnosis.displayName} />
          ))}
        </div>
      }
      editContent={
        <Autocomplete
          multiple
          inputValue={searchTerm}
          getOptionLabel={(diagnosis) => diagnosis.displayName}
          getOptionSelected={(option, value) => option.code === value.code}
          onChange={(event, value) => setDiagnoses(value)}
          loading={isLoading}
          value={diagnoses}
          onInputChange={handleInputChange}
          size="small"
          options={data ?? []}
          filterOptions={(options) => options}
          renderInput={(params) => <TextField {...params} />}
        />
      }
    />
  );
};
