import React, { FunctionComponent } from "react";
import { Participant, Track } from "twilio-video";

import {
  isScreensharePublicationTrack,
  isVideoPublicationTrack,
} from "../../helpers";
import { usePublications, useVideoContext } from "../../hooks";

import { Publication } from "./publication";

interface ParticipantProps {
  participant: Participant;
  disableAudio?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
}

export const ParticipantTracks: FunctionComponent<ParticipantProps> = ({
  participant,
  disableAudio,
  enableScreenShare,
  videoPriority,
}) => {
  const { room } = useVideoContext();
  const publications = usePublications(participant);
  const isLocal = participant === room.localParticipant;

  const filter =
    enableScreenShare && publications.some(isScreensharePublicationTrack)
      ? isVideoPublicationTrack
      : isScreensharePublicationTrack;

  const filteredPublications = publications.filter((pub) => !filter(pub));

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocal={isLocal}
          disableAudio={disableAudio}
          videoPriority={videoPriority}
        />
      ))}
    </>
  );
};
