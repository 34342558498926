import {
  DialogContentText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Smartphone } from "@material-ui/icons";
import React, {
  ChangeEventHandler,
  FormEventHandler,
  FunctionComponent,
  useState,
} from "react";

import { Button, FormModal } from "../../components";
import { VERIFICATION_MACHINE } from "../../constants";
import { useAnalytics } from "../../core";
import { useAppService } from "../../state";

import { useVerifyService, VerificationService } from "./state";

const useVerificationStyles = makeStyles((theme) => ({
  resend: {
    ...theme.typography.body1,
    padding: 0,
    textTransform: "none",
  },
  label: {
    fontWeight: "bold",
  },
  primaryAction: {
    textAlign: "right",
  },
  secondaryAction: {
    fontWeight: "normal",
    textTransform: "none",
    justifyContent: "flex-start",
    marginLeft: `-${theme.spacing(1)}px`,
  },
}));

export const VerificationModal: FunctionComponent = () => {
  const classes = useVerificationStyles();
  const { track } = useAnalytics();
  const [{ needsVerifying, user }, { [VERIFICATION_MACHINE]: verification }] =
    useAppService();
  const [{ didFail }, { sendCode, skip, reset, verify }] = useVerifyService(
    verification as VerificationService,
  );
  const [code, changeCode] = useState("");

  const handleResend = () => sendCode();
  const handleSkip = () => skip();
  const handleChangeCode: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (didFail) {
      reset();
    }
    changeCode(event.target.value);
  };
  const handleVerify: FormEventHandler<HTMLFormElement & HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    track("user.verify", { email: user?.email ?? "" });
    verify(code);
  };

  if (didFail && code !== "") {
    changeCode("");
  }

  return (
    <FormModal
      icon={Smartphone}
      open={needsVerifying || false}
      padded
      onSubmit={handleVerify}
      title="Please check your text messages on your phone for a 6-digit code from Relief."
      titleProps={{ variant: "body1" }}
      actions={
        <Grid container>
          <Grid item xs={6} md={4}>
            <Button
              onClick={handleSkip}
              variant="text"
              classes={{ root: classes.secondaryAction }}
            >
              Skip | Go to Login
            </Button>
          </Grid>
          <Grid item xs={false} md />
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button type="submit">Verify</Button>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="body2" className={classes.label}>
        Verification code here
      </DialogContentText>
      <TextField
        autoFocus
        error={didFail}
        helperText={
          didFail ? "Please check your code and try again" : undefined
        }
        id="verification"
        label="Verification Code"
        onChange={handleChangeCode}
        required
        value={code}
      />
      <DialogContentText>
        Didn&apos;t receive?{" "}
        <Button
          classes={{ root: classes.resend }}
          onClick={handleResend}
          variant="text"
        >
          Resend
        </Button>
      </DialogContentText>
    </FormModal>
  );
};
