import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";

import { RoomStateType, useRoomState } from "../../hooks";

import { Controls } from "./controls";
import { LocalVideoPreview } from "./local-preview";
import { MainParticipant } from "./main-participant";
import { ParticipantInfo } from "./participant-info";

const useRoomStyles = makeStyles({
  actions: {
    justifyContent: "space-evenly",
  },
});

export const VideoRoom: FunctionComponent = () => {
  const classes = useRoomStyles();
  const roomState = useRoomState();

  let display: ReactNode = <MainParticipant />;
  if (RoomStateType.disconnected === roomState) {
    display = <LocalVideoPreview />;
  }

  const basicHeader = (
    <CardHeader
      titleTypographyProps={{ variant: "overline" }}
      title="Video Preview"
    />
  );

  return (
    <Card>
      {RoomStateType.connected === roomState ? (
        <ParticipantInfo />
      ) : (
        basicHeader
      )}
      <CardContent>{display}</CardContent>
      <CardActions className={classes.actions}>
        <Controls />
      </CardActions>
    </Card>
  );
};
