import { CircularProgress } from "@material-ui/core";
import { Call, Replay } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { RoomStateType, useRoomState, useVideoContext } from "../../hooks";

import { ControlButton } from "./control-button";

interface ConnectState {
  isRetry?: boolean;
}

export const ControlConnect: FunctionComponent<ConnectState> = ({
  isRetry = false,
}) => {
  const { connect } = useVideoContext();
  const roomState = useRoomState();

  let connectHandler;
  let tooltip = "Connecting";
  let connectIcon = <CircularProgress color="primary" />;
  if (roomState === RoomStateType.disconnected) {
    connectHandler = connect;
    connectIcon = <Call color="primary" />;
    tooltip = "Join the room";
  }

  if (isRetry) {
    connectIcon = <Replay color="primary" />;
    tooltip = "Retry joining room";
  }

  return (
    <ControlButton
      disabled={!connectHandler}
      onClick={connectHandler}
      tooltip={tooltip}
    >
      {connectIcon}
    </ControlButton>
  );
};
