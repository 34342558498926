import { Badge, makeStyles, Tab, Tabs } from "@material-ui/core";
import React, { ChangeEvent, FunctionComponent, useState } from "react";

export interface StatusCount {
  count: number;
  status: string;
}

export interface TabbedNavigationProps {
  onChange?: (value: string) => void;
  statusCounts: StatusCount[];
}

const useTabNavStyles = makeStyles((theme) => ({
  badge: {
    marginLeft: theme.spacing(2),
  },
  indicatorColor: {
    backgroundColor: "transparent",
  },
}));

export const TabbedNavigation: FunctionComponent<TabbedNavigationProps> = ({
  onChange,
  statusCounts,
}) => {
  const classes = useTabNavStyles();

  const [selectedTab, changeTab] = useState(0);

  const handleTabChange = (_event: ChangeEvent<{}>, newValue: number) => {
    changeTab(newValue);
    if (onChange) {
      onChange(statusCounts[newValue]?.status);
    }
  };

  return (
    <Tabs
      value={selectedTab}
      indicatorColor="primary"
      TabIndicatorProps={{
        className: classes.indicatorColor,
      }}
      textColor="primary"
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="off"
    >
      {statusCounts.map(({ count, status }) => (
        <Tab
          key={status}
          label={
            <span>
              {status}
              <Badge
                badgeContent={count}
                classes={{ root: classes.badge }}
                color="error"
              />
            </span>
          }
        />
      ))}
    </Tabs>
  );
};
