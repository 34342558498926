import { ConditionPredicate } from "xstate";

import {
  validateEmail,
  validatePassword,
  validateConfirmation,
  validatePhone,
  validateTerms,
} from "../../../core";

import { CREATE } from "./constants";
import { RegistrationContext, RegistrationEvents, CreateEvent } from "./types";

export const isRegistrationValid: ConditionPredicate<
  RegistrationContext,
  RegistrationEvents
> = (_context, event): boolean => {
  if (event.type !== CREATE) {
    return false;
  }
  const actualEvent = event as CreateEvent;

  const validations: boolean[] = [
    validateConfirmation(actualEvent.password, actualEvent.confirmation),
    validateEmail(actualEvent.email),
    validatePassword(actualEvent.password),
    validatePhone(actualEvent.mobile),
    validateTerms(actualEvent.acceptedTerms),
  ];

  return validations.every(Boolean);
};
