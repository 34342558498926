import { makeStyles, SwipeableDrawer } from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";

interface DetailDrawerProps {
  open: boolean;
  onClose: (event: object) => void;
  children: ReactNode;
}

const useDrawerStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40vw",
    },
  },
}));

export const DetailDrawer: FunctionComponent<DetailDrawerProps> = ({
  open,
  onClose,
  children,
}) => {
  const drawerStyles = useDrawerStyles();

  return (
    <SwipeableDrawer
      open={open}
      anchor="right"
      onOpen={() => {}}
      disableSwipeToOpen
      onClose={onClose}
    >
      <div className={drawerStyles.container}>{children}</div>
    </SwipeableDrawer>
  );
};
