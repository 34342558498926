import { Box, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { Copyright } from "../../components";
import { Layout } from "../layout";
import { Sidebar } from "../sidebar";

import { OnboardingProps, OnboardingSteps } from "./onboarding-steps";

export const OnboardingLayout: FunctionComponent<OnboardingProps> = ({
  step = 0,
  children,
}) => {
  return (
    <Layout
      fullHeight
      padded
      sidebar={
        <Sidebar>
          <OnboardingSteps step={step} />
        </Sidebar>
      }
    >
      <Box
        component="main"
        display="flex"
        flexDirection="column"
        height="100%"
        my={3}
        mx={7}
      >
        <Typography variant="h1">Relief Telemed</Typography>
        <Box height="4rem" />
        {children}
        <Box flexGrow={1} />
        <Copyright />
      </Box>
    </Layout>
  );
};
