import { TableCellClassKey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

import { inputPlaceholder, nightBlue } from "../palette";
import { typography } from "../typography";

export const MuiTableCell: Partial<StyleRules<TableCellClassKey>> = {
  root: {
    borderBottom: "none",
  },
  head: {
    ...typography.body2,
    color: inputPlaceholder,
    lineHeight: 1.9,
    textTransform: "uppercase",
  },
  body: {
    ...typography.h5,
    lineHeight: 1.36,
    color: nightBlue,
  },
};
