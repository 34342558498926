import { Button } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { EditableList } from "../../components";
import { Listable, Medication, VideoVisit } from "../../core";

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

export const Medications: FunctionComponent<VisitDetail> = ({
  onChange = () => null,
  visit,
}) => {
  const handleChange = (newValue: Listable[]) => {
    onChange({
      healthHistory: {
        ...visit.healthHistory!,
        medications: newValue as Medication[],
      },
    });
  };

  return (
    <EditableList
      label="Medications"
      onSave={handleChange}
      value={visit.healthHistory?.medications ?? []}
      togglable
      footerActions={
        <Button variant="text" target="_blank" href={visit.dosespotUrl}>
          Order Prescription
        </Button>
      }
    />
  );
};
