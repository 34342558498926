import { IconButton } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { useNetworkQuality, useVideoContext } from "../../hooks";

import { NetworkQualityIndicator } from "./network-quality-indicator";

export const ControlQuality: FunctionComponent = () => {
  const { room } = useVideoContext();
  const networkQualityLevel = useNetworkQuality(room.localParticipant);

  if (networkQualityLevel === null || networkQualityLevel === undefined) {
    return null;
  }

  return (
    <IconButton disabled>
      <NetworkQualityIndicator participant={room.localParticipant} />
    </IconButton>
  );
};
