import {
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import React, {
  FunctionComponent,
  useState,
  useMemo,
  ChangeEventHandler,
} from "react";

import { EditableCard } from "../../components";
// mocked data
import { symptoms, VideoVisit, Symptom } from "../../core";

export interface SymptomElement {
  key: string;
  name: string;
  group: string;
}

const dissoc = (key: string, obj: Record<string, Symptom>) => {
  const copy = { ...obj };
  delete copy[key];
  return copy;
};
const buildSymptomKey = (group: string, key: string): string =>
  `${group}-${key}`;

// types

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

export const ReviewOfSystems: FunctionComponent<VisitDetail> = ({
  onChange = () => null,
  visit,
}) => {
  // states

  const initialSymptomMap: Record<string, Symptom> = useMemo(
    () =>
      visit?.symptoms.reduce(
        (acc, val) => ({ ...acc, [buildSymptomKey(val.group, val.id)]: val }),
        {},
      ),
    [visit],
  );

  const [symptomsMap, setSymptomsMap] =
    useState<Record<string, Symptom>>(initialSymptomMap);
  // functions
  const handleSave = () => {
    onChange({ symptoms: Object.values(symptomsMap) });
  };

  const handleCancel = () => {
    setSymptomsMap(initialSymptomMap);
  };

  const handleChange =
    ({
      key,
      name,
      group,
    }: SymptomElement): ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      setSymptomsMap((prevValue: Record<string, Symptom>) =>
        event.target.checked
          ? {
              ...prevValue,
              [buildSymptomKey(group, key)]: {
                id: key,
                group,
                description: "description",
                name,
              },
            }
          : dissoc(buildSymptomKey(group, key), prevValue),
      );
    };

  const dataSortedByGroups: Record<string, Array<SymptomElement>> = useMemo(
    () =>
      symptoms.reduce(
        (acc: { [key: string]: Array<SymptomElement> }, v: SymptomElement) => {
          acc[v.group] = acc[v.group] || [];
          acc[v.group] = acc[v.group].concat(v);

          return acc;
        },
        {},
      ),
    [],
  );

  const symptomCheckboxes = (disabled?: boolean) =>
    Object.entries(dataSortedByGroups).map(([group, values]) => (
      <Grid item xs={6} lg={4} key={`${group}-${Math.random()}`}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{group}</FormLabel>
          {values.map(({ key, name }) => (
            <FormControlLabel
              key={buildSymptomKey(group, key)}
              control={
                <Checkbox
                  checked={Boolean(symptomsMap[buildSymptomKey(group, key)])}
                  onChange={handleChange({ key, name, group })}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  disabled={disabled}
                />
              }
              label={name}
            />
          ))}
        </FormControl>
      </Grid>
    ));

  return (
    <EditableCard
      content={
        <Grid container spacing={2}>
          {symptomCheckboxes(true)}
        </Grid>
      }
      editContent={
        <Grid container spacing={2}>
          {symptomCheckboxes()}
        </Grid>
      }
      label="Symptoms"
      onCancel={handleCancel}
      onSave={handleSave}
    />
  );
};
