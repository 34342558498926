import {
  DialogContentText,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Videocam } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";

import { Button, FormModal } from "../../components";
import { Patient, useAnalytics } from "../../core";
import { useValidatedPhone } from "../../hooks";
import { PatientAutocomplete } from "../../patients";
import { useAppService } from "../../state";

interface VisitModalProps {
  onClose?: () => void;
  onSubmit: (visit: any) => void;
  open?: boolean;
}

const useVisitModalStyles = makeStyles({
  primaryAction: {
    textAlign: "right",
  },
});

export const CreateVideoVisitModal: FunctionComponent<VisitModalProps> = ({
  onClose,
  onSubmit,
  open = false,
}) => {
  const classes = useVisitModalStyles();
  const { track } = useAnalytics();
  const [{ user }] = useAppService();
  const [selectorMode, changeSelector] = useState(true);

  const [selected, changeSelected] = useState<Patient | null>(null);

  const [phone, isPhoneValid, changePhone] = useValidatedPhone();

  const toggleMode = () => {
    changeSelector(!selectorMode);
  };

  const handleSubmit = () => {
    track("videoVisit.create", {
      provider: user!.email,
      clinic: user!.currentClinic?.name ?? "",
    });
    onSubmit({
      clinic: user!.currentClinic,
      patient: selectorMode ? selected : undefined,
      phone: selectorMode ? undefined : phone,
      provider: user,
      user: selectorMode ? selected?.user : undefined,
    });
  };

  const patientInput = (
    <>
      <PatientAutocomplete
        label="Patient Name"
        onChange={changeSelected}
        value={selected}
        autoFocus
      />
      <Button variant="text" onClick={toggleMode}>
        + Invite a new patient
      </Button>
    </>
  );

  const phoneInput = (
    <>
      <TextField
        id="patient-phone"
        onChange={changePhone}
        value={phone}
        label="Patient Phone Number"
        required
        error={!isPhoneValid}
      />
      <Button variant="text" onClick={toggleMode}>
        - Find an existing patient
      </Button>
    </>
  );

  return (
    <FormModal
      icon={Videocam}
      fullWidth
      onClose={onClose}
      open={open}
      showDividers
      title="Video Visit"
      titleProps={{ variant: "h1" }}
      actions={
        <Grid container justify="flex-end">
          <Grid item xs={6} md={3} classes={{ root: classes.primaryAction }}>
            <Button onClick={handleSubmit}>Submit</Button>
          </Grid>
        </Grid>
      }
    >
      <DialogContentText variant="h3">Select a patient</DialogContentText>
      <DialogContentText>
        Patient will be sent a text message to join a video session with you.
      </DialogContentText>
      {selectorMode ? patientInput : phoneInput}
    </FormModal>
  );
};
