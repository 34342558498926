import { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const typography: TypographyOptions = {
  h1: {
    fontWeight: "normal",
    fontSize: "2.125rem",
    lineHeight: 1.18,
    letterSpacing: 0.01,
  },
  h2: {
    fontWeight: "normal",
    fontSize: "1.5rem",
    lineHeight: 1.17,
  },
  h3: {
    fontWeight: "normal",
    fontSize: "1.25rem",
    lineHeight: 1.2,
    letterSpacing: 0.01,
  },
  h4: {
    fontWeight: "normal",
    fontSize: "1rem",
    lineHeight: 1.63,
  },
  h5: {
    fontWeight: "normal",
    fontSize: "0.875rem",
    lineHeight: 1.14,
    letterSpacing: 0,
  },
  h6: {
    fontWeight: "normal",
    fontSize: "0.75rem",
    lineHeight: 1.75,
    letterSpacing: 0,
  },
  body1: {
    fontWeight: "normal",
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: 0,
  },
  body2: {
    fontWeight: "normal",
    fontSize: "0.813rem",
    lineHeight: 1.15,
    letterSpacing: 0.98,
  },
  button: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: 1.14,
    letterSpacing: 0.7,
  },
  caption: {
    fontWeight: "normal",
    fontSize: "0.875rem",
    lineHeight: 1.14,
    letterSpacing: 0.7,
  },
  overline: {
    fontWeight: "bold",
    fontSize: "0.688rem",
    lineHeight: 1.18,
    letterSpacing: 0.83,
  },
};
