import { FunctionComponent, useEffect, useRef } from "react";
import { AudioTrack as IAudioTrack } from "twilio-video";

interface AudioTrackProps {
  track: IAudioTrack;
}

export const AudioTrack: FunctionComponent<AudioTrackProps> = ({ track }) => {
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    audioEl.current = track.attach();
    document.body.appendChild(audioEl.current);
    return () => track.detach().forEach((el) => el.remove());
  }, [track]);

  useEffect(() => {
    audioEl.current?.setSinkId?.("default");
  }, []);

  return null;
};
