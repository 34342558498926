// States
export const UNREGISTERED = "unregistered";
export const FAILED = "failed";
export const REGISTERING = "registering";
export const REGISTERED = "registered";

// Public Events
export const CREATE = "REGISTER.CREATE";
export const RESET = "REGISTER.RESET";

// Private Events
export const FAIL = "REGISTER.FAIL";
export const SUCCEED = "REGISTER.SUCCEED";
