import { useService } from "@xstate/react";
import { useMemo } from "react";
import { interpret } from "xstate";

import { NurseVisit } from "../../../core";
import { logService } from "../../../helpers";

import { CLOSE, READY } from "./constants";
import { createNurseVisitDetailsMachine as machineFactory } from "./machine";
import { NurseVisitDetailsServiceHook } from "./types";

// Exposed API Below
export * from "./types";

export const useNurseVisitService = (
  nurseVisit: NurseVisit,
): NurseVisitDetailsServiceHook => {
  const nurseVisitService = useMemo(() => {
    const machine = machineFactory(nurseVisit);
    const service = interpret(machine).start();
    logService(nurseVisit.id, service);

    return service;
  }, [nurseVisit]);

  const [state, send] = useService(nurseVisitService);

  const { errors, visit } = state.context;

  const didFail = state.matches(READY) && errors !== undefined;

  const close = (message = "") => {
    send({
      type: CLOSE,
      message,
    });
  };

  return [{ didFail, errors: errors ?? [], visit }, { close }];
};
