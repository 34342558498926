import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface ConfirmationModalProps {
  acceptLabel?: string;
  instructions: string;
  onAccept: () => void;
  onClose?: () => void;
  open?: boolean;
  rejectLabel?: string;
  title: string;
}

export const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  acceptLabel = "Yes",
  instructions,
  onAccept,
  onClose = () => null,
  open = false,
  rejectLabel = "No",
  title,
}) => {
  const handleAccept = () => {
    onAccept();
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <DialogTitle id="confirmation-modal-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-modal-description">
          {instructions}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {rejectLabel}
        </Button>
        <Button onClick={handleAccept} color="primary" autoFocus>
          {acceptLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
