import { ConditionPredicate } from "xstate";

import { NurseVisitStatusMap } from "../../../core";

import { NurseVisitDetailsContext, NurseVisitDetailsEvents } from "./types";

export const isNurseVisitClosed: ConditionPredicate<
  NurseVisitDetailsContext,
  NurseVisitDetailsEvents
> = (context) => {
  return context.visit.status === NurseVisitStatusMap.closed;
};
