import {
  checkSmsVerificationPromise,
  logger,
  sendSmsVerificationPromise,
} from "../../../core";

import { FAIL, SUCCEED } from "./constants";
import { VerificationContext, SucceedEvent, FailEvent } from "./types";

export const createSendCodePromise = async () => {
  try {
    await sendSmsVerificationPromise();
  } catch (error) {
    const { messages } = error.response.json();
    logger.error("Failed to send a verification code", messages);
  }
};

export const createVerifyPromise = (
  context: VerificationContext,
): Promise<SucceedEvent> =>
  checkSmsVerificationPromise({ code: context.verification ?? "" }).then(
    ({ success }): SucceedEvent => {
      if (!success) {
        throw new Error("verification was not successfull");
      }
      return { type: SUCCEED };
    },
  );
