import { useState, useEffect } from "react";
import {
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from "twilio-video";

import { RoomTrackEvent } from "./types";

type TrackType =
  | LocalAudioTrack
  | LocalVideoTrack
  | RemoteAudioTrack
  | RemoteVideoTrack;

export const useIsTrackEnabled = (track?: TrackType) => {
  const [isEnabled, setIsEnabled] = useState(track?.isEnabled ?? false);

  useEffect(() => {
    setIsEnabled(track?.isEnabled ?? false);

    if (!track) {
      return () => null;
    }
    const setEnabled = () => setIsEnabled(true);
    const setDisabled = () => setIsEnabled(false);
    track.on(RoomTrackEvent.enabled, setEnabled);
    track.on(RoomTrackEvent.disabled, setDisabled);

    return () => {
      track.off(RoomTrackEvent.enabled, setEnabled);
      track.off(RoomTrackEvent.disabled, setDisabled);
    };
  }, [track]);

  return isEnabled;
};
