import { useMachine } from "@xstate/react";
import { useEffect } from "react";

import { logService } from "../../../helpers";

import { CREATE, SAVED, UNSAVED } from "./constants";
import { createVideoVisitMachine } from "./machine";
import { CreateVideoVisitServiceHook, VideoVisitData } from "./types";

// Exposed API Below
export * from "./types";

export const useCreateVideoVisitMachine = (): CreateVideoVisitServiceHook => {
  const [state, send, nvService] = useMachine(createVideoVisitMachine);

  useEffect(() => {
    logService("create-video-visit", nvService);
  }, [nvService]);

  const didCreate = state.matches(SAVED);

  const didFail =
    state.matches(UNSAVED) &&
    state.context.errors !== undefined &&
    state.context.errors.length > 0;
  const { errors, visit } = state.context;

  const create = (data: VideoVisitData) => {
    send(CREATE, {
      data,
    });
  };

  return [
    { didCreate, didFail, errors: errors ?? "", visit: visit! },
    { create },
  ];
};
