import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles,
} from "@material-ui/core";
import { Clinic } from "@relieftelemed/platform";
import React, { FunctionComponent, ReactNode } from "react";

import { clinicInitials } from "../helpers";
import { reliefTeal, fadedNightBlue } from "../styles";

interface ClinicListItemProps {
  clinic: Clinic;
  onSelect: () => void;
  icon?: ReactNode;
  isSelected: boolean;
}

const useUserMenuStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: 1.14,
    letterSpacing: 1.05,
    width: 36,
    height: 36,
  },
  unselectedClinic: {
    backgroundColor: fadedNightBlue,
  },
  selectedClinic: {
    backgroundColor: reliefTeal,
  },
}));

export const ClinicListItem: FunctionComponent<ClinicListItemProps> = ({
  clinic,
  icon,
  onSelect,
  isSelected,
}) => {
  const classes = useUserMenuStyles();
  return (
    <ListItem button onClick={onSelect} component="div">
      <ListItemAvatar>
        <Avatar
          classes={{ root: classes.avatar }}
          className={
            isSelected ? classes.selectedClinic : classes.unselectedClinic
          }
        >
          {clinicInitials(clinic)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{
          color: "textPrimary",
          variant: "body2",
        }}
        primary={clinic.name}
      />
      <ListItemSecondaryAction>{icon}</ListItemSecondaryAction>
    </ListItem>
  );
};
