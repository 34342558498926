import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useMemo } from "react";

import { FLAG_DASHBOARD } from "./constants";
import { DashboardPage } from "./dashboard";
import { VideoVisitsPage } from "./video-visits";

export const HomePage = () => {
  const flags = useFlags();
  const showDashboard = flags[FLAG_DASHBOARD];

  const HomeComponent = useMemo(
    () => (showDashboard ? DashboardPage : VideoVisitsPage),
    [showDashboard],
  );

  return <HomeComponent />;
};
