import Fuse from "fuse.js";
import { Interpreter, State, DoneInvokeEvent } from "xstate";

import { Patient } from "../../../core";

export interface PatientSearchContext {
  patients?: Fuse<Patient>;
  query: string;
  results: Patient[];
}

export interface PatientSearchSchema {
  states: {
    ready: {};
    searching: {
      states: {
        cold: {};
        warm: {};
        hot: {};
        done: {};
      };
    };
  };
}

// Events

export interface SearchEvent {
  type: "PATIENTS.SEARCH";
  query: string;
}

// Aggregates

export type PatientSearchEvents = SearchEvent | DoneInvokeEvent<Patient[]>;
export const isDoneEvent = (
  event: PatientSearchEvents,
): event is DoneInvokeEvent<Patient[]> =>
  (event as DoneInvokeEvent<Patient[]>).data !== undefined;

export type PatientSearchState = State<
  PatientSearchContext,
  PatientSearchEvents
>;

export type PatientSearchService = Interpreter<
  PatientSearchContext,
  PatientSearchSchema,
  PatientSearchEvents
>;

// Hooks

type SendSearch = (query: string) => void;

interface PatientSearchHookActions {
  search: SendSearch;
}

interface PatientSearchHookSelectors {
  results: Patient[];
  isSearching: boolean;
}

export type PatientSearchServiceHook = [
  PatientSearchHookSelectors,
  PatientSearchHookActions,
];
