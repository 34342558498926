import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";

export const PasswordInput: FunctionComponent<TextFieldProps> = ({
  InputProps,
  ...props
}) => {
  const [isVisible, changeVisibility] = useState(false);

  const handleToggle = () => {
    changeVisibility(!isVisible);
  };

  return (
    <TextField
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleToggle}
              onMouseDown={handleToggle}
            >
              {isVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={isVisible ? "text" : "password"}
      {...props}
    />
  );
};
