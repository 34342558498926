import React, { FunctionComponent } from "react";
import { Redirect } from "react-router-dom";

import { PATH_HOME } from "../constants";
import { useAnalytics } from "../core";
import { useAppService } from "../state";

export const LogoutPage: FunctionComponent = () => {
  const [{ isLoggedIn }, , { logout }] = useAppService();
  const { logout: onLogout } = useAnalytics();

  if (!isLoggedIn) {
    return <Redirect push to={PATH_HOME} />;
  }

  onLogout();
  logout();

  return <div />;
};
