import { Overrides } from "@material-ui/core/styles/overrides";

import { MuiBackdrop } from "./backdrop";
import { MuiButton } from "./button";
import { MuiDialog } from "./dialog";
import { MuiDialogContent } from "./dialog-content";
import { MuiDialogContentText } from "./dialog-content-text";
import { MuiFormControlLabel } from "./form-control-label";
import { MuiInputLabel } from "./input-label";
import { MuiOutlinedInput } from "./outlined-input";
import { MuiStepLabel } from "./step-label";
import { MuiTab } from "./tab";
import { MuiTableCell } from "./table-cell";

export const overrides: Overrides = {
  MuiBackdrop,
  MuiButton,
  MuiDialog,
  MuiDialogContent,
  MuiDialogContentText,
  MuiFormControlLabel,
  MuiInputLabel,
  MuiOutlinedInput,
  MuiStepLabel,
  MuiTab,
  MuiTableCell,
};
