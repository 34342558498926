import { logger } from "@relieftelemed/platform";
import React, { Component, PropsWithChildren } from "react";

type ErrorProps = PropsWithChildren<{}>;

interface ErrorState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorProps, ErrorState> {
  constructor(props: ErrorProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown): ErrorState {
    logger.log(error);

    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    logger.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return children;
  }
}
