export const FLAG_ADMIN = "clinicAdmin";
export const FLAG_DASHBOARD = "dashboard";
export const FLAG_NOTIFICATIONS = "notifications";
export const FLAG_NURSE_VISIT = "nurseVisit";
export const FLAG_PAST_VISITS = "pastVisits";
export const FLAG_VIDEO_VISIT = "videoVisit";
export const FLAG_START_VIDEO_VISIT = "startVideoVisit";
export const FLAG_VIEW_PATIENTS = "viewPatients";
export const FLAG_PROFILE = "profile";

export const PATH_ROOT = "";
export const PATH_HOME = `${PATH_ROOT}/`;
export const PATH_ADMIN = `${PATH_ROOT}/admin`;
export const PATH_DASHBOARD = `${PATH_ROOT}/dashboard`;
export const PATH_FORGOT_EMAIL = `${PATH_ROOT}/forgot-email`;
export const PATH_FORGOT_PASSWORD = `${PATH_ROOT}/forgot-password`;
export const PATH_RESET_PASSWORD = `${PATH_ROOT}/reset-password/:email/:token`;
export const PATH_LOGIN = `${PATH_ROOT}/login`;
export const PATH_LOGOUT = `${PATH_ROOT}/logout`;
export const PATH_NOTIFICATIONS = `${PATH_ROOT}/notifications`;
export const PATH_ONBOARD = `${PATH_ROOT}/onboard`;
export const PATH_PATIENTS = `${PATH_ROOT}/patients`;
export const PATH_REGISTER = `${PATH_ROOT}/register`;
export const PATH_VISITS = `${PATH_ROOT}/visits`;
export const PATH_CONSULTATION = `${PATH_ROOT}/visit/:id`;
export const PATH_NURSE_VISITS = `${PATH_VISITS}/nurse`;
export const PATH_NURSE_VISITS_STATUS = `${PATH_NURSE_VISITS}/:status?`;
export const PATH_VIDEO_VISITS = `${PATH_VISITS}/video`;
export const PATH_PROFILE = `${PATH_ROOT}/profile`;

export const UPDATE_EVENT_TYPE = "PARENT.UPDATE";

export const UPDATE_EVENT = {
  type: UPDATE_EVENT_TYPE,
};

// Machine Names
export const AUTHENTICATION_MACHINE = "authentication";
export const ONBOARDING_MACHINE = "onboarding";
export const REGISTRATION_MACHINE = "registration";
export const VERIFICATION_MACHINE = "verification";
