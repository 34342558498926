import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

import {
  useMainParticipant,
  useParticipants,
  useScreenShareParticipant,
  useSelectedParticipant,
} from "../../hooks";

import { ParticipantTracks } from "./participant-tracks";

const useVideoStyles = makeStyles({
  root: {
    position: "relative",
  },
  backdrop: {
    zIndex: 1,
    position: "absolute",
  },
});

export const MainParticipant = () => {
  const classes = useVideoStyles();
  const participants = useParticipants();
  const mainParticipant = useMainParticipant();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    mainParticipant === selectedParticipant ||
    mainParticipant === screenShareParticipant
      ? "high"
      : null;

  let connecting = null;
  if (participants.length === 0) {
    connecting = (
      <Backdrop open className={classes.backdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <div className={classes.root}>
      <ParticipantTracks
        participant={mainParticipant}
        videoPriority={videoPriority}
      />
      {connecting}
    </div>
  );
};
