import { TextField, Typography } from "@material-ui/core";
import React, { FunctionComponent, useState, ChangeEventHandler } from "react";

import { EditableCard } from "./editable-card";

export interface EditableFieldProps {
  label: string;
  value: string;
  onSave: (newValue: string) => void;
}

export const EditableField: FunctionComponent<EditableFieldProps> = ({
  label,
  value: initialValue,
  onSave,
}) => {
  const [value, setValue] = useState(initialValue);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
  };

  const handleSave = () => {
    onSave(value);
  };

  return (
    <EditableCard
      content={<Typography variant="body2">{initialValue}</Typography>}
      editContent={
        <TextField label={label} onChange={handleChange} value={value} />
      }
      label={label}
      onSave={handleSave}
    />
  );
};
