import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";

export const AmbulanceSideView: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 13" {...props}>
    <g id="prefix__ambulance-side-view" transform="translate(0 -60.039)">
      <g
        id="prefix__Group_436"
        data-name="Group 436"
        transform="translate(0 60.039)"
      >
        <path
          id="prefix__Path_501"
          d="M44.086 224.219a1.837 1.837 0 1 0 1.837 1.837 1.837 1.837 0 0 0-1.837-1.837zm0 2.635a.8.8 0 1 1 .8-.8.8.8 0 0 1-.8.8z"
          className="prefix__cls-1"
          data-name="Path 501"
          transform="translate(-39.897 -215.079)"
        />
        <path
          id="prefix__Path_502"
          d="M17.475 66.275c-.674-.175-1.059-.285-1.323-.8l-.884-1.791a1.893 1.893 0 0 0-1.527-.929h-1.373V61.1a1.062 1.062 0 0 0-1.059-1.059H1.059A1.062 1.062 0 0 0 0 61.1v8.971a1.062 1.062 0 0 0 1.059 1.059H1.8v-.111a2.394 2.394 0 0 1 4.788 0v.111h4.73a1.059 1.059 0 0 0 .175-.015v-.1a2.394 2.394 0 0 1 4.788 0v.111h2.186a1.049 1.049 0 0 0 1.033-1.045v-1.987c0-1.674-1.465-1.674-2.025-1.819zM8.9 65.4a.55.55 0 0 1-.548.548h-1.14v1.137a.55.55 0 0 1-.548.548H5.7a.55.55 0 0 1-.548-.548V65.95H4.02a.55.55 0 0 1-.548-.548v-.959a.55.55 0 0 1 .548-.543h1.137v-1.142a.55.55 0 0 1 .543-.548h.959a.55.55 0 0 1 .548.548V63.9h1.142a.55.55 0 0 1 .548.548zm3.472.78v-2.7h1a1.684 1.684 0 0 1 1.359.826l.787 1.593a1.137 1.137 0 0 0 .195.275z"
          className="prefix__cls-1"
          data-name="Path 502"
          transform="translate(0 -60.039)"
        />
        <path
          id="prefix__Path_503"
          d="M218.082 224.219a1.837 1.837 0 1 0 1.837 1.837 1.837 1.837 0 0 0-1.837-1.837zm0 2.635a.8.8 0 1 1 .8-.8.8.8 0 0 1-.8.8z"
          className="prefix__cls-1"
          data-name="Path 503"
          transform="translate(-204.206 -215.079)"
        />
      </g>
    </g>
  </SvgIcon>
);
