import { useCallback } from "react";
import { LocalAudioTrack } from "twilio-video";

import { useIsTrackEnabled } from "./use-is-track-enabled";
import { useVideoContext } from "./use-video-context";

type ToggleAudio = () => void;

export const useAudioToggle = (): [boolean, ToggleAudio] => {
  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find(
    (track) => track.kind === "audio",
  ) as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (!audioTrack) {
      return;
    }

    if (isEnabled) {
      audioTrack.disable();
    } else {
      audioTrack.enable();
    }
  }, [audioTrack, isEnabled]);

  return [isEnabled, toggleAudioEnabled];
};
