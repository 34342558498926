import { CallEnd } from "@material-ui/icons";
import { Howl } from "howler";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import { useParticipants, useVideoContext } from "../../hooks";

import { ControlButton } from "./control-button";

const ring = new Howl({
  src: [`${process.env.PUBLIC_URL}/audio/ring.mp3`],
  loop: true,
});

export const ControlDisconnect: FunctionComponent = () => {
  const { disconnect } = useVideoContext();
  const participants = useParticipants();
  const [hasRung, setHasRung] = useState(false);
  const timeoutId = useRef<number>();

  useEffect(() => {
    // We have participants
    if (participants.length > 0) {
      // So get rid of the timeout
      if (timeoutId) {
        window.clearTimeout(timeoutId.current);
      }
      // And stop the sound
      ring.stop();
      setHasRung(true);
    } else if (!hasRung) {
      // Otherwise, set a disconnect timeout and start the ringtone
      timeoutId.current = window.setTimeout(() => {
        disconnect();
      }, 25 * 1000);

      ring.play();
    }

    // Stop the timeout and ringing if we're unmounting
    return () => {
      window.clearTimeout(timeoutId.current);
      ring.stop();
    };
  }, [disconnect, hasRung, participants]);

  return (
    <ControlButton onClick={disconnect} tooltip="Leave the room">
      <CallEnd color="error" />
    </ControlButton>
  );
};
