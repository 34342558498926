import {
  Card,
  CardActions,
  CardContent,
  CardProps,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { FormEventHandler, FunctionComponent, ReactNode } from "react";

interface FormProps {
  actions: ReactNode;
  instructions?: ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement & HTMLDivElement>;
  title: ReactNode;
}

const useFormCardStyles = makeStyles((theme) => ({
  root: {
    background: "none",
    margin: -theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
  headerGutter: {
    marginBottom: theme.spacing(2),
  },
}));

export type FormCardProps = FormProps & Partial<CardProps>;

export const FormCard: FunctionComponent<FormCardProps> = ({
  actions,
  children,
  onSubmit,
  title,
  instructions,
  ...props
}) => {
  const cardStyles = useFormCardStyles();

  let caption = null;
  if (instructions) {
    caption = (
      <Typography variant="body1" component="p">
        {instructions}
      </Typography>
    );
  }

  const content = (
    <>
      <CardContent>
        <Typography
          gutterBottom
          variant="h1"
          classes={{ gutterBottom: cardStyles.headerGutter }}
        >
          {title}
        </Typography>
        {caption}
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          {children}
        </Grid>
      </CardContent>
      <CardActions classes={{ root: cardStyles.actions }}>
        {actions}
      </CardActions>
    </>
  );

  return (
    <Card classes={{ root: cardStyles.root }} {...props}>
      {onSubmit ? <form onSubmit={onSubmit}>{content}</form> : content}
    </Card>
  );
};
