import { useEffect, useState } from "react";

import { RoomStateType } from "./types";
import { useVideoContext } from "./use-video-context";

export const useRoomState = () => {
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>(RoomStateType.disconnected);

  useEffect(() => {
    const setRoomState = () => {
      const newState: RoomStateType =
        (<any>RoomStateType)[room.state] ?? RoomStateType.disconnected;

      setState(newState);
    };

    setRoomState();

    room
      .on(RoomStateType.disconnected, setRoomState)
      .on(RoomStateType.connected, setRoomState)
      .on(RoomStateType.reconnecting, setRoomState);

    return () => {
      room
        .off(RoomStateType.disconnected, setRoomState)
        .off(RoomStateType.connected, setRoomState)
        .off(RoomStateType.reconnecting, setRoomState);
    };
  }, [room]);

  return state;
};
