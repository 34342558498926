import React, { FunctionComponent } from "react";
import {
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from "twilio-video";

import { isVideoTrack } from "../../helpers";
import { useTrack } from "../../hooks";

import { AudioTrack } from "./audio-track";
import { VideoTrack } from "./video-track";

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocal: boolean;
  disableAudio?: boolean;
  videoPriority?: Track.Priority | null;
}

export const Publication: FunctionComponent<PublicationProps> = ({
  publication,
  isLocal,
  disableAudio,
  videoPriority,
}) => {
  const track = useTrack(publication);

  if (!track) {
    return null;
  }

  switch (track.kind) {
    case "video":
      return (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={isVideoTrack(track) && isLocal}
        />
      );

    case "audio":
      return disableAudio ? null : <AudioTrack track={track} />;

    default:
      return null;
  }
};
