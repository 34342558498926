import { User } from "@relieftelemed/platform";

import { analytics as mixpanel } from "./mixpanel";

interface EventDetails {
  [key: string]: string | number | boolean;
}

let hasSetupLinks = false;

export const Analytics = {
  login(user: User) {
    mixpanel.identify(user.id);
    mixpanel.people.set_once({
      $email: user.email,
      orderedNurseVisits: 0,
    });
  },

  logout() {
    mixpanel.reset();
  },

  incrementNurseVisits() {
    mixpanel.people.increment("orderedNurseVisits");
  },

  sendBeacon(event: string, details?: EventDetails) {
    mixpanel.track(event, details, { transport: "sendBeacon" });
  },

  track(event: string, details?: EventDetails) {
    mixpanel.track(event, details);
  },

  trackLinks() {
    if (!hasSetupLinks) {
      mixpanel.track_links(".MuiDrawer-root nav a", "click nav link");
      hasSetupLinks = true;
    }
  },
};
