import { useMemo } from "react";

import { Patient, Visit } from "../core";

export function useFilteredVisits<
  TStatus extends string,
  TVisit extends Visit = Visit,
>(
  statuses: TStatus[],
  visits: TVisit[] = [],
  currentPatient?: Patient,
): Record<TStatus, TVisit[]> {
  return useMemo(() => {
    return statuses.reduce((aggregate, status: TStatus) => {
      const visitsForStatus = visits.filter((visit) => {
        if (visit.status !== status) {
          return false;
        }

        if (!currentPatient) {
          return true;
        }

        if (typeof visit.patient === "string") {
          return visit.patient === currentPatient.id;
        }

        return visit.patient.id === currentPatient.id;
      });

      return {
        ...aggregate,
        [status]: visitsForStatus,
      };
    }, {} as Record<TStatus, TVisit[]>);
  }, [statuses, currentPatient, visits]);
}
