import { useEffect, useState } from "react";
import { RemoteParticipant } from "twilio-video";

import { RoomParticipantEvent, RoomStateType } from "./types";
import { useDominantSpeaker } from "./use-dominant-speaker";
import { useVideoContext } from "./use-video-context";

export const useParticipants = () => {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();

  const initialParticipants =
    room.state === RoomStateType.connected
      ? Array.from(room.participants.values())
      : [];
  const [participants, setParticipants] = useState(initialParticipants);

  // Move dominant speaker up so they are visible in strip
  useEffect(() => {
    if (dominantSpeaker) {
      setParticipants((prevParticipants) => [
        dominantSpeaker,
        ...prevParticipants.filter(
          (participant) => participant !== dominantSpeaker,
        ),
      ]);
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    if (room.state !== RoomStateType.connected) {
      return () => null;
    }

    const addParticipant = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) => [...prevParticipants, participant]);

    const removeParticipant = (participant: RemoteParticipant) =>
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant),
      );

    room.on(RoomParticipantEvent.connected, addParticipant);
    room.on(RoomParticipantEvent.disconnected, removeParticipant);

    return () => {
      room.off(RoomParticipantEvent.connected, addParticipant);
      room.off(RoomParticipantEvent.disconnected, removeParticipant);
    };
  }, [room]);

  return participants;
};
