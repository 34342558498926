import { Divider, Grid, List, makeStyles, Typography } from "@material-ui/core";
import {
  Check,
  Dashboard,
  Group,
  Notifications,
  Settings,
  Videocam,
} from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent } from "react";

import { NavItem } from "../../components";
import {
  FLAG_ADMIN,
  FLAG_DASHBOARD,
  FLAG_NOTIFICATIONS,
  FLAG_NURSE_VISIT,
  FLAG_PAST_VISITS,
  FLAG_VIDEO_VISIT,
  FLAG_VIEW_PATIENTS,
  PATH_ADMIN,
  PATH_DASHBOARD,
  PATH_HOME,
  PATH_NOTIFICATIONS,
  PATH_NURSE_VISITS,
  PATH_PATIENTS,
  PATH_VIDEO_VISITS,
  PATH_VISITS,
} from "../../constants";
import { AmbulanceSideView, inputPlaceholder } from "../../styles";

import { StartVisitButton } from "./start-visit-button";
import { UserMenu } from "./user-menu";

const useNavStyles = makeStyles({
  root: {
    height: "100%",
  },
  divider: {
    backgroundColor: inputPlaceholder,
  },
});

export const Navigation: FunctionComponent = () => {
  const classes = useNavStyles();
  const flags = useFlags();
  const showDashboard = flags[FLAG_DASHBOARD];

  return (
    <Grid
      classes={{ root: classes.root }}
      alignItems="stretch"
      container
      direction="column"
      justify="flex-start"
      spacing={2}
    >
      <Grid item>
        <UserMenu />
      </Grid>
      <Divider classes={{ root: classes.divider }} />
      <Grid item>
        <Typography variant="srOnly" id="nav-header">
          Main Navigation
        </Typography>
        <List aria-labelledby="nav-header" component="nav">
          <NavItem
            flag={FLAG_DASHBOARD}
            icon={Dashboard}
            link={showDashboard ? PATH_HOME : PATH_DASHBOARD}
            text="Dashboard"
          />
          <NavItem
            flag={FLAG_VIDEO_VISIT}
            icon={Videocam}
            link={showDashboard ? PATH_VIDEO_VISITS : PATH_HOME}
            text="Video Visits"
          />
          <NavItem
            flag={FLAG_NURSE_VISIT}
            icon={AmbulanceSideView}
            link={PATH_NURSE_VISITS}
            text="Nurse Visits"
          />
          <NavItem
            flag={FLAG_PAST_VISITS}
            icon={Check}
            link={PATH_VISITS}
            text="Past Visits"
          />
          <NavItem
            flag={FLAG_VIEW_PATIENTS}
            icon={Group}
            link={PATH_PATIENTS}
            text="Patients"
          />
          <NavItem
            flag={FLAG_NOTIFICATIONS}
            icon={Notifications}
            link={PATH_NOTIFICATIONS}
            text="Notifications"
          />
          <NavItem
            flag={FLAG_ADMIN}
            icon={Settings}
            link={PATH_ADMIN}
            text="Administration"
          />
        </List>
      </Grid>
      <Grid item xs />
      <Grid item>
        <StartVisitButton />
      </Grid>
    </Grid>
  );
};
