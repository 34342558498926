import { Machine, sendParent } from "xstate";

import { ONBOARDING_MACHINE, UPDATE_EVENT } from "../../../constants";

import {
  clearContext,
  saveError,
  saveOnboarding,
  saveProvider,
} from "./actions";
import {
  FAIL,
  FAILED,
  IDLE,
  ONBOARD,
  ONBOARDED,
  ONBOARDING,
  RESET,
  SUCCEED,
} from "./constants";
import { isOnboardingValid } from "./guards";
import { createOnboardPromise } from "./promises";
import { OnboardingEvents, OnboardingContext, OnboardingSchema } from "./types";

export const onboardMachine = Machine<
  OnboardingContext,
  OnboardingSchema,
  OnboardingEvents
>(
  {
    id: ONBOARDING_MACHINE,
    initial: IDLE,
    context: {
      errors: [],
      user: {
        email: "",
        firstName: "",
        lastName: "",
        id: "",
        phone: "",
        verifiedEmail: false,
        verifiedMobile: false,
      },
    },
    states: {
      [IDLE]: {
        on: {
          [ONBOARD]: [
            {
              target: ONBOARDING,
              cond: { type: "isOnboardingValid" },
              actions: "saveOnboarding",
            },
            {
              target: FAILED,
            },
          ],
        },
      },
      [FAILED]: {
        entry: sendParent(UPDATE_EVENT, { delay: 200 }),
        exit: sendParent(UPDATE_EVENT, { delay: 200 }),
        on: {
          [ONBOARD]: [
            {
              target: ONBOARDING,
              cond: { type: "isOnboardingValid" },
              actions: "saveOnboarding",
            },
            {
              target: FAILED,
            },
          ],
          [RESET]: {
            target: IDLE,
            actions: "clearContext",
          },
        },
      },
      [ONBOARDING]: {
        invoke: {
          src: createOnboardPromise,
          id: "onboard",
          onDone: {
            target: ONBOARDED,
            actions: ["clearContext", "saveProvider"],
          },
          onError: {
            target: FAILED,
            actions: ["clearContext", "saveError"],
          },
        },
      },
      [ONBOARDED]: {
        type: "final",
        data: ({ provider, user }) => ({
          user: {
            ...provider,
            ...user,
          },
        }),
      },
    },
  },
  {
    actions: {
      clearContext,
      saveError,
      saveOnboarding,
      saveProvider,
    },
    guards: {
      isOnboardingValid,
    },
  },
);
