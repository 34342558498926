import {
  Button as MuiButton,
  ButtonProps,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

export const Button: FunctionComponent<ButtonProps> = (props) => {
  const theme = useTheme();
  const isSvp = useMediaQuery(theme.breakpoints.down("sm"));

  return <MuiButton fullWidth={isSvp} {...props} />;
};
