// States
export const FAILED = "failed";
export const UNVERIFIED = "unverified";
export const VERIFYING = "verifying";
export const VERIFIED = "verified";

// Events
export const FAIL = "FAIL";
export const RESET = "RESET";
export const SEND_CODE = "SEND_CODE";
export const SKIP = "SKIP";
export const SUCCEED = "SUCCEED";
export const VERIFY = "VERIFY";
