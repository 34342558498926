import { ONBOARDING_MACHINE } from "../../../constants";
import { logService } from "../../../helpers";

import { FAILED, ONBOARD, RESET } from "./constants";
import {
  OnboardingData,
  OnboardingServiceHook,
  OnboardingService,
} from "./types";

// Exposed API Below
export { onboardMachine } from "./machine";
export * from "./types";

export const useOnboardService = (
  onboardService: OnboardingService,
): OnboardingServiceHook => {
  logService(ONBOARDING_MACHINE, onboardService);

  const didFail = onboardService?.state.matches(FAILED) || false;
  const errors = onboardService?.state.context.errors || [];

  const onboard = (details: OnboardingData) => {
    onboardService?.send({
      type: ONBOARD,
      details,
    });
  };

  const reset = () => {
    onboardService?.send({ type: RESET });
  };

  return [
    { didFail, errors },
    { onboard, reset },
  ];
};
