import { fade } from "@material-ui/core/styles/colorManipulator";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";

export const blue = "#0099cc";
export const grey = "#818697";
export const nightBlue = "#050f31"; // primaryText, nav bg
export const purple = "#523c82"; // was "#563d82";
export const reliefTeal = "#20ACAF";
export const white = "#ffffff";
export const fadedNightBlue = fade(nightBlue, 0.5);
export const blackPearl = "#02132c";

export const primary = blue;
export const secondary = grey;
export const accent = purple;

export const activePurple = accent;
export const navSelected = "#373f5a";

export const error = "#ed5858";
export const warning = "#ffe489";
export const success = "#5fd56d";
export const disabled = "#cdcfd5";

export const background = "#fafbff"; // was "#fbfbfb";

export const inputPlaceholder = "#9ca2aa";
export const inputActiveBg = white;
export const inputInactiveBg = "#eeeeee";
export const inputBorderColor = "#e7e7e7";

export const hoverOnWhiteBg = "#f8f8f8";
export const cta = blue;
export const ctaBg = "#e2f2f7";
export const borderColor = "#d9dae0";
export const textLightBg = "#f3f3f3";

export const palette: PaletteOptions = {
  primary: {
    main: blue,
  },
  secondary: {
    main: grey,
  },
  error: {
    main: error,
  },
  warning: {
    main: warning,
  },
  success: {
    main: success,
  },
  text: {
    primary: nightBlue,
    disabled,
  },
  background: {
    paper: white,
    default: background,
  },
};
