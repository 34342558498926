import {
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { SplitButton } from "../../components";
import { PATH_VIDEO_VISITS } from "../../constants";
import { VideoVisit } from "../../core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      justifyContent: "center",
      display: "flex",
    },
  }),
);

interface FinalizeVisitProps {
  onChange: (visitInfo: Partial<VideoVisit>) => void;
}

export const FinalizeVisit: FunctionComponent<FinalizeVisitProps> = ({
  onChange,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [finished, setFinished] = useState(false);

  const finishAndUpdate = (status: string) => {
    setFinished(true);
    onChange({
      status,
    });
  };

  const finalizeOptions = [
    {
      label: "Close",
      onClick: () => {
        finishAndUpdate("closed");
      },
    },
    {
      label: "Save for Later",
      onClick: () => {
        finishAndUpdate("charting");
      },
    },
  ];

  return !finished ? (
    <Card>
      <CardHeader
        titleTypographyProps={{ variant: "overline" }}
        title="Save Visit"
      />
      <CardContent className={classes.root}>
        <SplitButton options={finalizeOptions} />
      </CardContent>
    </Card>
  ) : (
    <Redirect
      to={{
        pathname: PATH_VIDEO_VISITS,
        state: { from: location },
      }}
    />
  );
};
