import { assign, DoneInvokeEvent } from "xstate";

import { logger, setBaseHeader } from "../core";

import { SET_USER, CHANGE_CLINIC, GET_FULL_CLINIC_SUCCEED } from "./constants";
import {
  ApplicationContext,
  ApplicationEvents,
  DoneLoginData,
  DoneOnboardData,
  isDoneEvent,
  ChangeClinicEvent,
} from "./types";

export const incrememtPing = assign<ApplicationContext, ApplicationEvents>({
  pings: (context) => context.pings + 1,
});

export const changeClinic = assign<ApplicationContext, ApplicationEvents>({
  user: (context, event) => {
    if (event.type !== CHANGE_CLINIC) {
      return context.user;
    }

    return {
      ...context.user!,
      currentClinic: (event as ChangeClinicEvent).clinic,
    };
  },
});

export const updateFullClinic = assign<ApplicationContext, ApplicationEvents>({
  user: (context, event) =>
    isDoneEvent(event)
      ? {
          ...context.user!,
          currentClinic: (event as DoneInvokeEvent<any>).data,
        }
      : context.user,
});

export const removeUser = assign<ApplicationContext, ApplicationEvents>({
  token: "",
  user: undefined,
});

export const revokeToken = () => {
  logger.debug("Removing user token");
  setBaseHeader("Authorization", "");
};

export const saveUser = assign<ApplicationContext, ApplicationEvents>({
  token: (context, event) =>
    isDoneEvent<DoneLoginData>(event) ? event.data.token : context.token,
  user: (context, event) =>
    isDoneEvent<DoneLoginData>(event) ? event.data.user : context.user,
});

export const setToken = (context: ApplicationContext) => {
  logger.debug("Setting user token");
  setBaseHeader("Authorization", `Token ${context.token}`);
};

export const updateSession = assign<ApplicationContext, ApplicationEvents>({
  user: (context, event) =>
    isDoneEvent<DoneOnboardData>(event) ? event.data.user : context.user,
});

export const updateUser = assign<ApplicationContext, ApplicationEvents>({
  user: (context, event) =>
    isDoneEvent<DoneOnboardData>(event) ? event.data.user : context.user,
});
