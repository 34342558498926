import React, { FunctionComponent } from "react";

import { isLocalVideoTrack } from "../../helpers";
import { useVideoContext } from "../../hooks";

import { VideoTrack } from "./video-track";

export const LocalVideoPreview: FunctionComponent = () => {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(isLocalVideoTrack);

  return videoTrack ? <VideoTrack track={videoTrack} isLocal /> : null;
};
