import {
  createVideoVisit as createVideoVisitApi,
  isApiError,
} from "../../../core";

import { FAIL, SUCCEED } from "./constants";
import {
  CreateVideoVisitContext,
  FailEvent,
  SucceedEvent,
  VisitFromNumber,
  VisitFromPatient,
} from "./types";

export const createVideoVisit = async (
  context: CreateVideoVisitContext,
): Promise<SucceedEvent | FailEvent> => {
  try {
    await createVideoVisitApi({
      clinic: context.data?.clinic?.id!,
      patient: (context.data as VisitFromPatient).patient?.id,
      provider: context.data?.provider?.id!,
      household: (context.data as VisitFromPatient).patient.household as string, // TODO(james) Values here were hardcoded when unknown
      patientInsurance: {},
      stripePaymentMethodId: "",
      queueCode: "",
      complaintDuration: "",
      previouslyDiagnosed: false,
      primaryComplaint: "",
      symptoms: [],
      healthHistory: [],
    });
    return {
      type: SUCCEED,
    };
  } catch (error) {
    const messages = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};
