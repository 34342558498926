import { Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";

import { EditableCard, UsageSelector } from "../../components";
import { Usage, VideoVisit } from "../../core";

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

export const SocialHistory: FunctionComponent<VisitDetail> = ({
  onChange = () => null,
  visit,
}) => {
  const [drinkAlcohol, setDrinkAlcohol] = useState<Usage | undefined>(
    visit.healthHistory?.drinkAlcohol,
  );
  const [useDrugs, setUseDrugs] = useState<Usage | undefined>(
    visit.healthHistory?.useDrugs,
  );
  const [useTobacco, setUseTobacco] = useState<Usage | undefined>(
    visit.healthHistory?.useTobacco,
  );

  const handleChange = () => {
    onChange({
      healthHistory: {
        ...visit.healthHistory!,
        drinkAlcohol,
        useDrugs,
        useTobacco,
      },
    });
  };

  const handleCancel = () => {};

  return (
    <EditableCard
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Alcohol: {visit.healthHistory?.drinkAlcohol}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Drugs: {visit.healthHistory?.useDrugs}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Tobacco: {visit.healthHistory?.useTobacco}
            </Typography>
          </Grid>
        </Grid>
      }
      editContent={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UsageSelector
              value={drinkAlcohol}
              onChange={setDrinkAlcohol}
              label="Alcohol"
              id="drinkAlcohol"
            />
          </Grid>
          <Grid item xs={12}>
            <UsageSelector
              value={useDrugs}
              onChange={setUseDrugs}
              label="Drugs"
              id="useDrugs"
            />
          </Grid>
          <Grid item xs={12}>
            <UsageSelector
              value={useTobacco}
              onChange={setUseTobacco}
              label="Tobacco"
              id="useTobacco"
            />
          </Grid>
        </Grid>
      }
      label="Social History"
      onCancel={handleCancel}
      onSave={handleChange}
    />
  );
};
