import { Machine } from "xstate";

import { saveError, saveVisits } from "./actions";
import { FETCH, FETCHING, READY } from "./constants";
import { fetchAllNurseVisits } from "./promises";
import {
  NurseVisitsEvents,
  NurseVisitsContext,
  NurseVisitsSchema,
} from "./types";

export const nurseVisitsMachine = Machine<
  NurseVisitsContext,
  NurseVisitsSchema,
  NurseVisitsEvents
>(
  {
    id: "nurse-visits",
    initial: FETCHING,
    context: {
      errors: [],
      visits: [],
    },
    states: {
      [FETCHING]: {
        invoke: {
          id: "fetch-all",
          src: fetchAllNurseVisits,
          onDone: {
            target: READY,
            actions: ["saveVisits"],
          },
          onError: {
            target: READY,
            actions: ["saveError"],
          },
        },
      },
      [READY]: {
        on: {
          [FETCH]: FETCHING,
        },
      },
    },
  },
  {
    actions: {
      saveError,
      saveVisits,
    },
  },
);
