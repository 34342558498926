import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIconProps,
} from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, {
  ComponentType,
  ElementType,
  FunctionComponent,
  MouseEvent,
} from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

import { useAnalytics } from "../core";
import { navSelected, nightBlue, reliefTeal } from "../styles";

export interface NavItemProps {
  flag?: string;
  icon?: ComponentType<SvgIconProps>;
  link?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  text: string;
}

interface RootNavProps {
  component?: ElementType;
  to?: string;
}

const useNavItemStyles = makeStyles((theme) => ({
  root: {
    borderLeft: `1px solid ${nightBlue}`,
    "&:hover": {
      backgroundColor: navSelected,
      borderLeftColor: theme.palette.common.white,
    },
  },
  selected: {
    "&.MuiListItem-root, &.MuiListItem-root:hover": {
      backgroundColor: navSelected,
      borderLeftColor: reliefTeal,
    },
    "& .MuiTypography-root, & .MuiListItemIcon-root": {
      color: reliefTeal,
    },
  },
  icon: {
    color: theme.palette.common.white,
    minWidth: theme.spacing(4),
  },
  text: {
    color: theme.palette.common.white,
  },
}));

export const NavItem: FunctionComponent<NavItemProps> = ({
  flag = undefined,
  icon: Icon = undefined,
  link = undefined,
  onClick = undefined,
  text,
}) => {
  const classes = useNavItemStyles();
  const flags = useFlags();
  const { track } = useAnalytics();
  const match = useRouteMatch(link!);

  const selected = Boolean(match && match?.isExact);

  const allowed = !flag || flags[flag];

  if (!allowed) {
    return null;
  }

  const rootProps: RootNavProps = {};

  if (link && !onClick) {
    rootProps.component = Link;
    rootProps.to = link;
  }

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    track("nav.click", { to: link ?? text });
    if (onClick) {
      onClick(event);
    }
  };

  let leftIcon = null;
  if (Icon) {
    leftIcon = (
      <ListItemIcon classes={{ root: classes.icon }}>
        <Icon />
      </ListItemIcon>
    );
  }

  return (
    <ListItem
      classes={{ root: classes.root, selected: classes.selected }}
      button
      onClick={handleClick}
      {...rootProps}
      selected={selected}
    >
      {leftIcon}
      <ListItemText
        primaryTypographyProps={{
          classes: {
            root: classes.text,
          },
          variant: "body1",
        }}
        primary={text}
      />
    </ListItem>
  );
};
