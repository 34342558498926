import { assign, DoneInvokeEvent, ErrorExecutionEvent } from "xstate";

import { CREATE, FAIL, PARSE_SERVICES, SUCCEED, UPDATE } from "./constants";
import {
  CreateNurseVisitContext,
  CreateNurseVisitEvents,
  isDoneEvent,
  UpdateEvent,
} from "./types";

export const saveData = assign<CreateNurseVisitContext, CreateNurseVisitEvents>(
  {
    data: (_context, event) => (event.type !== CREATE ? undefined : event.data),
    errors: undefined,
  },
);

export const updateVisitData = assign<
  CreateNurseVisitContext,
  CreateNurseVisitEvents
>({
  visit: (_context, event) =>
    event.type !== UPDATE ? undefined : (event as UpdateEvent).visit,
  errors: undefined,
});

export const saveError = assign<
  CreateNurseVisitContext,
  CreateNurseVisitEvents
>({
  errors: (_context, event) =>
    event.type !== "error.execution" ? "" : event.data.errors,
});

export const saveServices = assign<
  CreateNurseVisitContext,
  CreateNurseVisitEvents
>({
  errors: undefined,
  services: (_context, event) =>
    isDoneEvent(event) ? (event as DoneInvokeEvent<any>).data : [],
});

export const saveVisit = assign<
  CreateNurseVisitContext,
  CreateNurseVisitEvents
>({
  data: undefined,
  errors: undefined,
  visit: (context, event) =>
    isDoneEvent(event) ? (event as DoneInvokeEvent<any>).data : undefined,
});
