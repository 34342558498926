import { getNurseVisits, marshallNurseVisit, NurseVisit } from "../../../core";

import { NurseVisitsContext, NurseVisitsEvents } from "./types";

export const fetchAllNurseVisits = async (
  _context: NurseVisitsContext,
  _event: NurseVisitsEvents,
): Promise<NurseVisit[]> => {
  try {
    const response = await getNurseVisits();
    return response.map(marshallNurseVisit);
  } catch (error) {
    const { messages } = error.response.json();
    return Promise.reject(Object.values(messages).flat().join(", "));
  }
};
