import { useEffect, useState } from "react";
import { Participant } from "twilio-video";

import { RoomParticipantEvent } from "./types";

export const useNetworkQuality = (participant: Participant) => {
  const [networkQualityLevel, setNetworkQualityLevel] = useState(
    participant?.networkQualityLevel,
  );

  useEffect(() => {
    if (!participant) {
      return () => null;
    }

    const handleChange = (newNetworkQualityLevel: number) => {
      setNetworkQualityLevel(newNetworkQualityLevel);
    };

    setNetworkQualityLevel(participant.networkQualityLevel);
    participant.on(RoomParticipantEvent.qualityChanged, handleChange);

    return () => {
      participant.off(RoomParticipantEvent.qualityChanged, handleChange);
    };
  }, [participant]);

  return networkQualityLevel;
};
