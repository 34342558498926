import { useCallback, useRef, useState } from "react";
import { LocalVideoTrack } from "twilio-video";

import { isLocalTrackVideo } from "../../helpers";

import { RoomTrackEvent } from "./types";
import { useVideoContext } from "./use-video-context";

type ToggleVideo = () => void;

export const useVideoToggle = (): [boolean, ToggleVideo] => {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
  } = useVideoContext();

  const videoTrack = localTracks.find(isLocalTrackVideo);
  const [isPublishing, setIspublishing] = useState(false);
  const previousDeviceIdRef = useRef<string>();

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        previousDeviceIdRef.current =
          videoTrack.mediaStreamTrack.getSettings().deviceId;
        const localTrackPublication =
          localParticipant?.unpublishTrack(videoTrack);

        // Faking event because twilio-video itself does not emit
        localParticipant?.emit(
          RoomTrackEvent.unpublished,
          localTrackPublication,
        );

        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack({ deviceId: { exact: previousDeviceIdRef.current } })
          .then((track: LocalVideoTrack) =>
            localParticipant?.publishTrack(track, { priority: "standard" }),
          )
          .catch(onError)
          .finally(() => setIspublishing(false));
      }
    }
  }, [
    videoTrack,
    localParticipant,
    getLocalVideoTrack,
    isPublishing,
    onError,
    removeLocalVideoTrack,
  ]);

  return [Boolean(videoTrack), toggleVideoEnabled];
};
