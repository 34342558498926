import { ConnectOptions } from "twilio-video";

const getEnv = (key: string, defaultValue: string = ""): string => {
  let value = defaultValue;

  if (process.env[key]) {
    value = process.env[key] as string;
  }

  return value;
};

export const platformUrl = getEnv(
  "REACT_APP_PLATFORM_URL",
  "https://production.relieftelemed.com/relief_api/v1/",
);

export const appId = getEnv(
  "REACT_APP_ID",
  "75ac635f-a28e-46d8-9adb-902610185e5b",
);

export const flagProviderId = getEnv(
  "REACT_APP_FLAG_PROVIDER",
  "5eb08d33f01d3a0a6fd8b942",
);

export const analyticsProjectToken = getEnv(
  "REACT_APP_ANALYTICS_PROVIDER",
  "d62a73d75a3a9f1ee171a85dfb6ae1cc",
);

const enableDebug = getEnv("REACT_APP_DEBUG", "false");

export const minLogLevel = enableDebug === "true" ? "debug" : "warn";

export const emptyValue = "--";

export const validationDelay = 600;

export const supportEmail = "support@relieftelemed.com";

export const pageTitle = "Relief Telemed";

export const videoSettings: Partial<ConnectOptions> = {
  maxAudioBitrate: 16000,
  preferredVideoCodecs: [{ codec: "VP8" as const, simulcast: true }],
  dominantSpeaker: true,
  networkQuality: {
    local: 2,
    remote: 1,
  },
};

export const createPageTitle = (subtitle?: string): string => {
  let text = pageTitle;

  if (subtitle) {
    text += ` - ${subtitle}`;
  }

  return text;
};
