import { StepLabelClasskey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

import { accent, success } from "../palette";
import { typography } from "../typography";

export const MuiStepLabel: Partial<StyleRules<StepLabelClasskey>> = {
  label: {
    ...typography.caption,
    lineHeight: 1.2,
    "&$completed": {
      color: success,
    },
    "&$active": {
      color: accent,
    },
  },
  completed: {
    color: success,
  },
  active: {
    color: accent,
  },
};
