import { Backdrop, CircularProgress } from "@material-ui/core";
import { isUserProvider } from "@relieftelemed/platform";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LDUser } from "launchdarkly-js-client-sdk";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, useEffect, useState } from "react";

import { flagProviderId } from "../config";
import { useAppService } from "../state";

let LDProvider: FunctionComponent = ({ children }) => <div>{children}</div>;

export const FlagProvider: FunctionComponent = ({ children }) => {
  const [{ user }] = useAppService();

  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const ldUser: LDUser = {
      key: user!.id,
      email: user!.email,
    };

    if (isUserProvider(user!)) {
      ldUser.firstName = user.firstName ?? "";
      ldUser.lastName = user.lastName ?? "";
      ldUser.custom = {
        clinics: user.clinics.map((clinic) => clinic.name),
      };
    }

    asyncWithLDProvider({
      clientSideID: flagProviderId,
      user: ldUser,
      options: {
        useReport: true,
      },
    }).then((ActivatedProvider) => {
      LDProvider = ActivatedProvider;
      setReady(true);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isReady) {
    return (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return <LDProvider>{children}</LDProvider>;
};
