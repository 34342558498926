import { ChangeEventHandler, useState } from "react";
import { useDebounce } from "react-use";

import { validationDelay } from "../config";

import { ValidatedState, ValidatorFunction } from "./types";

export const useValidatedToggle = (
  initialValue = false,
  validator: ValidatorFunction<boolean>,
  initialValidation = true,
): ValidatedState<boolean> => {
  const [value, changeValue] = useState(initialValue);
  const [isValid, changeValidation] = useState(initialValidation);

  useDebounce(() => changeValidation(validator(value)), validationDelay, [
    value,
  ]);

  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = () =>
    changeValue(!value);

  return [value, isValid, handleChangeValue];
};
