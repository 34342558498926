import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  ButtonBaseProps,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import {
  CheckCircle,
  Close,
  ExitToApp,
  KeyboardArrowDown,
  PeopleAltTwoTone,
} from "@material-ui/icons";
import { Clinic, isUserProvider, ProviderUser } from "@relieftelemed/platform";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FunctionComponent, MouseEvent, useState } from "react";
import { Link } from "react-router-dom";

import { ClinicListItem } from "../../components";
import { PATH_LOGOUT, PATH_PROFILE } from "../../constants";
import { useAnalytics } from "../../core";
import { useAppService } from "../../state";
import {
  fadedNightBlue,
  grey,
  narrowDrawerWidth,
  inputPlaceholder,
  navSelected,
  reliefTeal,
} from "../../styles";

interface UserTriggerProps {
  open?: boolean;
}

const useUserTriggerStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  closed: {
    "&:hover": {
      backgroundColor: navSelected,
    },
  },
  avatar: {
    backgroundColor: reliefTeal,
    fontSize: "1.063rem",
    fontWeight: "bold",
    lineHeight: 1.18,
    letterSpacing: 1.28,
  },
  closeButton: {
    color: theme.palette.text.primary,
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  downArrow: {
    color: theme.palette.common.white,
  },
  subtitle: {
    color: inputPlaceholder,
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  titleOpen: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

const UserTrigger: FunctionComponent<UserTriggerProps & ButtonBaseProps> = ({
  open,
  ...props
}) => {
  const classes = useUserTriggerStyles();
  const [{ user }] = useAppService();

  let userName = "";
  let clinic = "";
  let clinicInitials = "";
  if (user && isUserProvider(user)) {
    userName = `${user.firstName} ${user.lastName}`;
    clinic = user.currentClinic?.name ?? userName;
    clinicInitials = clinic
      .split(" ")
      .map((segment: string) => {
        return segment.substr(segment.indexOf("#") + 1, 1);
      })
      .join("");
  }

  return (
    <ButtonBase
      className={open ? undefined : classes.closed}
      {...props}
      classes={{ root: classes.root }}
    >
      <ListItem component="div">
        <ListItemAvatar>
          <Avatar classes={{ root: classes.avatar }}>{clinicInitials}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            classes: {
              root: open ? classes.titleOpen : classes.title,
            },
          }}
          primary={clinic}
          secondaryTypographyProps={{
            classes: {
              colorTextSecondary: classes.subtitle,
            },
          }}
          secondary={userName}
        />
        {open ? (
          <Close aria-label="close" className={classes.closeButton} />
        ) : (
          <KeyboardArrowDown className={classes.downArrow} />
        )}
      </ListItem>
    </ButtonBase>
  );
};

const useUserMenuStyles = makeStyles((theme) => ({
  root: {
    width: narrowDrawerWidth,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  header: {
    ...theme.typography.body2,
    color: grey,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    textTransform: "uppercase",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: 1.14,
    letterSpacing: 1.05,
    width: 36,
    height: 36,
  },
  unselectedClinic: {
    backgroundColor: fadedNightBlue,
  },
  selectedClinic: {
    backgroundColor: reliefTeal,
  },
  successCheck: {
    color: theme.palette.success.main,
    height: 20,
    width: 20,
  },
  icon: {
    paddingLeft: 5,
  },
  footer: {
    color: grey,
    margin: theme.spacing(0.5),
    textTransform: "none",
  },
}));

export const UserMenu: FunctionComponent = () => {
  const classes = useUserMenuStyles();
  const { track } = useAnalytics();
  const [{ user }, , { changeClinic }] = useAppService();
  const flags = useFlags();
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const isMenuOpen = Boolean(anchorEl);
  const canProfile = flags.profile;

  let userName = "";
  let userInitials = "";
  let showClinics = false;
  if (user && isUserProvider(user)) {
    userName = `${user.firstName} ${user.lastName}`;
    userInitials = `${(user as ProviderUser).firstName.substr(0, 1)}${(
      user as ProviderUser
    ).lastName.substr(0, 1)}`;

    showClinics = user.clinics?.length > 1;
  }

  const toggleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    const target = isMenuOpen ? undefined : event.currentTarget;
    setAnchorEl(target);
  };

  const handleChangeClinic = (clinic: Clinic) => () => {
    changeClinic(clinic);
  };

  const handleClick = (item: string) => () => {
    track("nav.click", { to: item });
  };

  let clinicSelector = null;

  if (showClinics) {
    clinicSelector = (
      <>
        <Divider />
        <List
          aria-labelledby="clinics-header"
          dense
          subheader={
            <ListSubheader
              classes={{ root: classes.header }}
              component="div"
              id="clinics-header"
            >
              Your Clinics
            </ListSubheader>
          }
        >
          {(user as ProviderUser).clinics.map((clinic) => {
            const isSelected = clinic.id === user?.currentClinic?.id;
            let icon = null;
            if (isSelected) {
              icon = <CheckCircle classes={{ root: classes.successCheck }} />;
            }
            return (
              <ClinicListItem
                key={clinic.id}
                onSelect={handleChangeClinic(clinic)}
                isSelected={isSelected}
                icon={icon}
                clinic={clinic}
              />
            );
          })}
        </List>
      </>
    );
  }

  let profileItems = null;
  if (canProfile) {
    profileItems = (
      <>
        <ListItem button onClick={handleClick("Profile Name")}>
          <ListItemAvatar>
            <Avatar classes={{ root: classes.avatar }}>{userInitials}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              color: "textPrimary",
              variant: "body2",
            }}
            primary={userName}
          />
        </ListItem>
        <ListItem button component={Link} to={PATH_PROFILE}>
          <ListItemIcon classes={{ root: classes.icon }}>
            <PeopleAltTwoTone />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              color: "textPrimary",
              variant: "body2",
            }}
            primary="Manage Profile"
          />
        </ListItem>
      </>
    );
  }

  return (
    <>
      <UserTrigger
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={toggleMenu}
      />
      <Popover
        aria-labelledby="user-menu-header"
        id="user-menu"
        anchorEl={anchorEl}
        classes={{ paper: classes.root }}
        keepMounted
        marginThreshold={0}
        open={isMenuOpen}
        onClose={toggleMenu}
        TransitionComponent={Collapse}
      >
        <Typography id="user-menu-header" variant="srOnly">
          User Menu
        </Typography>
        <UserTrigger open aria-controls="user-menu" onClick={toggleMenu} />
        {clinicSelector}
        <Divider />
        <List
          aria-labelledby="account-header"
          component="nav"
          dense
          subheader={
            <ListSubheader
              classes={{ root: classes.header }}
              component="div"
              id="account-header"
            >
              Your Account
            </ListSubheader>
          }
        >
          {profileItems}
          <ListItem
            button
            onClick={handleClick(PATH_LOGOUT)}
            component={Link}
            to={PATH_LOGOUT}
          >
            <ListItemIcon classes={{ root: classes.icon }}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                color: "textPrimary",
                variant: "body2",
              }}
              primary="Sign Out"
            />
          </ListItem>
        </List>
        <Divider />
        <Box display="flex" justifyContent="center" p={1}>
          <Button
            classes={{ textSizeSmall: classes.footer }}
            size="small"
            variant="text"
            onClick={handleClick("Terms of Use")}
          >
            Terms of Use
          </Button>
          <Button
            classes={{ textSizeSmall: classes.footer }}
            size="small"
            variant="text"
            onClick={handleClick("Privacy Policy")}
          >
            Privacy Policy
          </Button>
        </Box>
      </Popover>
    </>
  );
};
