/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Checkbox,
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Input,
  Chip,
  ListItemText,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { EditableCard } from "../../components";
import { billingCodes, VideoVisit } from "../../core";

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

export const BillingCodes: FunctionComponent<VisitDetail> = ({
  onChange = () => null,
  visit,
}) => {
  const classes = useStyles();

  // states
  const [selectedCodes, setSelectedCodes] = React.useState<string[]>(
    visit.billingCodes ?? [],
  );

  // functions

  const handleLocalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCodes(event.target.value as string[]);
  };

  const handleSave = (newValue: string[]) => {
    onChange({ billingCodes: newValue });
  };

  // data

  const multiSelect = (
    <Grid item xs={12} lg={12}>
      <FormControl fullWidth>
        <InputLabel>Code</InputLabel>
        <Select
          multiple
          value={selectedCodes}
          onChange={handleLocalChange}
          input={<Input />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
        >
          {billingCodes.map((code) => (
            <MenuItem key={code} value={code}>
              <Checkbox checked={selectedCodes.indexOf(code) > -1} />
              <ListItemText primary={code} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );

  const selectedCodesContent = (
    <Grid container spacing={4}>
      {selectedCodes.map((code) => (
        <Grid item key={`code: ${code}`}>
          <Typography variant="body2">{code}</Typography>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <EditableCard
      content={selectedCodesContent}
      editContent={
        <Grid container spacing={2}>
          {multiSelect}
        </Grid>
      }
      label="Billing Codes"
      onSave={() => handleSave([...selectedCodes])}
      onCancel={() => {
        setSelectedCodes(visit?.billingCodes ?? []);
      }}
    />
  );
};
