import {
  createProvider,
  credentials as allCredentials,
  formatDate,
  formatPhoneNumber,
  isApiError,
  marshallProvider,
  Provider,
} from "../../../core";

import { FAIL, SUCCEED } from "./constants";
import { OnboardingContext, SucceedEvent, FailEvent } from "./types";

export const createOnboardPromise = async (
  context: OnboardingContext,
): Promise<Provider> => {
  const {
    credentials = [],
    license,
    fax,
    mobile,
    ...details
  } = context.onboarding!;
  try {
    const response = await createProvider({
      ...details,
      user: context.user?.id || "",
      dob: formatDate(details.dob),
      credentials: allCredentials.filter((credential) =>
        credentials.includes(credential.id),
      ),
      fax: formatPhoneNumber(fax),
      stateLicenseExpiration: license?.expiration
        ? formatDate(license.expiration)
        : "",
      stateLicenseNumber: license?.number,
      stateLicensed: license?.state,
    });
    return marshallProvider(response);
  } catch (error) {
    const messages = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};
