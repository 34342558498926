import { assign } from "xstate";

import { NurseVisitsContext, NurseVisitsEvents } from "./types";

export const saveError = assign<NurseVisitsContext, NurseVisitsEvents>({
  errors: (_context, event) =>
    event.type !== "error.execution" ? _context.errors : event.data,
});

export const saveVisits = assign<NurseVisitsContext, NurseVisitsEvents>({
  visits: (context, event) =>
    event.type !== "done.invoke.fetch-all" ? context.visits : event.data,
});
