import { ThemeOptions } from "@material-ui/core";

import { props } from "./default-props";
import { palette } from "./palette";
import { overrides } from "./style-overrides";
import { typography } from "./typography";

export const themeConfig: ThemeOptions = {
  overrides,
  palette,
  props,
  typography,
};
