import { ConditionPredicate } from "xstate";

import { VideoVisitStatusMap } from "../../../core";

import { VideoVisitDetailsContext, VideoVisitDetailsEvents } from "./types";

export const isVideoVisitAccepted: ConditionPredicate<
  VideoVisitDetailsContext,
  VideoVisitDetailsEvents
> = (context) => {
  return context.visit.status === VideoVisitStatusMap.accepted;
};
