import {
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TextField,
  Box,
  Hidden,
} from "@material-ui/core";
import { Add, Delete, Search } from "@material-ui/icons";
import React, {
  ChangeEventHandler,
  FunctionComponent,
  useMemo,
  useState,
} from "react";

import { Button, FormModal } from "../../components";
import { NurseVisit, NurseVisitService, useAnalytics } from "../../core";
import { renderCurrency } from "../../helpers";
import {
  accent,
  AmbulanceSideView,
  borderColor,
  textLightBg,
} from "../../styles";

import { ServicesList } from "./services-list";

interface SelectServicesProps {
  nurseVisit: NurseVisit;
  onClose?: () => void;
  onSubmit: (visit: NurseVisit) => void;
  open?: boolean;
  services: NurseVisitService[];
}

const useVisitModalStyles = makeStyles((theme) => ({
  dialog: {
    [theme.breakpoints.up("md")]: {
      height: "65vh",
      paddingBottom: 0,
    },
  },
  container: {
    height: "100%",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  dialogeContentContainer: {
    paddingTop: 0,
  },
  alignTop: {
    flexGrow: 1,
    border: `1px solid ${borderColor}`,
    borderRadius: "4px",
    overflow: "auto",
    padding: theme.spacing(1),
    minHeight: "30%",
  },
  rightColumn: {
    marginLeft: -1,
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },

  leftColumn: {
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      height: "100%",
    },
  },
  primaryAction: {
    textAlign: "right",
  },
  rightFooter: {
    paddingTop: theme.spacing(1),
  },
  textBox: {
    backgroundColor: textLightBg,
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  totalsBox: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
  subTotal: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
  },
  grandTotal: {
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
  },
  grandTotalAmount: {
    ...theme.typography.button,
    color: accent,
  },
}));

export const SelectNurseVisitServicesModal: FunctionComponent<SelectServicesProps> =
  ({
    nurseVisit,
    onClose = () => null,
    onSubmit,
    open = false,
    services = [],
  }) => {
    const classes = useVisitModalStyles();
    const { incrementNurseVisits, track } = useAnalytics();

    const [selectedServiceIds, changeSelectedServices] = useState<string[]>([]);
    const [searchQuery, changeQuery] = useState("");
    const [providerNotes, changeNotes] = useState("");

    const selectedServices = useMemo(
      () =>
        services.filter((service) => selectedServiceIds.includes(service.id)),
      [services, selectedServiceIds],
    );

    const availableServices = useMemo(
      () =>
        services.filter(
          (service) =>
            !selectedServiceIds.includes(service.id) &&
            service.name.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
      [services, selectedServiceIds, searchQuery],
    );

    const servicesCost = useMemo(
      () =>
        selectedServices.reduce(
          (aggregate, service) => aggregate + service.cost,
          0.0,
        ),
      [selectedServices],
    );

    const grandTotal = useMemo(() => {
      return servicesCost + nurseVisit.dispatchFee + nurseVisit.clinicSurcharge;
    }, [nurseVisit, servicesCost]);

    const handleSubmit = () => {
      onSubmit({
        ...nurseVisit,
        providerNotes,
        providerRequested: true,
        services: selectedServices,
      });
      track("nurseVisit.addServices", { visit: nurseVisit.id });
      incrementNurseVisits();
      onClose();
    };

    const handleChangeNotes: ChangeEventHandler<HTMLTextAreaElement> = (
      event,
    ) => {
      changeNotes(event.target.value);
    };

    const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
      changeQuery(event.target.value);
    };

    const handleAdd = (serviceId: string) => {
      changeSelectedServices([...selectedServiceIds, serviceId]);
    };

    const handleRemove = (serviceId: string) => {
      changeSelectedServices(
        selectedServiceIds.filter((service) => service !== serviceId),
      );
    };

    return (
      <FormModal
        icon={AmbulanceSideView}
        classes={{ paper: classes.dialog }}
        dialogContentClasses={{ dividers: classes.dialogeContentContainer }}
        fullWidth
        open={open}
        showDividers
        maxWidth="md"
        title="Nurse Visit"
        titleProps={{ variant: "h1" }}
      >
        <Grid container spacing={6} className={classes.container}>
          <Grid item xs={12} md={6} className={classes.leftColumn}>
            <Grid container justify="space-around" alignItems="stretch">
              <Grid item xs={12}>
                <TextField
                  id="services-filter"
                  value={searchQuery}
                  onChange={handleChangeInput}
                  placeholder="Search Services"
                  InputProps={{
                    startAdornment: <Search />,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ServicesList
                  actionIcon={<Add color="primary" />}
                  onAction={handleAdd}
                  services={availableServices}
                />
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Divider orientation="vertical" />
          </Hidden>
          <Grid item xs={12} md={6} className={classes.rightColumn}>
            <Box
              display="flex"
              flexDirection="column"
              style={{ height: "100%" }}
            >
              <Box flexGrow={1} className={classes.alignTop}>
                <ServicesList
                  actionIcon={<Delete color="error" />}
                  onAction={handleRemove}
                  services={selectedServices}
                />
              </Box>
              <Box>
                <Grid
                  container
                  alignItems="flex-end"
                  className={classes.rightFooter}
                  spacing={2}
                >
                  <Grid item xs={12} md={12}>
                    <TextField
                      multiline
                      id="provider-notes"
                      placeholder="Notes for the dispatched nurse."
                      rows={5}
                      value={providerNotes}
                      onChange={handleChangeNotes}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TableContainer classes={{ root: classes.totalsBox }}>
                      <Table
                        size="small"
                        padding="none"
                        aria-label="cost summary table"
                      >
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className={classes.subTotal}
                              component="th"
                              scope="row"
                            >
                              Service Fee
                            </TableCell>
                            <TableCell
                              className={classes.subTotal}
                              align="center"
                            >
                              --
                            </TableCell>
                            <TableCell
                              className={classes.subTotal}
                              align="right"
                            >
                              {renderCurrency(servicesCost)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className={classes.subTotal}
                              component="th"
                              scope="row"
                            >
                              Clinic Fee
                            </TableCell>
                            <TableCell
                              className={classes.subTotal}
                              align="center"
                            >
                              --
                            </TableCell>
                            <TableCell
                              className={classes.subTotal}
                              align="right"
                            >
                              {renderCurrency(nurseVisit.clinicSurcharge)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className={classes.subTotal}
                              component="th"
                              scope="row"
                            >
                              Dispatch Fee
                            </TableCell>
                            <TableCell
                              className={classes.subTotal}
                              align="center"
                            >
                              --
                            </TableCell>
                            <TableCell
                              className={classes.subTotal}
                              align="right"
                            >
                              {renderCurrency(nurseVisit.dispatchFee)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className={classes.grandTotal}
                              component="th"
                              scope="row"
                            >
                              Grand Total
                            </TableCell>
                            <TableCell
                              className={classes.grandTotal}
                              align="center"
                            >
                              --
                            </TableCell>
                            <TableCell
                              className={classes.grandTotalAmount}
                              align="right"
                            >
                              {renderCurrency(grandTotal)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    classes={{ root: classes.primaryAction }}
                  >
                    <Button onClick={handleSubmit}>Submit</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </FormModal>
    );
  };
