import { makeStyles, Typography, TypographyProps } from "@material-ui/core";
import React, { FunctionComponent, ReactNode } from "react";

import { inputPlaceholder } from "../styles";

export interface DefinedPropertyProps {
  title: string;
  children: ReactNode;
}

const useDefinedPropertyStyles = makeStyles({
  title: {
    color: inputPlaceholder,
    lineHeight: 1.75,
    textTransform: "uppercase",
  },
});

export const DefinedProperty: FunctionComponent<
  DefinedPropertyProps & TypographyProps<"p">
> = ({ children, title, ...props }) => {
  const classes = useDefinedPropertyStyles();

  const value =
    typeof children === "string" ? (
      <Typography component="p" variant="h4" {...props}>
        {children}
      </Typography>
    ) : (
      children
    );

  return (
    <>
      <Typography
        classes={{ root: classes.title }}
        variant="body2"
        component="div"
      >
        {title}
      </Typography>
      {value}
    </>
  );
};
