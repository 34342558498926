import { DialogContentTextClassKey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

export const MuiDialogContentText: Partial<
  StyleRules<DialogContentTextClassKey>
> = {
  root: {
    marginBottom: 16,
    marginTop: 16,
  },
};
