import { History } from "@material-ui/icons";
import React, { FunctionComponent, useState } from "react";
import { Redirect } from "react-router";

import { PATH_VIDEO_VISITS } from "../../constants";

import { ControlButton } from "./control-button";

export const ControlRequeue: FunctionComponent = () => {
  const [requeued, setRequeue] = useState(false);

  if (requeued) {
    return <Redirect to={PATH_VIDEO_VISITS} />;
  }
  return (
    <ControlButton
      onClick={() => setRequeue(true)}
      tooltip="Return visit back to the queue"
    >
      <History color="secondary" />
    </ControlButton>
  );
};
