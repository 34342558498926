import { assign } from "xstate";

import { VERIFY } from "./constants";
import { VerificationContext, VerificationEvents } from "./types";

export const clearContext = assign<VerificationContext, VerificationEvents>({
  verification: undefined,
});

export const saveVerification = assign<VerificationContext, VerificationEvents>(
  {
    verification: (_context, event) =>
      event.type !== VERIFY ? "" : event.code,
  },
);
