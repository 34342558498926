import { getPatients, marshallPatient, Patient } from "../../../core";

import { SEARCH_DONE } from "./constants";

export const createSearchPromise = async (): Promise<Patient[]> => {
  try {
    const response = await getPatients();
    return response.map(marshallPatient);
  } catch (_error) {
    return [];
  }
};
