import {
  getProviderPromise,
  isApiError,
  logger,
  logoutPromise,
  marshallProvider,
  ProviderUser,
  getClinic,
  marshallClinic,
  ApiClinic,
  Clinic,
} from "../core";

import {
  END_SESSION,
  FINISH_LOGOUT,
  SET_USER,
  GET_FULL_CLINIC_FAIL,
  GET_FULL_CLINIC_SUCCEED,
} from "./constants";
import {
  ApplicationContext,
  ApplicationEvents,
  SuccessUserProviderResponse,
  FinishLogoutEvent,
  FailEvent,
  SucceedEvent,
  FullClinicData,
} from "./types";

export const createLogoutPromise = async (): Promise<FinishLogoutEvent> => {
  logoutPromise().catch((err) =>
    logger.error("There was a problem logging out", err),
  );
  return {
    type: FINISH_LOGOUT,
  } as FinishLogoutEvent;
};

export const createProviderPromise = async (
  context: ApplicationContext,
): Promise<SuccessUserProviderResponse> => {
  const response = await getProviderPromise(
    (context.user as ProviderUser).providerId,
  );
  const provider = marshallProvider(response);
  const currentClinic =
    provider.clinics.length > 0 ? provider.clinics[0] : undefined;

  return {
    user: {
      ...(context.user as ProviderUser),
      ...provider,
      currentClinic,
    },
  };
};

export const createClinicPromise = async (
  context: ApplicationContext,
  event: ApplicationEvents,
): Promise<Clinic | undefined> => {
  try {
    const defaultClinic =
      Array.isArray((context.user as ProviderUser).clinics) &&
      (context.user as ProviderUser).clinics.length > 0
        ? (context.user as ProviderUser).clinics[0].id
        : undefined;

    const currentClinic = context.user?.currentClinic?.id ?? defaultClinic;

    if (!currentClinic) {
      return undefined;
    }

    const response: ApiClinic = await getClinic(currentClinic);
    return marshallClinic(response);
  } catch (errorResponse) {
    const { messages } = await errorResponse.response.json();
    return Promise.reject(Object.values(messages).flat().join(", "));
  }
};
