import React, { FunctionComponent, useState, useEffect } from "react";

import { RoomStateType, useRoomState } from "../../hooks";

import { ControlAudio } from "./control-audio";
import { ControlConnect } from "./control-connect";
import { ControlDisconnect } from "./control-disconnect";
import { ControlQuality } from "./control-quality";
import { ControlRequeue } from "./control-requeue";
import { ControlVideo } from "./control-video";

export const Controls: FunctionComponent = () => {
  const roomState = useRoomState();
  const [isRetry, setRetry] = useState(false);

  useEffect(() => {
    if (roomState === RoomStateType.connected) {
      setRetry(true);
    }
  }, [roomState]);

  let connectButton = <ControlConnect isRetry={isRetry} />;
  if (roomState === RoomStateType.connected) {
    connectButton = <ControlDisconnect />;
  }

  let requeue = null;
  if (isRetry && roomState === RoomStateType.disconnected) {
    requeue = <ControlRequeue />;
  }

  return (
    <>
      <ControlAudio />
      {requeue}
      {connectButton}
      <ControlVideo />
      <ControlQuality />
    </>
  );
};
