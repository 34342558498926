import { ChangeEventHandler, useState } from "react";
import { useDebounce } from "react-use";

import { validationDelay } from "../config";

type ValidatorFunction<T = any> = (value: T) => boolean;

type ConfirmedValidatorFunction<T = string> = (
  value: T,
  confirmation: T,
) => boolean;

type ValidatedState<T = any> = [
  T,
  boolean,
  ChangeEventHandler<HTMLInputElement>,
];

type ConfirmedValidatedState<T = string> = [
  T,
  T,
  boolean,
  boolean,
  ChangeEventHandler<HTMLInputElement>,
  ChangeEventHandler<HTMLInputElement>,
];

export const useValidatedState = (
  initialValue = "",
  validator: ValidatorFunction<string>,
  initialValidation = true,
): ValidatedState<string> => {
  const [value, changeValue] = useState(initialValue);
  const [isValid, changeValidation] = useState(initialValidation);

  useDebounce(() => changeValidation(validator(value)), validationDelay, [
    value,
  ]);

  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = (event) =>
    changeValue(event.target.value);

  return [value, isValid, handleChangeValue];
};

export const useValidatedConfirmedState = (
  initialValue: string,
  validator: ValidatorFunction<string>,
  confirmationValidator: ConfirmedValidatorFunction<string>,
  initialValidation = true,
): ConfirmedValidatedState<string> => {
  const [value, changeValue] = useState(initialValue);
  const [confirmation, changeConfirmation] = useState(initialValue);
  const [isValid, changeValidation] = useState(initialValidation);
  const [isConfirmationValid, changeConfirmationValidation] = useState(true);

  useDebounce(() => changeValidation(validator(value)), validationDelay, [
    value,
  ]);

  useDebounce(
    () =>
      changeConfirmationValidation(confirmationValidator(value, confirmation)),
    validationDelay,
    [value, confirmation],
  );

  const handleChangeValue: ChangeEventHandler<HTMLInputElement> = (event) =>
    changeValue(event.target.value);

  const handleChangeConfirmation: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => changeConfirmation(event.target.value);

  return [
    value,
    confirmation,
    isValid,
    isConfirmationValid,
    handleChangeValue,
    handleChangeConfirmation,
  ];
};
