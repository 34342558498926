export const CREATING = "creating";
export const FETCHING_SERVICES = "fetchingServices";
export const READY = "ready";
export const SAVED = "saved";
export const SAVING = "saving";
export const UNSAVED = "unsaved";

export const CREATE = "NV.CREATE";
export const FAIL = "NV.FAIL";
export const FETCH_SERVICES = "NV.FETCH_SERVICES";
export const PARSE_SERVICES = "NV.PARSE_SERVICES";
export const SUCCEED = "NV.SUCCEED";
export const UPDATE = "NV.UPDATE";
