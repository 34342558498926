import React, { FunctionComponent, useMemo } from "react";

import { NotificationTrigger, Success } from "../components";

import { UpdateState, useUpdateState } from "./context";

export const WorkerCacheUpdated: FunctionComponent = () => {
  const { state, handleReset } = useUpdateState();

  const isCacheUpdated = useMemo(() => state === UpdateState.cached, [state]);

  return (
    <NotificationTrigger
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isCacheUpdated}
      onClose={handleReset}
    >
      <Success
        message="Application has been updated."
        onDismiss={handleReset}
      />
    </NotificationTrigger>
  );
};
