import { Snackbar, SnackbarProps } from "@material-ui/core";
import React, { FunctionComponent } from "react";

export const NotificationTrigger: FunctionComponent<Partial<SnackbarProps>> = ({
  anchorOrigin = { vertical: "top", horizontal: "center" },
  autoHideDuration = 6000,
  children,
  open,
  onClose = () => null,
}) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      {children}
    </Snackbar>
  );
};
