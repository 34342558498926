import { EventObject, Interpreter } from "xstate";

import { logger } from "../core";

const currentServices = new Set();

export const logService = <TContext, TStateSchema, TEvent extends EventObject>(
  key: string,
  service?: Interpreter<TContext, TStateSchema, TEvent>,
) => {
  if (!service) {
    if (currentServices.has(key)) {
      currentServices.delete(key);
    }
    return;
  }

  if (currentServices.has(key)) {
    return;
  }

  logger.debug("Adding logging capability to", service.id);

  service.onTransition((state) => {
    logger.info(service.id, "transitioned to", state.value, state.context);
  });

  service.onEvent((event) => {
    logger.debug(service.id, "received event", event);
  });

  currentServices.add(key);
};
