import { Box } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Redirect } from "react-router";

import {
  Alert,
  NotificationTrigger,
  PageTitle,
  ProfileData,
  ProfileForm,
} from "../../components";
import { ONBOARDING_MACHINE, PATH_HOME } from "../../constants";
import { useAnalytics } from "../../core";
import { OnboardingLayout } from "../../layouts";
import { useAppService } from "../../state";
import { VerificationModal } from "../verification";

import { OnboardingService, useOnboardService } from "./state";

export const OnboardingPage: FunctionComponent = () => {
  const { track } = useAnalytics();
  const [
    { needsOnboarding, needsVerifying, user },
    { [ONBOARDING_MACHINE]: onboarding },
  ] = useAppService();
  const [{ didFail, errors }, { onboard, reset }] = useOnboardService(
    onboarding as OnboardingService,
  );

  const handleSubmit = (data: ProfileData) => {
    track("user.onboard", { email: user?.email ?? "" });
    onboard({
      firstName: data.firstName,
      lastName: data.lastName,
      fax: data.fax,
      dob: data.dob,
      credentials: data.credentials,
      license: {
        expiration: data.license?.expiration!,
        number: data.license?.number,
        state: data.license?.state,
      },
      npiNumber: data.npiNumber,
      signatureElectronic: data.signatureElectronic,
    });
  };

  const handleDismissNotice = () => {
    reset();
  };

  if (!needsOnboarding && !needsVerifying) {
    return <Redirect to={PATH_HOME} />;
  }

  return (
    <OnboardingLayout step={needsVerifying ? 1 : 2}>
      <PageTitle title="Onboarding" />
      <Box maxWidth={550}>
        <ProfileForm onSubmit={handleSubmit} actionLabel="Next" />
      </Box>
      <VerificationModal />
      <NotificationTrigger open={didFail} onClose={handleDismissNotice}>
        <Alert
          message={errors[0] ?? "Generic Error"}
          onDismiss={handleDismissNotice}
        />
      </NotificationTrigger>
    </OnboardingLayout>
  );
};
