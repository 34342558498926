import { useState, useEffect } from "react";
import { AudioTrack, VideoTrack } from "twilio-video";

export const useMediaTrack = (track?: AudioTrack | VideoTrack) => {
  const [mediaStreamTrack, setMediaStreamTrack] = useState(
    track?.mediaStreamTrack,
  );

  useEffect(() => {
    const updateTrack = () => setMediaStreamTrack(track?.mediaStreamTrack);

    if (!track) {
      return () => null;
    }

    updateTrack();

    track.on("started", updateTrack);

    return () => {
      track.off("started", updateTrack);
    };
  }, [track]);

  return mediaStreamTrack;
};
