import { differenceInYears } from "date-fns";
import { formatWithOptions, formatRelative } from "date-fns/fp";

import { renderNumber } from "./number";

const dateFormatter = Intl.DateTimeFormat("en-US");

export const renderDate = (date: Date) => dateFormatter.format(date);

export const renderTime = formatWithOptions({}, "h:mm b");

export const renderRelativeToToday = formatRelative(new Date());

export const renderAge = (date: Date) => {
  const years = differenceInYears(new Date(), date);

  return renderNumber(years);
};
