import { differenceInMinutes } from "date-fns";
import { useState } from "react";
import { useInterval } from "react-use";

const DELAY = 30 * 1000; // 30 seconds

export const useTimeSince = (from: Date): string => {
  const [to, updateTo] = useState(new Date());

  useInterval(() => {
    updateTo(new Date());
  }, DELAY);

  const rawDifference = differenceInMinutes(to, from);
  const hours = Math.floor(rawDifference / 60);
  const minutes = rawDifference % 60;

  return `${hours}:${minutes}`;
};
