import { VERIFICATION_MACHINE } from "../../../constants";
import { logService } from "../../../helpers";

import { FAILED, RESET, SEND_CODE, SKIP, VERIFY } from "./constants";
import { VerificationServiceHook, VerificationService } from "./types";

// Exposed API Below
export { verifyMachine } from "./machine";
export * from "./types";

export const useVerifyService = (
  service: VerificationService,
): VerificationServiceHook => {
  logService(VERIFICATION_MACHINE, service);

  const didFail = service?.state.matches(FAILED) || false;

  const reset = () => {
    service?.send(RESET);
  };

  const sendCode = () => {
    service?.send(SEND_CODE);
  };

  const skip = () => {
    service?.send(SKIP);
  };

  const verify = (code: string) => {
    service?.send({
      type: VERIFY,
      code,
    });
  };

  return [{ didFail }, { sendCode, skip, reset, verify }];
};
