import { ErrorOutline } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

export const BandwidthWarning: FunctionComponent = () => {
  return (
    <div>
      <div>
        <ErrorOutline fontSize="large" />
      </div>
      <div>Insufficient Bandwidth</div>
    </div>
  );
};
