import { interpret, Machine } from "xstate";

import { authMachine } from "../authentication/state";
import {
  AUTHENTICATION_MACHINE,
  ONBOARDING_MACHINE,
  REGISTRATION_MACHINE,
  UPDATE_EVENT_TYPE,
  VERIFICATION_MACHINE,
} from "../constants";
import { logger } from "../core";
import { onboardMachine } from "../users/onboarding/state";
import { registerMachine } from "../users/registration/state";
import { verifyMachine } from "../users/verification/state";

import {
  changeClinic,
  incrememtPing,
  removeUser,
  revokeToken,
  saveUser,
  setToken,
  updateSession,
  updateUser,
  updateFullClinic,
} from "./actions";
import {
  CHANGE_CLINIC,
  CHECK_SESSION,
  CHECKING_SESSION,
  END_SESSION,
  LOGGED_IN,
  LOGGING_OUT,
  MAYBE_HYDRATE_CLINIC,
  MAYBE_ONBOARD,
  MAYBE_VERIFY,
  ONBOARDING,
  NOT_LOGGED_IN,
  HYDRATING_CLINIC,
  READY,
  SET_USER,
  VERIFYING,
  GET_FULL_CLINIC_SUCCEED,
} from "./constants";
import { hasUserOnboarded, hasUserVerified, hasFullClinicData } from "./guards";
import { clearSavedState, restoreState, saveState } from "./persistence";
import {
  createLogoutPromise,
  createProviderPromise,
  createClinicPromise,
} from "./promises";
import {
  ApplicationContext,
  ApplicationSchema,
  ApplicationEvents,
} from "./types";

export const appMachine = Machine<
  ApplicationContext,
  ApplicationSchema,
  ApplicationEvents
>(
  {
    id: "application",
    initial: NOT_LOGGED_IN,
    context: {
      pings: 0,
    },
    on: {
      [UPDATE_EVENT_TYPE]: {
        actions: "incrememtPing",
      },
    },
    states: {
      [NOT_LOGGED_IN]: {
        invoke: [
          {
            id: AUTHENTICATION_MACHINE,
            src: authMachine,
            onDone: {
              target: LOGGED_IN,
              actions: "saveUser",
            },
          },
          {
            id: REGISTRATION_MACHINE,
            src: registerMachine,
            onDone: {
              target: LOGGED_IN,
              actions: "saveUser",
            },
          },
        ],
      },
      [LOGGED_IN]: {
        initial: MAYBE_VERIFY,
        entry: "setToken",
        on: {
          [CHANGE_CLINIC]: {
            actions: "changeClinic",
            internal: true,
            target: `.${HYDRATING_CLINIC}`,
          },
          [CHECK_SESSION]: {
            target: `.${CHECKING_SESSION}`,
            internal: true,
          },
          [END_SESSION]: LOGGING_OUT,
        },
        states: {
          [MAYBE_VERIFY]: {
            always: [
              {
                target: MAYBE_ONBOARD,
                cond: { type: "hasUserVerified" },
              },
              {
                target: VERIFYING,
              },
            ],
          },
          [VERIFYING]: {
            invoke: {
              id: VERIFICATION_MACHINE,
              src: verifyMachine,
              data: {
                user: (context: ApplicationContext) => context.user,
              },
              onDone: {
                target: MAYBE_ONBOARD,
                actions: "updateUser",
              },
            },
          },
          [MAYBE_ONBOARD]: {
            always: [
              {
                target: CHECKING_SESSION,
                cond: { type: "hasUserOnboarded" },
              },
              {
                target: ONBOARDING,
              },
            ],
          },
          [ONBOARDING]: {
            invoke: {
              id: ONBOARDING_MACHINE,
              src: onboardMachine,
              data: {
                user: (context: ApplicationContext) => context.user,
              },
              onDone: {
                target: CHECKING_SESSION,
                actions: "updateUser",
              },
            },
          },
          [CHECKING_SESSION]: {
            invoke: {
              src: createProviderPromise,
              id: "checkSession",
              onDone: {
                actions: "updateSession",
                target: MAYBE_HYDRATE_CLINIC,
              },
              onError: {
                target: `#application.${LOGGING_OUT}`,
              },
            },
          },
          [MAYBE_HYDRATE_CLINIC]: {
            always: [
              {
                target: READY,
                cond: { type: "hasFullClinicData" },
              },
              {
                target: HYDRATING_CLINIC,
              },
            ],
          },
          [HYDRATING_CLINIC]: {
            invoke: {
              src: createClinicPromise,
              id: "updateClinic",
              onDone: {
                target: READY,
                actions: "updateFullClinic",
              },
            },
          },
          [READY]: {
            type: "final",
          },
        },
      },
      [LOGGING_OUT]: {
        invoke: {
          src: createLogoutPromise,
          id: "logout",
          onDone: {
            target: NOT_LOGGED_IN,
            actions: ["removeUser", "revokeToken"],
          },
        },
      },
    },
  },
  {
    actions: {
      changeClinic,
      incrememtPing,
      removeUser,
      revokeToken,
      saveUser,
      setToken,
      updateSession,
      updateUser,
      updateFullClinic,
    },
    guards: { hasUserOnboarded, hasUserVerified, hasFullClinicData },
  },
);

const savedState = restoreState();
const resolvedState = savedState
  ? appMachine.resolveState(savedState)
  : undefined;

export const appService = interpret(appMachine).start(resolvedState);

if (resolvedState) {
  appService.send(CHECK_SESSION);
}

appService.onTransition((state) => {
  logger.info("Application transitioned to", state.value, state.context);
  if (state.matches(LOGGED_IN)) {
    saveState(state);
  }
  if (state.matches(NOT_LOGGED_IN)) {
    clearSavedState();
  }
});

appService.onEvent((event) => {
  logger.debug("Application received event", event);
});
