import { CircularProgress, TextField } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete, AutocompleteProps } from "@material-ui/lab";
import React, { FunctionComponent, useState } from "react";
import { useDebounce } from "react-use";

import { validationDelay } from "../../config";
import { Patient } from "../../core";
import { renderDate } from "../../helpers";

import { usePatientSearchService } from "./state";

interface PatientAutocompleteProps {
  autoFocus?: boolean;
  label?: string;
  onChange?: (value: Patient | null) => void;
  placeholder?: string;
  value?: Patient | null;
}

export const PatientAutocomplete: FunctionComponent<
  PatientAutocompleteProps &
    Partial<
      Omit<
        AutocompleteProps<Patient, false, false, false>,
        keyof PatientAutocompleteProps
      >
    >
> = ({
  autoFocus = false,
  label,
  onChange = () => null,
  placeholder,
  value = null,
  ...props
}) => {
  const [{ isSearching, results }, { search }] = usePatientSearchService();
  const [query, setQuery] = useState("");

  useDebounce(() => search(query), validationDelay, [query]);

  const handleInputChange = (_event: unknown, inputValue: string) => {
    setQuery(inputValue);
  };

  const handleChangeValue = (
    _event: unknown,
    selectedValue: Patient | null,
  ) => {
    if (onChange) {
      onChange(selectedValue);
    }
  };

  return (
    <Autocomplete
      id="patient-search"
      size="small"
      {...props}
      forcePopupIcon={false}
      getOptionSelected={(option, selectedValue) =>
        option.id === selectedValue.id
      }
      getOptionLabel={(option) =>
        `${option.fullName} (${
          option.dob ? renderDate(option.dob) : "Unknown DOB"
        })`
      }
      inputValue={query}
      onChange={handleChangeValue}
      onInputChange={handleInputChange}
      options={results}
      loading={isSearching}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          // placeholder="Search for patients"
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            startAdornment: <Search />,
            endAdornment: (
              <>
                {isSearching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
