import {
  Interpreter,
  State,
  DoneInvokeEvent,
  ErrorExecutionEvent,
} from "xstate";

import { VideoVisit } from "../../../core";

export interface VideoVisitsContext {
  errors: string[];
  visits: VideoVisit[];
}

export interface VideoVisitsSchema {
  states: {
    fetching: {};
    ready: {};
  };
}

// Events

// export interface FailEvent {
//   type: "VV.FAIL";
//   errors: string[];
// }

export type FetchEvent = { type: "VV.FETCH" };

// export interface SucceedEvent {
//   type: "VV.SUCCEED";
//   visits: VideoVisit[];
// }

// Aggregates

export type VideoVisitsEvents =
  | DoneInvokeEvent<any>
  | FetchEvent
  | ErrorExecutionEvent;

export const isDoneEvent = <TData = {}>(
  event: VideoVisitsEvents,
): event is DoneInvokeEvent<TData> =>
  (event as DoneInvokeEvent<TData>).data !== undefined;

export type VideoVisitsState = State<
  VideoVisitsContext,
  VideoVisitsEvents,
  VideoVisitsSchema
>;

export type VideoVisitsService = Interpreter<
  VideoVisitsContext,
  VideoVisitsSchema,
  VideoVisitsEvents
>;

// Hooks

type SendFetch = () => void;

interface VideoVisitsHookActions {
  fetch: SendFetch;
}

interface VideoVisitsHookSelectors {
  errors: string[];
  isReady: boolean;
  visits: VideoVisit[];
}

export type VideoVisitsServiceHook = [
  VideoVisitsHookSelectors,
  VideoVisitsHookActions,
];
