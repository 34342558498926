import {
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Popover,
  CircularProgress,
} from "@material-ui/core";
import { CheckCircle, Cancel as CancelIcon } from "@material-ui/icons";
import {
  ProviderUser,
  useGetCurrentClinic,
  useSetCurrentClinic,
} from "@relieftelemed/platform";
import React, { useState, useMemo, useCallback } from "react";

import { ClinicListItem } from "../components";
import { useAppService } from "../state";

const availabilityStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  successCheck: {
    color: theme.palette.success.main,
    height: 20,
    width: 20,
  },
}));
export const Availability = () => {
  const [{ user }] = useAppService();
  const classes = availabilityStyles();
  const { data: providersCurrentClinic, isFetching } = useGetCurrentClinic(
    (user as ProviderUser)?.providerId,
  );
  const { mutate: updateProvidersClinic } = useSetCurrentClinic(
    (user as ProviderUser)?.providerId,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const isNotAvailable = useMemo(
    () => providersCurrentClinic?.id == null,
    [providersCurrentClinic],
  );

  const buttonCaption = useMemo(
    () => (isNotAvailable ? "Unavailable" : providersCurrentClinic?.name),
    [isNotAvailable, providersCurrentClinic],
  );
  const doActionAndClose = useCallback(
    (actualCallback) => () => {
      actualCallback();
      handleClose();
    },
    [handleClose],
  );

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={handleClick}
        color={isNotAvailable ? "secondary" : "primary"}
      >
        {isFetching ? <CircularProgress size={18} /> : buttonCaption}
      </Button>
      <Popover
        aria-labelledby="user-menu-header"
        id="user-menu"
        anchorEl={anchorEl}
        classes={{ paper: classes.root }}
        keepMounted
        marginThreshold={0}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Collapse}
      >
        <List>
          {(user as ProviderUser).clinics.map((clinic) => {
            const isSelected = clinic.id === providersCurrentClinic?.id;
            let icon = null;
            if (isSelected) {
              icon = <CheckCircle classes={{ root: classes.successCheck }} />;
            }
            return (
              <ClinicListItem
                key={clinic.id}
                clinic={clinic}
                isSelected={isSelected}
                icon={icon}
                onSelect={doActionAndClose(() =>
                  updateProvidersClinic({ clinic: clinic.id }),
                )}
              />
            );
          })}
        </List>
        <Divider />
        <List>
          <ListItem
            button
            onClick={doActionAndClose(() =>
              updateProvidersClinic({ clinic: null }),
            )}
          >
            <ListItemAvatar>
              <CancelIcon style={{ color: "red" }} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                color: "textPrimary",
                variant: "body2",
              }}
              primary="Unavailable"
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
