import { logger } from "@relieftelemed/platform";
import React, { FunctionComponent, useEffect, useState } from "react";

import { WorkerCacheUpdated } from "./cache-updated-info";
import { UpdateState, updateContext } from "./context";
import * as serviceWorker from "./service-worker-registration";
import { ServiceWorkerUpdated } from "./updated-alert";

const { Provider } = updateContext;

export const AppUpdateNotifications: FunctionComponent = () => {
  const [state, setState] = useState(UpdateState.ready);
  const [worker, setWorker] = useState<ServiceWorkerRegistration>();

  const handleCacheUpdated = () => {
    logger.debug("PWA cache has been updated");
    setState(UpdateState.cached);
  };

  const handleUpdateAvailable = (registration: ServiceWorkerRegistration) => {
    logger.debug("PWA has an update");
    setWorker(registration);

    if (registration.waiting) {
      setState(UpdateState.update);
    }
  };

  const handleReset = () => {
    logger.debug("Resetting app updates state", state);
    setState(UpdateState.ready);
  };

  const handleReload = () => {
    logger.debug("Attempt to reload for application update.", state);

    if (worker?.waiting) {
      worker.waiting.postMessage({ type: "SKIP_WAITING" });
      worker.waiting.addEventListener("statechange", (event) => {
        if ((event.target as ServiceWorker).state === "activated") {
          handleReset();
          window.location.reload();
        }
      });
    }
  };

  useEffect(() => {
    // Learn more about service workers: https://cra.link/PWA
    serviceWorker.register({
      onSuccess: handleCacheUpdated,
      onUpdate: handleUpdateAvailable,
    });
  });

  return (
    <Provider
      value={{
        state,
        handleReset,
        handleReload,
      }}
    >
      <ServiceWorkerUpdated />
      <WorkerCacheUpdated />
    </Provider>
  );
};
