import { Interpreter, State, DoneInvokeEvent } from "xstate";

import { License, Provider, User } from "../../../core";

export interface OnboardingData {
  firstName: string;
  lastName: string;
  dob: Date;
  fax?: string;
  mobile?: string;
  email?: string;
  credentials?: string[];
  license?: License;
  npiNumber?: string;
  signatureElectronic?: string;
}

export interface OnboardingContext {
  errors: string[];
  onboarding?: OnboardingData;
  provider?: Provider;
  user: User;
  verification?: string;
}

export interface OnboardingSchema {
  states: {
    idle: {};
    onboarding: {};
    failed: {};
    onboarded: {};
  };
}

// Events

export interface FailEvent {
  type: "FAIL";
  errors: string[];
}

export type OnboardEvent = {
  type: "ONBOARD";
  details: OnboardingData;
};

export interface ResetEvent {
  type: "RESET";
}

export interface SucceedEvent {
  type: "SUCCEED";
  provider: Provider;
}

// Aggregates

export type OnboardingEvents =
  | OnboardEvent
  | ResetEvent
  | DoneInvokeEvent<Provider>;

export const isDoneEvent = <TData = Provider>(
  event: OnboardingEvents,
): event is DoneInvokeEvent<Provider> =>
  (event as DoneInvokeEvent<Provider>).data !== undefined;

export type OnboardingState = State<
  OnboardingContext,
  OnboardingEvents,
  OnboardingSchema
>;

export type OnboardingService = Interpreter<
  OnboardingContext,
  any,
  OnboardingEvents
>;

// Hooks

type SendOnboard = (details: OnboardingData) => void;

type SendReset = () => void;

interface OnboardingHookActions {
  onboard: SendOnboard;
  reset: SendReset;
}

interface OnboardingHookSelectors {
  didFail: boolean;
  errors: string[];
}

export type OnboardingServiceHook = [
  OnboardingHookSelectors,
  OnboardingHookActions,
];
