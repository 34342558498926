import { OutlinedInputClassKey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

import { inputActiveBg, inputBorderColor, inputInactiveBg } from "../palette";

export const MuiOutlinedInput: Partial<StyleRules<OutlinedInputClassKey>> = {
  root: {
    background: inputInactiveBg,
    borderRadius: 8,
    "@media (hover: none)": {
      "&:hover $notchedOutline": {
        borderColor: inputBorderColor,
      },
    },
    "&$focused": {
      background: inputActiveBg,
    },
  },
  notchedOutline: {
    borderColor: inputBorderColor,
  },
};
