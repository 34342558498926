// States
export const IDLE = "idle";
export const FAILED = "failed";
export const ONBOARDING = "onboarding";
export const ONBOARDED = "onboarded";

// Public Events
export const FAIL = "FAIL";
export const ONBOARD = "ONBOARD";
export const RESET = "RESET";
export const SUCCEED = "SUCCEED";
