import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Fragment, FunctionComponent, ReactNode } from "react";

import { NurseVisitService } from "../../core";
import { renderCurrency } from "../../helpers";
import { accent, borderColor } from "../../styles";

interface ServicesListProps {
  actionIcon: ReactNode;
  onAction: (serviceId: string) => void;
  services: NurseVisitService[];
}

const useServicesListStyles = makeStyles((theme) => ({
  list: {
    margin: `0 -${theme.spacing(1)}px`,
  },
  serviceName: {
    float: "left",
  },
  divider: {
    backgroundColor: borderColor,
  },
  serviceCost: {
    borderRight: `1px solid ${borderColor}`,
    color: accent,
    fontWeight: "bold",
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const ServicesList: FunctionComponent<ServicesListProps> = ({
  actionIcon,
  onAction,
  services,
}) => {
  const classes = useServicesListStyles();
  const handleAction = (serviceId: string) => () => {
    onAction(serviceId);
  };

  return (
    <List dense classes={{ root: classes.list }}>
      {services.map((service, index) => {
        return (
          <Fragment key={service.id}>
            {Boolean(index) && <Divider classes={{ root: classes.divider }} />}
            <ListItem>
              <ListItemText
                id={service.key}
                primary={
                  <Typography
                    variant="body2"
                    display="block"
                    align="left"
                    classes={{ body2: classes.serviceName }}
                  >
                    {service.name}
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body2"
                    display="block"
                    align="right"
                    classes={{ body2: classes.serviceCost }}
                  >
                    {renderCurrency(service.cost)}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <IconButton onClick={handleAction(service.id)}>
                  {actionIcon}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Fragment>
        );
      })}
    </List>
  );
};
