import { IconButton } from "@material-ui/core";
import { Print as PrintIcon } from "@material-ui/icons";
import { getVideoVisitPdf, logger } from "@relieftelemed/platform";
import { saveAs } from "file-saver";
import React, { FunctionComponent, useState } from "react";

import { Alert, NotificationTrigger } from "../../components";
import { VideoVisit } from "../../core";

interface VisitDetails {
  visit: VideoVisit;
}

export const PrintButton: FunctionComponent<VisitDetails> = ({ visit }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = () => {
    getVideoVisitPdf(visit.id)
      .then((blob) => {
        saveAs(blob, `${visit.patientName} - Video Visit`);
      })
      .catch((error) => {
        logger.error(error);
        setErrorMessage("The chart could not be downloaded ");
      });
  };

  const handleDismissError = () => {
    setErrorMessage("");
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <PrintIcon />
      </IconButton>
      <NotificationTrigger open={!!errorMessage} onClose={handleDismissError}>
        <Alert onDismiss={handleDismissError} message={errorMessage} />
      </NotificationTrigger>
    </>
  );
};
