import { compile } from "path-to-regexp";

type RouteFn = (params: Record<string, string>) => string;

const RouteFns: Map<string, RouteFn> = new Map();

const getRouteFn = (path: string): RouteFn => {
  if (!RouteFns.has(path)) {
    RouteFns.set(path, compile(path));
  }

  return RouteFns.get(path)!;
};

export const createRoute = (
  path: string,
  params?: Record<string, string>,
): string => {
  if (!params) {
    return path;
  }

  return getRouteFn(path)(params);
};
