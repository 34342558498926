import { useEffect, useState } from "react";
import { LocalTrackPublication, RemoteTrackPublication } from "twilio-video";

import { RoomPublicationEvent } from "./types";

export const useTrack = (
  publication?: LocalTrackPublication | RemoteTrackPublication,
) => {
  const [track, setTrack] = useState(publication?.track ?? undefined);

  useEffect(() => {
    setTrack(publication?.track ?? undefined);

    if (!publication) {
      return () => null;
    }

    const removeTrack = () => setTrack(undefined);

    publication.on(RoomPublicationEvent.subscribed, setTrack);
    publication.on(RoomPublicationEvent.unsubscribed, removeTrack);

    return () => {
      publication.off(RoomPublicationEvent.subscribed, setTrack);
      publication.off(RoomPublicationEvent.unsubscribed, removeTrack);
    };
  }, [publication]);

  return track;
};
