import { Box, styled } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { reliefTeal } from "../../styles";

const PrimaryLine = styled("div")(({ theme }) => ({
  backgroundColor: reliefTeal,
  borderRadius: theme.shape.borderRadius * 4,
  display: "inline-block",
  height: "0.1875rem",
  marginRight: "0 0.125rem",
  width: "3.625rem",
}));

const SecondaryLine = styled("div")(({ theme }) => ({
  backgroundColor: reliefTeal,
  borderRadius: theme.shape.borderRadius * 4,
  display: "inline-block",
  height: "0.1875rem",
  margin: "0 0.125rem",
  opacity: 0.3,
  width: "1.25rem",
}));

export const AccentLine: FunctionComponent = () => (
  <Box mt="1.5rem">
    <PrimaryLine />
    <SecondaryLine />
    <SecondaryLine />
  </Box>
);
