import { makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useState } from "react";
import { Redirect } from "react-router-dom";

import { PATH_ONBOARD } from "../../constants";
import { Patient } from "../../core";
import { TitleContextProvider } from "../../hooks";
import { PatientAutocomplete, PatientContextProvider } from "../../patients";
import { useAppService } from "../../state";
import { Layout, LayoutProps, LayoutWidth } from "../layout";

import { Navigation } from "./navigation";

const useAppStyles = makeStyles((theme) => ({
  search: {
    minWidth: "30vw",
    [theme.breakpoints.up("md")]: {
      minWidth: "20vw",
    },
  },
}));

export const AppLayout: FunctionComponent<LayoutProps> = ({
  children,
  ...props
}) => {
  const classes = useAppStyles();
  const [{ needsOnboarding, needsVerifying }] = useAppService();
  const [pageTitle, setPageTitle] = useState("");
  const [patientContext, changePatient] = useState<Patient | null>(null);

  if (needsOnboarding || needsVerifying) {
    return <Redirect to={PATH_ONBOARD} />;
  }

  return (
    <Layout
      {...props}
      dark
      width={LayoutWidth.narrow}
      showAppBar
      sidebar={<Navigation />}
      title={pageTitle}
      appBarRight={
        <PatientAutocomplete
          id="global-patient-search"
          classes={{ root: classes.search }}
          onChange={changePatient}
          placeholder="Patient Name"
          value={patientContext}
        />
      }
    >
      <TitleContextProvider value={setPageTitle}>
        <PatientContextProvider value={patientContext ?? undefined}>
          {children}
        </PatientContextProvider>
      </TitleContextProvider>
    </Layout>
  );
};
