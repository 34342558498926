type ValidateFunction<T = any> = (value: T, allowEmpty: boolean) => boolean;
type ValidateConfirmationFunction<T = any> = (
  value: T,
  confirmation: T,
  allowEmpty: boolean,
) => boolean;
type CurriedValidator<T = any> = (value: T) => boolean;
type CurriedConfirmationValidator<T = string> = (
  value: T,
  confirmation: T,
) => boolean;

export function curryValidator<T = any>(
  validator: ValidateFunction<T>,
  allowEmpty = true,
): CurriedValidator<T> {
  return (value: T) => validator(value, allowEmpty);
}

export function curryConfirmationValidator<T = string>(
  validator: ValidateConfirmationFunction<T>,
  allowEmpty = true,
): CurriedConfirmationValidator<T> {
  return (value: T, confirmation: T) =>
    validator(value, confirmation, allowEmpty);
}
