import { useMachine } from "@xstate/react";
import { useEffect, useMemo } from "react";

import { NurseVisit } from "../../../core";
import { logService } from "../../../helpers";

import { CREATE, READY, UNSAVED, UPDATE, SAVED } from "./constants";
import { createNurseVisitMachine } from "./machine";
import { CreateNurseVisitServiceHook, NurseVisitData } from "./types";

// Exposed API Below
export * from "./types";

export const useCreateNurseVisitMachine = (): CreateNurseVisitServiceHook => {
  const [state, send, nvService] = useMachine(createNurseVisitMachine);

  useEffect(() => {
    logService("create-nurse-visit", nvService);
  }, [nvService]);

  const didFail = useMemo(
    () =>
      (state.matches(READY) || state.matches(UNSAVED)) &&
      state.context.errors !== undefined &&
      state.context.errors.length > 0,
    [state],
  );

  const { errors, services, visit } = state.context;

  const didFinishedSuccessfull = useMemo(() => state.matches(SAVED), [state]);

  const create = (data: NurseVisitData) => {
    send({
      type: CREATE,
      data,
    });
  };

  const update = (updatedVisit: NurseVisit) => {
    send({
      type: UPDATE,
      visit: updatedVisit,
    });
  };

  return [
    {
      didFail,
      errors: errors ?? "",
      services,
      visit: visit!,
      didFinishedSuccessfull,
    },
    { create, update },
  ];
};
