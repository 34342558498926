import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import React, { FunctionComponent } from "react";

export const DateInput: FunctionComponent<KeyboardDatePickerProps> = ({
  InputProps,
  InputLabelProps,
  ...props
}) => {
  return <KeyboardDatePicker variant="inline" {...props} />;
};
