import {
  Avatar,
  Box,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  fade,
  makeStyles,
  SvgIconProps,
  Typography,
  TypographyProps,
  useMediaQuery,
  useTheme,
  DialogContentClassKey,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, {
  FormEventHandler,
  FunctionComponent,
  ReactNode,
  ComponentType,
} from "react";

import { accent } from "../styles";

interface FormModalProps {
  actions?: ReactNode;
  children: ReactNode;
  icon?: ComponentType<SvgIconProps>;
  onClose?: () => void;
  onSubmit?: FormEventHandler<HTMLFormElement & HTMLDivElement>;
  padded?: boolean;
  showDividers?: boolean;
  dialogContentClasses?: Partial<Record<DialogContentClassKey, string>>;
  title: string;
  titleProps?: TypographyProps;
}

const useModalStyles = makeStyles((theme) => ({
  padded: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  title: {
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    width: "100%",
  },
  avatar: {
    background: fade(accent, 0.1),
    height: theme.spacing(6),
    marginRight: theme.spacing(2),
    width: theme.spacing(6),
  },
  dividers: {
    paddingBottom: 0,
  },
  actions: {
    flex: "0 1 auto",
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
}));

export const FormModal: FunctionComponent<
  FormModalProps & Omit<DialogProps, "onClose">
> = ({
  actions,
  children,
  classes: passedClasses = {},
  dialogContentClasses: passedDialogContentClasses = {},
  icon: Icon,
  onClose,
  onSubmit,
  padded = false,
  showDividers = false,
  title,
  titleProps,
  ...props
}) => {
  const theme = useTheme();
  const isSvp = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useModalStyles();

  const dialogClasses = passedClasses;
  if (padded) {
    dialogClasses.paper = dialogClasses.paper
      ? `${dialogClasses.paper} ${padded}`
      : classes.padded;
  }

  const icon = Icon ? (
    <Avatar classes={{ colorDefault: classes.avatar }}>
      <Icon htmlColor={accent} />
    </Avatar>
  ) : null;

  let dialogActions = null;
  if (actions) {
    dialogActions = (
      <DialogActions classes={{ spacing: classes.actions }}>
        {actions}
      </DialogActions>
    );
  }
  let closeIcon = null;
  if (onClose) {
    closeIcon = (
      <IconButton aria-label="close" onClick={onClose}>
        <Close />
      </IconButton>
    );
  }

  const dialogContentClasses = passedDialogContentClasses;
  if (showDividers) {
    dialogContentClasses.dividers = dialogContentClasses.dividers
      ? `${dialogContentClasses.dividers} ${classes.dividers}`
      : classes.dividers;
  }
  const content = (
    <>
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        {icon}
        <Typography variant="h1" {...titleProps}>
          {title}
        </Typography>
        <Box flexGrow={1} />
        {closeIcon}
      </DialogTitle>
      <DialogContent classes={dialogContentClasses} dividers={showDividers}>
        {children}
      </DialogContent>
      {dialogActions}
    </>
  );

  return (
    <Dialog fullScreen={isSvp} maxWidth="sm" {...props} classes={dialogClasses}>
      {onSubmit ? <form onSubmit={onSubmit}>{content}</form> : content}
    </Dialog>
  );
};
