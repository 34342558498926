import DateFnsUtils from "@date-io/date-fns";
import {
  Container,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { FunctionComponent } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { AppUpdateNotifications } from "./app-updates";
import { theme } from "./styles";

export const queryClient = new QueryClient();

export const App: FunctionComponent = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <CssBaseline />
        <StylesProvider injectFirst>
          <BrowserRouter>
            <Container disableGutters maxWidth="xl">
              {children!}
            </Container>
            <AppUpdateNotifications />
          </BrowserRouter>
        </StylesProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </QueryClientProvider>
);
