import { MenuItem } from "@material-ui/core";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { forwardRef, MouseEvent, ReactNode } from "react";

import { FLAG_NURSE_VISIT } from "../../constants";

import { StartNurseVisitForm } from "./form-modal";

interface StartNurseVisitProps {
  onCancel?: () => void;
  onStart: (event: MouseEvent<HTMLLIElement>) => void;
  open: boolean;
}

export const StartNurseVisit = forwardRef<HTMLLIElement, StartNurseVisitProps>(
  (props, ref) => {
    const { onCancel = () => null, onStart, open } = props;

    const flags = useFlags();
    const canNurse = flags[FLAG_NURSE_VISIT];

    if (!canNurse) {
      return null;
    }

    let form: ReactNode = null;

    if (open) {
      form = <StartNurseVisitForm onCancel={onCancel} />;
    }

    return (
      <>
        <MenuItem ref={ref} onClick={onStart}>
          Nurse Visit
        </MenuItem>
        {form}
      </>
    );
  },
);
