import { useEffect, useState } from "react";
import {
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
} from "twilio-video";

import { RoomTrackEvent } from "./types";

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

export const usePublications = (participant: Participant) => {
  const [publications, setPublications] = useState<TrackPublication[]>([]);

  useEffect(() => {
    setPublications(
      Array.from(participant.tracks.values()) as TrackPublication[],
    );

    const addPublication = (publication: TrackPublication) =>
      setPublications((prevPublications) => [...prevPublications, publication]);
    const removePublication = (publication: TrackPublication) =>
      setPublications((prevPublications) =>
        prevPublications.filter((p) => p !== publication),
      );

    participant.on(RoomTrackEvent.published, addPublication);
    participant.on(RoomTrackEvent.unpublished, removePublication);

    return () => {
      participant.off(RoomTrackEvent.published, addPublication);
      participant.off(RoomTrackEvent.unpublished, removePublication);
    };
  }, [participant]);

  return publications;
};
