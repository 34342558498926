import { Grid, TextField } from "@material-ui/core";
import {
  validateConfirmation,
  validatePassword,
  useChangePassword,
  validateMinLength,
} from "@relieftelemed/platform";
import React, { FormEvent, FunctionComponent } from "react";

import {
  ActionButton,
  Alert,
  FormCard,
  NotificationTrigger,
  PasswordInput,
  Success,
} from "../../components";
import { curryConfirmationValidator, curryValidator } from "../../helpers";
import { useValidatedConfirmation, useValidatedState } from "../../hooks";

export const ChangePassword: FunctionComponent = () => {
  const [oldPassword, isOldPasswordValid, changeOldPassword] =
    useValidatedState("", curryValidator<string>(validateMinLength));

  const [
    password,
    confirmation,
    isPasswordValid,
    isConfirmationValid,
    changePassword,
    changeConfirmation,
  ] = useValidatedConfirmation(
    "",
    curryValidator<string>(validatePassword),
    curryConfirmationValidator(validateConfirmation),
  );

  const { mutate, isError, isSuccess, reset } = useChangePassword();

  const allowSubmit = [
    isOldPasswordValid,
    isPasswordValid,
    isConfirmationValid,
  ].every(Boolean);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    mutate({
      oldPassword,
      newPassword1: password,
      newPassword2: confirmation,
    });
  };

  const resultMessage = isError ? (
    <Alert message="Your password could not be changed" onDismiss={reset} />
  ) : (
    <Success dismissable={false} message="New password has been saved." />
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <FormCard
          elevation={0}
          title="Change Password"
          onSubmit={handleSubmit}
          actions={
            <ActionButton type="submit" disabled={!allowSubmit}>
              Save
            </ActionButton>
          }
        >
          <Grid item xs={12}>
            <PasswordInput
              id="old-password"
              label="Old Password"
              required
              value={oldPassword}
              onChange={changeOldPassword}
              error={!isOldPasswordValid}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              id="new-password"
              label="New Password"
              required
              onChange={changePassword}
              value={password}
              error={!isPasswordValid}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password-confirmation"
              label="Confirm Password"
              required
              type="password"
              value={confirmation}
              onChange={changeConfirmation}
              error={!isConfirmationValid}
              helperText={
                isConfirmationValid ? undefined : "Passwords do not match"
              }
            />
          </Grid>
        </FormCard>
      </Grid>
      <NotificationTrigger open={isError || isSuccess} onClose={reset}>
        {resultMessage}
      </NotificationTrigger>
    </Grid>
  );
};
