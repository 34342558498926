import { assign, ActionTypes, DoneInvokeEvent } from "xstate";

import { VideoVisitsContext, VideoVisitsEvents, isDoneEvent } from "./types";

export const saveError = assign<VideoVisitsContext, VideoVisitsEvents>({
  errors: (_context, event) =>
    event.type !== ActionTypes.ErrorExecution ? "" : event.data.errors,
});

export const saveVisits = assign<VideoVisitsContext, VideoVisitsEvents>({
  visits: (context, event) =>
    isDoneEvent(event) ? (event as DoneInvokeEvent<any>).data : context.visits,
});
