import { Tooltip } from "@material-ui/core";
import { Videocam, VideocamOff } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import { Participant, LocalVideoTrack, RemoteVideoTrack } from "twilio-video";

import { isVideoPublicationTrack } from "../../helpers";
import { useIsTrackThrottled, usePublications, useTrack } from "../../hooks";

import { BandwidthWarning } from "./bandwidth-warning";

interface VideoStatusProps {
  participant: Participant;
}

export const VideoStatusIndicator: FunctionComponent<VideoStatusProps> = ({
  participant,
}) => {
  const publications = usePublications(participant);

  const videoPublication = publications.find(isVideoPublicationTrack);

  const videoTrack = useTrack(videoPublication);
  const isThrottled = useIsTrackThrottled(
    videoTrack as LocalVideoTrack | RemoteVideoTrack,
  );

  if (isThrottled) {
    return <BandwidthWarning />;
  }

  const isVideoEnabled = videoPublication?.isTrackEnabled ?? false;

  const icon = isVideoEnabled ? (
    <Videocam color="primary" />
  ) : (
    <VideocamOff color="error" />
  );

  return (
    <Tooltip title={isVideoEnabled ? "Video is on" : "Video is off"}>
      {icon}
    </Tooltip>
  );
};
