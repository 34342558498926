import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";
import { Phone, Videocam } from "@material-ui/icons";
import React, {
  ChangeEventHandler,
  FunctionComponent,
  ReactNode,
  useState,
} from "react";

import {
  ConfirmationModal,
  DefinedProperty,
  VisitDetailCard,
} from "../../components";
import {
  NurseVisit,
  NurseVisitService,
  NurseVisitStatus,
  ProviderUser,
} from "../../core";
import { renderTime } from "../../helpers";
import { useAppService } from "../../state";
import { reliefTeal, white } from "../../styles";

import { useNurseVisitService } from "./state";

interface NurseVisitDetailsProps {
  visit: NurseVisit;
}

const useCardStyles = makeStyles((theme) => ({
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  afterDivider: {
    marginLeft: -1,
  },
  avatar: {
    background: reliefTeal,
    color: white,
    marginLeft: "auto",
    marginRight: "auto",
  },
  timeline: {
    marginTop: 2,
  },
}));

export const NurseVisitDetails: FunctionComponent<NurseVisitDetailsProps> = ({
  visit: simpleVisit,
}) => {
  const classes = useCardStyles();
  const [{ user }] = useAppService();
  const [{ visit }, { close }] = useNurseVisitService(simpleVisit);
  const [message, changeMessage] = useState("");
  const [sendMessage, changeActions] = useState(false);
  const [confirm, changeConfirmation] = useState(false);

  const providerName =
    typeof visit.provider === "string"
      ? ""
      : `${visit.provider.firstName} ${visit.provider.lastName}`;
  const sourceIcon = visit.videoVisit === undefined ? <Phone /> : <Videocam />;
  const isCompletedState = visit.status === NurseVisitStatus.COMPLETED;
  const isFollowupState = visit.status === NurseVisitStatus.FOLLOWUP;
  const userName = `${(user as ProviderUser).firstName} ${
    (user as ProviderUser).lastName
  }`;
  const userInitials = `${(user as ProviderUser).firstName.substr(0, 1)}${(
    user as ProviderUser
  ).lastName.substr(0, 1)}`;

  const handleClose = () => {
    close(message);
  };

  const handleChangeActions = () => {
    changeActions(!sendMessage);
  };

  const handleChangeMessage: ChangeEventHandler<HTMLInputElement> = (event) => {
    changeMessage(event?.target.value);
  };

  const toggleConfirmation = () => {
    changeConfirmation(!confirm);
  };

  let actions: ReactNode = (
    <Grid container spacing={4}>
      <Grid item md={1} />
      <Grid item md={5}>
        <Button
          fullWidth
          disabled={!isFollowupState}
          onClick={handleChangeActions}
        >
          Send Message
        </Button>
      </Grid>
      <Grid item md={5}>
        <Button
          fullWidth
          variant="outlined"
          disabled={!isFollowupState}
          onClick={toggleConfirmation}
        >
          Called Patient
        </Button>
      </Grid>
    </Grid>
  );

  if (sendMessage) {
    actions = (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Avatar alt={userName} classes={{ root: classes.avatar }}>
            {userInitials}
          </Avatar>
        </Grid>
        <Grid item xs={7}>
          <TextField
            label="Write a message"
            value={message}
            onChange={handleChangeMessage}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            disabled={message.length === 0}
            onClick={toggleConfirmation}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (isCompletedState) {
    actions = null;

    if (sendMessage) {
      actions = (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Avatar alt={userName} classes={{ root: classes.avatar }}>
              {userInitials}
            </Avatar>
          </Grid>
          <Grid item xs={7}>
            <Typography>Sent SMS</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>Sent Date</Typography>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <VisitDetailCard
      address={visit.address?.summary}
      dob={typeof visit.patient === "string" ? undefined : visit.patient.dob}
      gender={
        typeof visit.patient === "string" ? undefined : visit.patient.gender
      }
      name={visit.patientName}
      payment={visit.payment}
      phone={visit.phone.length > 0 ? visit.phone : undefined}
    >
      <CardContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DefinedProperty title="Nurse">
              {visit.nurseName ?? "--"}
            </DefinedProperty>
          </Grid>
          <Grid item xs={4}>
            <DefinedProperty title="Status">{visit.status}</DefinedProperty>
          </Grid>
          <Grid item xs={4}>
            <DefinedProperty title="Nurse Cell">
              {visit.nursePhone ?? "--"}
            </DefinedProperty>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent className={classes.content}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TableContainer>
              <Table size="small" padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell>Service</TableCell>
                    <TableCell>Results</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visit.services.map((service: NurseVisitService | string) => (
                    <TableRow
                      key={typeof service === "string" ? service : service.id}
                    >
                      <TableCell component="th" scope="row">
                        {typeof service === "string" ? service : service.name}
                      </TableCell>
                      <TableCell>Pending</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Divider flexItem orientation="vertical" variant="fullWidth" />
          <Grid item xs={6} className={classes.afterDivider}>
            <DefinedProperty title="Timeline">
              {visit.history?.map((event) => (
                <Typography
                  key={event.date.toISOString()}
                  variant="h5"
                  className={classes.timeline}
                  component="div"
                >
                  {renderTime(event.date)} - {event.status}
                </Typography>
              ))}
            </DefinedProperty>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <DefinedProperty title="Source">{sourceIcon}</DefinedProperty>
          </Grid>
          <Grid item xs={5}>
            <DefinedProperty title="Provider">{providerName}</DefinedProperty>
          </Grid>
          <Grid item xs={5}>
            <DefinedProperty title="CC">
              {typeof visit.videoVisit !== "string"
                ? visit.videoVisit?.primaryComplaint
                : "--"}
            </DefinedProperty>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.content}>{actions}</CardActions>
      <ConfirmationModal
        instructions="Clicking Yes will mark this visit as closed out."
        open={confirm}
        onAccept={handleClose}
        onClose={toggleConfirmation}
        title="Are you sure?"
      />
    </VisitDetailCard>
  );
};
