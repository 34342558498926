import { assign } from "xstate";

import { CLOSE, FAIL, SUCCEED } from "./constants";
import { NurseVisitDetailsContext, NurseVisitDetailsEvents } from "./types";

export const saveError = assign<
  NurseVisitDetailsContext,
  NurseVisitDetailsEvents
>({
  errors: (_context, event) => (event.type !== FAIL ? [] : event.errors),
});

export const saveMessage = assign<
  NurseVisitDetailsContext,
  NurseVisitDetailsEvents
>({
  patientMessage: (_context, event) =>
    event.type !== CLOSE ? undefined : event.message,
});

export const saveVisit = assign<
  NurseVisitDetailsContext,
  NurseVisitDetailsEvents
>({
  errors: undefined,
  visit: (context, event) =>
    event.type !== SUCCEED ? context.visit : event.visit,
});
