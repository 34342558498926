import { assign } from "xstate";

import { FAIL, ONBOARD, SUCCEED } from "./constants";
import {
  OnboardingContext,
  OnboardingEvents,
  isDoneEvent,
  OnboardEvent,
} from "./types";

export const clearContext = assign<OnboardingContext, OnboardingEvents>({
  errors: [],
  onboarding: {
    dob: new Date(),
    firstName: "",
    lastName: "",
  },
  user: undefined,
});

export const saveError = assign<OnboardingContext, OnboardingEvents>({
  errors: (_context, event) =>
    isDoneEvent(event) ? [event.data.toString()] : _context.errors,
});

export const saveOnboarding = assign<OnboardingContext, OnboardingEvents>({
  onboarding: (context, event) =>
    event.type !== ONBOARD
      ? context.onboarding
      : (event as OnboardEvent).details,
});

export const saveProvider = assign<OnboardingContext, OnboardingEvents>({
  provider: (context, event) =>
    isDoneEvent(event) ? event.data : context.provider,
});
