import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";

import { PATH_LOGIN } from "./constants";
import { useAnalytics } from "./core";
import { useAppService } from "./state";

export const AuthenticatedRoute: FunctionComponent<RouteProps> = ({
  children,
  ...rest
}) => {
  const [{ isLoggedIn, user }] = useAppService();
  const location = useLocation();
  const analytics = useAnalytics();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN,
          state: { from: location },
        }}
      />
    );
  }

  analytics.login(user!);

  return <Route {...rest}>{children}</Route>;
};
