import { useState, useEffect } from "react";
import { LocalVideoTrack, RemoteVideoTrack } from "twilio-video";

import { RoomTrackEvent } from "./types";

type TrackType = RemoteVideoTrack | LocalVideoTrack;

// The 'switchedOff' event is emitted when there is not enough bandwidth to support
// a track. See: https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api#understanding-track-switch-offs

export const useIsTrackThrottled = (track?: TrackType) => {
  const [isSwitchedOff, setIsSwitchedOff] = useState(
    track?.isSwitchedOff ?? false,
  );

  useEffect(() => {
    // Reset the value if the 'track' variable changes
    setIsSwitchedOff(track?.isSwitchedOff ?? false);

    if (!track) {
      return () => null;
    }

    const toggleOff = () => setIsSwitchedOff(true);
    const toggleOn = () => setIsSwitchedOff(false);

    track.on(RoomTrackEvent.switchedOff, toggleOff);
    track.on(RoomTrackEvent.switchedOn, toggleOn);

    return () => {
      track.off(RoomTrackEvent.switchedOff, toggleOff);
      track.off(RoomTrackEvent.switchedOn, toggleOn);
    };
  }, [track]);

  return isSwitchedOff;
};
