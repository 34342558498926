import { FormControlLabelClassKey } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

import { typography } from "../typography";

export const MuiFormControlLabel: Partial<
  StyleRules<FormControlLabelClassKey>
> = {
  label: {
    ...typography.body2,
    letterSpacing: 0,
  },
};
