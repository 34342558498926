import { ConditionPredicate } from "xstate";

import { ONBOARD } from "./constants";
import { OnboardingContext, OnboardingEvents } from "./types";

export const isOnboardingValid: ConditionPredicate<
  OnboardingContext,
  OnboardingEvents
> = (_context, event): boolean => {
  if (event.type !== ONBOARD) {
    return false;
  }

  return true;
};
