import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AuthenticatedRoute } from "./authenticated-route";
import { AuthenticatedSwitch } from "./authenticated-switch";
import {
  ForgotEmailPage,
  ForgotPasswordPage,
  LoginPage,
  LogoutPage,
  ResetPasswordPage,
} from "./authentication";
import { ComingSoonPage } from "./components";
import {
  FLAG_ADMIN,
  FLAG_DASHBOARD,
  FLAG_NOTIFICATIONS,
  FLAG_NURSE_VISIT,
  FLAG_PAST_VISITS,
  FLAG_PROFILE,
  FLAG_VIDEO_VISIT,
  FLAG_VIEW_PATIENTS,
  PATH_ADMIN,
  PATH_CONSULTATION,
  PATH_DASHBOARD,
  PATH_FORGOT_EMAIL,
  PATH_FORGOT_PASSWORD,
  PATH_HOME,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_NOTIFICATIONS,
  PATH_NURSE_VISITS_STATUS,
  PATH_ONBOARD,
  PATH_PATIENTS,
  PATH_PROFILE,
  PATH_REGISTER,
  PATH_RESET_PASSWORD,
  PATH_VIDEO_VISITS,
  PATH_VISITS,
} from "./constants";
import { DashboardPage } from "./dashboard";
import { FlaggedRoute } from "./flagged-route";
import { HomePage } from "./home";
import { NurseVisitsPage } from "./nurse-visits";
import { OnboardingPage, ProfilePage, RegistrationPage } from "./users";
import { ExamRoom, VideoVisitsPage } from "./video-visits";

export const Routes = () => (
  <Switch>
    <Route path={PATH_LOGOUT}>
      <LogoutPage />
    </Route>
    <Route path={PATH_LOGIN}>
      <LoginPage />
    </Route>
    <Route path={PATH_REGISTER}>
      <RegistrationPage />
    </Route>
    <Route path={PATH_FORGOT_EMAIL}>
      <ForgotEmailPage />
    </Route>
    <Route path={PATH_FORGOT_PASSWORD}>
      <ForgotPasswordPage />
    </Route>
    <Route path={PATH_RESET_PASSWORD}>
      <ResetPasswordPage />
    </Route>
    <AuthenticatedRoute path={PATH_ONBOARD}>
      <OnboardingPage />
    </AuthenticatedRoute>
    <AuthenticatedSwitch>
      <FlaggedRoute flag={FLAG_NURSE_VISIT} path={PATH_NURSE_VISITS_STATUS}>
        <NurseVisitsPage />
      </FlaggedRoute>
      <FlaggedRoute flag={FLAG_VIDEO_VISIT} path={PATH_CONSULTATION}>
        <ExamRoom />
      </FlaggedRoute>
      <FlaggedRoute flag={FLAG_VIDEO_VISIT} path={PATH_VIDEO_VISITS}>
        <VideoVisitsPage />
      </FlaggedRoute>
      <FlaggedRoute flag={FLAG_PROFILE} path={PATH_PROFILE}>
        <ProfilePage />
      </FlaggedRoute>
      <FlaggedRoute exact flag={FLAG_PAST_VISITS} path={PATH_VISITS}>
        <ComingSoonPage title="Past Visits" />
      </FlaggedRoute>
      <FlaggedRoute flag={FLAG_VIEW_PATIENTS} path={PATH_PATIENTS}>
        <ComingSoonPage title="Patients Visits" />
      </FlaggedRoute>
      <FlaggedRoute flag={FLAG_NOTIFICATIONS} path={PATH_NOTIFICATIONS}>
        <ComingSoonPage title="Notifications" />
      </FlaggedRoute>
      <FlaggedRoute flag={FLAG_ADMIN} path={PATH_ADMIN}>
        <ComingSoonPage title="Manage Clinic" />
      </FlaggedRoute>
      <FlaggedRoute flag={FLAG_DASHBOARD} path={PATH_DASHBOARD}>
        <DashboardPage />
      </FlaggedRoute>
      <Route exact path={PATH_HOME}>
        <HomePage />
      </Route>
      <Route>
        <Redirect to={PATH_HOME} />
      </Route>
    </AuthenticatedSwitch>
  </Switch>
);
