import React, { FunctionComponent } from "react";

import { EditableList } from "../../components";
import { Allergy, Listable, VideoVisit } from "../../core";

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

export const Allergies: FunctionComponent<VisitDetail> = ({
  onChange = () => null,
  visit,
}) => {
  const handleChange = (newValue: Listable[]) => {
    onChange({
      healthHistory: {
        ...visit.healthHistory!,
        allergies: newValue as Allergy[],
      },
    });
  };

  return (
    <EditableList
      label="Allergies"
      onSave={handleChange}
      value={visit.healthHistory?.allergies ?? []}
    />
  );
};
