import { useState } from "react";
import { useDebounce } from "react-use";

import { validationDelay } from "../config";

import { ValidatorFunction } from "./types";

export type DateChangeHandler = (date: Date | null) => void;

export type ValidatedDateState = [Date | undefined, boolean, DateChangeHandler];

export const useValidatedDate = (
  initialValue: Date | undefined,
  validator: ValidatorFunction<Date | undefined>,
  initialValidation = true,
): ValidatedDateState => {
  const [value, changeValue] = useState(initialValue);
  const [isValid, changeValidation] = useState(initialValidation);

  useDebounce(() => changeValidation(validator(value)), validationDelay, [
    value,
  ]);

  const handleChangeValue = (date: Date | null): void => {
    if (date) {
      changeValue(date);
    }
  };

  return [value, isValid, handleChangeValue];
};
