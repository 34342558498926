import { Machine } from "xstate";

import { saveData, saveError, saveVisit } from "./actions";
import { CREATE, CREATING, FAIL, SAVED, SUCCEED, UNSAVED } from "./constants";
import { createVideoVisit } from "./promises";
import {
  CreateVideoVisitEvents,
  CreateVideoVisitContext,
  CreateVideoVisitSchema,
} from "./types";

export const createVideoVisitMachine = Machine<
  CreateVideoVisitContext,
  CreateVideoVisitSchema,
  CreateVideoVisitEvents
>(
  {
    id: "create-video-visit",
    initial: UNSAVED,
    context: {},
    states: {
      [UNSAVED]: {
        on: {
          [CREATE]: {
            target: CREATING,
            actions: "saveData",
          },
        },
      },
      [CREATING]: {
        invoke: {
          src: createVideoVisit,
          id: "create",
          onDone: {
            target: SAVED,
            actions: "saveVisit",
          },
          onError: {
            target: UNSAVED,
            actions: "saveError",
          },
        },
      },
      [SAVED]: {
        type: "final",
      },
    },
  },
  {
    actions: {
      saveData,
      saveError,
      saveVisit,
    },
  },
);
