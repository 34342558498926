import { MenuItem, TextField } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { Usage } from "../core";

export interface UsageSelectorProps {
  value: Usage | undefined;
  onChange: (usage: Usage) => void;
  label: string;
  id: string;
}

export const UsageSelector: FunctionComponent<UsageSelectorProps> = ({
  onChange,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    onChange(event.target.value as Usage);
  };

  return (
    <TextField select {...props} onChange={handleChange}>
      {Object.values(Usage).map((usage) => (
        <MenuItem key={usage} value={usage}>
          {usage}
        </MenuItem>
      ))}
    </TextField>
  );
};
