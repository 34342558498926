import { useService } from "@xstate/react";
import { useMemo } from "react";
import { interpret } from "xstate";

import { VideoVisit } from "../../../core";
import { logService } from "../../../helpers";

import { ACCEPT, UNFETCHED, FETCHING } from "./constants";
import { createVideoVisitDetailsMachine as machineFactory } from "./machine";
import { VideoVisitDetailsServiceHook } from "./types";

// Exposed API Below
export * from "./types";

export const useVideoVisitService = (
  videoVisit: VideoVisit,
): VideoVisitDetailsServiceHook => {
  const nurseVisitService = useMemo(() => {
    const machine = machineFactory(videoVisit);
    const service = interpret(machine).start();
    logService(`video-visit-${videoVisit.id}`, service);

    return service;
  }, [videoVisit]);

  const [state, send] = useService(nurseVisitService);

  const { errors, visit } = state.context;

  const didFail = useMemo(
    () => state.matches(UNFETCHED) && errors !== undefined,
    [state, errors],
  );
  const isFetching = useMemo(() => state.matches(FETCHING), [state]);

  const accept = () => {
    send(ACCEPT);
  };

  return [{ didFail, errors: errors ?? [], visit, isFetching }, { accept }];
};
