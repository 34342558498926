import React, { FunctionComponent, useState, useEffect } from "react";
import { Participant, Room } from "twilio-video";

import { SelectedParticipantContext } from "../../helpers";
import { RoomStateType } from "../../hooks";

type SelectedParticipantProviderProps = {
  room: Room;
};

export const SelectedParticipantProvider: FunctionComponent<SelectedParticipantProviderProps> =
  ({ room, children }) => {
    const [selectedParticipant, setSelectedParticipant] =
      useState<Participant | null>(null);

    const changeSelected = (participant?: Participant) =>
      setSelectedParticipant((prevParticipant) => {
        if (participant) {
          return prevParticipant === participant ? null : participant;
        }

        return prevParticipant;
      });

    useEffect(() => {
      const onDisconnect = () => setSelectedParticipant(null);

      room.on(RoomStateType.disconnected, onDisconnect);

      return () => {
        room.off(RoomStateType.disconnected, onDisconnect);
      };
    }, [room]);

    return (
      <SelectedParticipantContext.Provider
        value={[selectedParticipant!, changeSelected]}
      >
        {children}
      </SelectedParticipantContext.Provider>
    );
  };
