import {
  getVideoVisits,
  getVideoVisitsQueue,
  marshallVideoVisit,
  VideoVisit,
} from "../../../core";

export const fetchAllVideoVisits = async (): Promise<VideoVisit[]> => {
  try {
    const videoVisitsResponse = await getVideoVisits();
    const videoVisitQueueResponse = await getVideoVisitsQueue();
    return videoVisitsResponse.map((visit) => {
      const queueInfo = videoVisitQueueResponse.results.find(
        (value) => value.visit === visit.id,
      );
      return marshallVideoVisit(visit, queueInfo);
    });
  } catch (error) {
    const { messages } = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};
