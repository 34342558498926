import React, { FunctionComponent, useMemo } from "react";

import { Note, ProviderNote, VideoVisit } from "../core";

import { EditableField } from "./editable-field";

interface VisitDetail {
  onSave?: (visitInfo: Partial<VideoVisit>) => void;
  noteType: string;
  label: string;
  visit: VideoVisit;
}

const isProviderNote = (value: Note): value is ProviderNote =>
  (value as ProviderNote).addedAt !== undefined;

export const EditableNote: FunctionComponent<VisitDetail> = ({
  onSave: onChange = () => null,
  noteType,
  label,
  visit,
}) => {
  const providerNote = useMemo(() => {
    const filteredNotes = visit.notes.filter(
      (note) => note.noteType === noteType,
    );

    if (filteredNotes.length > 1) {
      filteredNotes.sort((a, b) => {
        const aDate = isProviderNote(a) ? a.addedAt : new Date();
        const bDate = isProviderNote(b) ? b.addedAt : new Date();

        if (aDate > bDate) {
          return 1;
        }

        if (aDate < bDate) {
          return -1;
        }

        return 0;
      });
    }

    return filteredNotes[filteredNotes.length - 1];
  }, [visit, noteType]);

  const handleChange = (newValue: string) => {
    onChange({
      notes: [
        {
          noteType,
          note: newValue,
        },
      ],
    });
  };

  return (
    <EditableField
      label={label}
      onSave={handleChange}
      value={providerNote?.note ?? ""}
    />
  );
};
