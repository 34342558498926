import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Clear, Done, Edit } from "@material-ui/icons";
import React, { FunctionComponent, useState, ReactNode } from "react";

import { useVisitContext } from "../hooks";

export interface EditableCardProps {
  content: ReactNode;
  editContent: ReactNode;
  footerActions?: ReactNode;
  label: string;
  onCancel?: () => void;
  onSave: () => void;
  isEditable?: boolean;
}

export const EditableCard: FunctionComponent<EditableCardProps> = ({
  content,
  editContent,
  label,
  footerActions,
  onCancel,
  onSave,
}) => {
  const visitContext = useVisitContext();
  const [isEditing, setMode] = useState<boolean>(
    visitContext.canEdit && !visitContext.isFinished,
  );

  const handleCancel = () => {
    setMode(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleSave = () => {
    setMode(false);
    onSave();
  };

  const handleToggle = () => {
    setMode(true);
  };

  let actions: ReactNode = null;

  if (visitContext.canEdit) {
    actions = (
      <Tooltip title={`Edit ${label}`}>
        <IconButton aria-label={`Edit ${label}`} onClick={handleToggle}>
          <Edit color="action" />
        </IconButton>
      </Tooltip>
    );
  }

  if (isEditing) {
    actions = (
      <>
        <Tooltip title={`Save ${label}`}>
          <IconButton aria-label={`Save ${label}`} onClick={handleSave}>
            <Done color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Reset ${label}`}>
          <IconButton aria-label={`Reset ${label}`} onClick={handleCancel}>
            <Clear color="secondary" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  let footer = null;

  if (footerActions) {
    footer = <CardActions>{footerActions}</CardActions>;
  }

  return (
    <Card variant="outlined">
      <CardHeader
        action={actions}
        title={label}
        titleTypographyProps={{ variant: "h3" }}
      />
      <CardContent>{isEditing ? editContent : content}</CardContent>
      {footer}
    </Card>
  );
};
