import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ArrowDropDown } from "@material-ui/icons";
import { Visit } from "@relieftelemed/platform";
import React, { FunctionComponent, useState } from "react";
import { FixedSizeList } from "react-window";

import { GROUPINGS } from "../hooks";

import { Button } from "./button";
import { VisitCardProps } from "./visit-quick-card";

interface VisitListProps {
  onSelect: (visit: Visit) => () => void;
  selected?: Visit;
  title: string;
  visits: Visit[];
  VisitCard: FunctionComponent<VisitCardProps>;
}

interface GroupedVisitListProps {
  onSelect: (visit: Visit) => () => void;
  selected?: Visit;
  groupLabels: Record<string, string>;
  activeClinicName: string;
  VisitCard: FunctionComponent<VisitCardProps>;
  groupedVisits: Record<GROUPINGS, Visit[]>;
}

interface VisitListItemProps {
  onSelect: (visit: Visit) => () => void;
  selected: boolean;
  visit: Visit;
  VisitCard: FunctionComponent<VisitCardProps>;
  style: {};
}

const useVisitListGroupStyles = makeStyles((theme) => ({
  title: {
    alignItems: "center",
    background: theme.palette.background.default,
    margin: `${theme.spacing(2)}px 0`,
    display: "flex",
  },
  virtualizedWrapper: {
    width: "99%", // avoids overflow-x
  },
}));

const VISIT_CARD_HEIGHT = 96;

export const VisitListItem: FunctionComponent<VisitListItemProps> = ({
  onSelect,
  selected,
  visit,
  VisitCard,
  style,
}) => {
  return (
    <ListItem key={visit.id} style={{ width: "100%", ...style }}>
      <VisitCard
        visit={visit}
        isSelected={selected}
        onClick={onSelect(visit)}
      />
    </ListItem>
  );
};

export const VisitListGroup: FunctionComponent<VisitListProps> = ({
  onSelect,
  selected,
  title,
  visits,
  VisitCard,
}) => {
  const classes = useVisitListGroupStyles();
  if (visits.length === 0) {
    return null;
  }
  return (
    <>
      <ListSubheader className={classes.title} component="div" disableGutters>
        <Box pr={1} flex="0 1 auto">
          <Typography display="inline" color="secondary">
            {title}
          </Typography>
        </Box>
        <Box pl={1} flex="1 0 auto">
          <Divider />
        </Box>
      </ListSubheader>
      <Grid
        container
        spacing={2}
        className={`visits-list visits-list-${title}`}
      >
        {visits.map((visit) => (
          <Grid key={visit.id} item xs={12}>
            <VisitCard
              visit={visit}
              isSelected={selected?.id === visit.id}
              onClick={onSelect(visit)}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export const GroupedVisitList: FunctionComponent<GroupedVisitListProps> = ({
  selected,
  VisitCard,
  onSelect,
  groupLabels: GROUP_LABELS,
  activeClinicName,
  groupedVisits,
}) => {
  const [selectedGroup, changeSelectedGroup] = useState(
    Object.keys(GROUP_LABELS)[0],
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useVisitListGroupStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelection = (label: string) => {
    changeSelectedGroup(label);
    setAnchorEl(null);
  };

  return (
    <>
      <ListSubheader className={classes.title} component="div" disableGutters>
        <Box pr={1} flex="0 1 auto">
          <Button
            variant="text"
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
            color="secondary"
          >
            {selectedGroup === GROUPINGS.CLINIC
              ? activeClinicName
              : GROUP_LABELS[selectedGroup]}
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {Object.keys(GROUP_LABELS).map((label) => (
              <MenuItem key={label} onClick={() => handleItemSelection(label)}>
                {GROUP_LABELS[label]}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box pl={1} flex="1 0 auto">
          <Divider />
        </Box>
      </ListSubheader>
      <FixedSizeList
        itemData={{
          selected,
          VisitCard,
        }}
        height={725}
        itemCount={groupedVisits[selectedGroup as GROUPINGS].length}
        itemSize={VISIT_CARD_HEIGHT}
        width="100%"
        className="visits-list"
      >
        {({ index, style }) => (
          <VisitListItem
            onSelect={onSelect}
            visit={groupedVisits[selectedGroup as GROUPINGS][index]}
            style={style}
            VisitCard={VisitCard}
            selected={
              selected?.id ===
              groupedVisits[selectedGroup as GROUPINGS][index].id
            }
          />
        )}
      </FixedSizeList>
    </>
  );
};
