import { assign } from "xstate";

import {
  VideoVisitDetailsContext,
  VideoVisitDetailsEvents,
  isDoneEvent,
} from "./types";

export const saveError = assign<
  VideoVisitDetailsContext,
  VideoVisitDetailsEvents
>({
  errors: (_context, event) =>
    event.type !== "error.execution" ? _context.errors : event.data,
});

export const saveVisit = assign<
  VideoVisitDetailsContext,
  VideoVisitDetailsEvents
>({
  errors: undefined,
  visit: (context, event) => (isDoneEvent(event) ? event.data : context.visit),
});
