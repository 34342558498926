import { useEffect, useState } from "react";
import { Participant } from "twilio-video";

import { isScreensharePublicationTrack } from "../../helpers";

import { RoomParticipantEvent, RoomStateType, RoomTrackEvent } from "./types";
import { useVideoContext } from "./use-video-context";

const noOp = () => null;

export const useScreenShareParticipant = (includeLocal = true) => {
  const { room } = useVideoContext();
  const [screenShareParticipant, setScreenShareParticipant] =
    useState<Participant>();

  useEffect(() => {
    if (room.state !== RoomStateType.connected) {
      return noOp;
    }
    const participants = Array.from<Participant>(room.participants.values());
    if (includeLocal) {
      participants.push(room.localParticipant);
    }

    const updateScreenShare = () => {
      setScreenShareParticipant(
        participants.find((participant: Participant) =>
          Array.from(participant.tracks.values()).find(
            isScreensharePublicationTrack,
          ),
        ),
      );
    };

    updateScreenShare();

    room.on(RoomTrackEvent.published, updateScreenShare);
    room.on(RoomTrackEvent.unpublished, updateScreenShare);
    room.on(RoomParticipantEvent.disconnected, updateScreenShare);

    room.localParticipant.on(
      RoomTrackEvent.published,
      includeLocal ? updateScreenShare : noOp,
    );
    room.localParticipant.on(
      RoomTrackEvent.unpublished,
      includeLocal ? updateScreenShare : noOp,
    );

    return () => {
      room.off(RoomTrackEvent.published, updateScreenShare);
      room.off(RoomTrackEvent.unpublished, updateScreenShare);
      room.off(RoomParticipantEvent.disconnected, updateScreenShare);

      room.localParticipant.off(
        RoomTrackEvent.published,
        includeLocal ? updateScreenShare : noOp,
      );
      room.localParticipant.off(
        RoomTrackEvent.unpublished,
        includeLocal ? updateScreenShare : noOp,
      );
    };
  }, [includeLocal, room]);

  return screenShareParticipant;
};
