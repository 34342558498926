import { assign, ErrorExecutionEvent } from "xstate";

import { CREATE, FAIL } from "./constants";
import {
  RegistrationContext,
  RegistrationEvents,
  isDoneEvent,
  CreateEvent,
  SuccesfullRegistration,
} from "./types";

export const clearContext = assign<RegistrationContext, RegistrationEvents>({
  errors: [],
  email: "",
  password: "",
  confirmation: "",
  mobile: "",
  acceptedTerms: false,
});

export const saveError = assign<RegistrationContext, RegistrationEvents>({
  errors: (_context, event) =>
    isDoneEvent(event)
      ? [(event as ErrorExecutionEvent).data.toString()]
      : _context.errors,
});

export const saveRegistration = assign<RegistrationContext, RegistrationEvents>(
  {
    acceptedTerms: (_context, event) =>
      event.type !== CREATE ? false : (event as CreateEvent).acceptedTerms,
    confirmation: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).confirmation,
    email: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).email,
    mobile: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).mobile,
    password: (_context, event) =>
      event.type !== CREATE ? "" : (event as CreateEvent).password,
  },
);

export const saveUser = assign<RegistrationContext, RegistrationEvents>({
  user: (_context, event) =>
    isDoneEvent(event)
      ? (event.data as SuccesfullRegistration).user
      : undefined,
  token: (_context, event) =>
    isDoneEvent(event)
      ? (event.data as SuccesfullRegistration).token
      : undefined,
});
