import { ComponentsProps } from "@material-ui/core/styles/props";

import { MuiButton } from "./button";
import { MuiDialogContentText } from "./dialog-content-text";
import { MuiTextField } from "./text-field";

export const props: ComponentsProps = {
  MuiButton,
  MuiDialogContentText,
  MuiTextField,
};
