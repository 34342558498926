import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import { Listable, ListableToggle } from "@relieftelemed/platform";
import React, {
  FunctionComponent,
  useEffect,
  useState,
  ChangeEventHandler,
  ReactNode,
} from "react";

import { EditableCard } from "./editable-card";

type ListableType = Listable | ListableToggle;

interface ListableProps {
  edit?: boolean;
  items: ListableType[];
  togglable?: boolean;
  onAddItem?: (item: ListableType) => void;
  onChangeItem?: (
    item: ListableType,
  ) => (change?: Partial<ListableType>) => void;
  onToggleItem?: (item: ListableType) => () => void;
}

const isListableSwitchable = (item: Listable): item is ListableToggle =>
  (item as ListableToggle).active !== undefined;

const ListableList: FunctionComponent<ListableProps> = ({
  edit,
  items,
  onAddItem,
  onChangeItem,
  onToggleItem,
  togglable,
}) => {
  const [newItem, setNewItem] = useState("");
  const handleNewChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setNewItem(event.target.value);
  };
  const handleAdd = () => {
    const baseItem = {};

    if (togglable || (items[0] && isListableSwitchable(items[0]))) {
      (baseItem as ListableToggle).active = true;
    }

    if (onAddItem) {
      onAddItem({
        name: newItem,
        id: newItem.replace(/\s/g, "_").toLowerCase(),
        ...baseItem,
      } as ListableType);
    }

    setNewItem("");
  };

  const addItem = (
    <ListItem>
      <ListItemText disableTypography>
        <TextField value={newItem} onChange={handleNewChange} />
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton onClick={handleAdd}>
          <Add color="primary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <List>
      {items.map((item) => {
        let action;
        let content = <ListItemText primary={item.name} />;

        if (isListableSwitchable(item)) {
          action = (
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                disabled={!edit}
                onChange={onToggleItem!(item)}
                checked={item.active}
              />
            </ListItemSecondaryAction>
          );
        }

        if (edit) {
          const changeItem = onChangeItem!(item);
          const handleChange: ChangeEventHandler<HTMLInputElement> = (event) =>
            changeItem({ name: event.target.value });

          const handleDelete = () => changeItem();

          action = (
            <ListItemSecondaryAction>
              <IconButton onClick={handleDelete}>
                <Delete color="error" />
              </IconButton>
            </ListItemSecondaryAction>
          );
          content = (
            <ListItemText disableTypography>
              <TextField value={item.name} onChange={handleChange} />
            </ListItemText>
          );
        }

        return (
          <ListItem key={item.id}>
            {content}
            {action}
          </ListItem>
        );
      })}
      {edit ? addItem : null}
    </List>
  );
};

export interface EditableListProps {
  label: string;
  value: ListableType[];
  onSave: (newValue: Listable[]) => void;
  togglable?: boolean;
  footerActions?: ReactNode;
}

export const EditableList: FunctionComponent<EditableListProps> = ({
  label,
  value: initialValue,
  onSave,
  togglable,
  footerActions,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChangeItem =
    (item: ListableType) => (change?: Partial<ListableType>) => {
      setValue((prevValue) =>
        prevValue
          .map((prevItem) => {
            if (prevItem.id !== item.id) {
              return prevItem;
            }

            if (!change) {
              return false;
            }

            return {
              ...prevItem,
              ...change,
            };
          })
          .filter((maybeItem): maybeItem is Listable => maybeItem !== false),
      );
    };

  const handleAddItem = (item: ListableType) => {
    setValue((prevValue) => prevValue.concat(item));
  };

  const handleToggleItem = (item: ListableType) => () => {
    onSave(
      value.map((prevItem) => {
        if (prevItem.id !== item.id) {
          return prevItem;
        }

        return {
          ...prevItem,
          active: !(item as ListableToggle).active,
        };
      }),
    );
  };

  const handleCancel = () => {
    setValue(initialValue);
  };

  const handleSave = () => {
    onSave(value);
  };

  return (
    <EditableCard
      content={<ListableList items={value} onToggleItem={handleToggleItem} />}
      editContent={
        <ListableList
          items={value}
          edit
          onAddItem={handleAddItem}
          onChangeItem={handleChangeItem}
          onToggleItem={handleToggleItem}
          togglable={togglable}
        />
      }
      label={label}
      onCancel={handleCancel}
      onSave={handleSave}
      footerActions={footerActions}
    />
  );
};
