import { useContext } from "react";
import { Participant } from "twilio-video";

import { SelectedParticipantContext } from "../../helpers";

type SetSelectedParticipant = (participant?: Participant | undefined) => void;
type SelectedParticipantHook = [Participant, SetSelectedParticipant];

export const useSelectedParticipant = (): SelectedParticipantHook => {
  const [selectedParticipant, setSelectedParticipant] = useContext(
    SelectedParticipantContext,
  );
  return [selectedParticipant, setSelectedParticipant];
};
