import { Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import {
  ClockTimer,
  DefinedProperty,
  useQuickCardStyles,
  VisitCardProps,
  VisitQuickCard,
} from "../../components";
import { VideoVisit } from "../../core";

export const VideoVisitCard: FunctionComponent<VisitCardProps> = ({
  visit: rawVisit,
  ...props
}) => {
  const classes = useQuickCardStyles();
  const visit = rawVisit as VideoVisit;

  return (
    <VisitQuickCard {...props} visit={rawVisit}>
      <Grid
        item
        xs={4}
        className={`${classes.content} ${classes.afterDivider}`}
      >
        <DefinedProperty title="Chief Complaint" noWrap>
          {visit.primaryComplaint}
        </DefinedProperty>
      </Grid>
      <Grid item xs={3} className={classes.content}>
        <DefinedProperty title="Provider">{visit.providerName}</DefinedProperty>
      </Grid>
      <Grid item xs={1} className={classes.content}>
        <DefinedProperty title={visit.status} align="center">
          <Typography component="p" variant="h4">
            <ClockTimer date={visit.updatedAt} />
          </Typography>
        </DefinedProperty>
      </Grid>
    </VisitQuickCard>
  );
};
