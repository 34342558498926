import React, { FunctionComponent, MouseEvent, ReactNode } from "react";

import { Alert, NotificationTrigger } from "../../components";

import { CreateNurseVisitModal } from "./create";
import { SelectNurseVisitServicesModal } from "./select-services";
import { useCreateNurseVisitMachine } from "./state";

interface StartNurseVisitFormProps {
  onCancel: () => void;
}

export const StartNurseVisitForm: FunctionComponent<StartNurseVisitFormProps> =
  ({ onCancel }) => {
    const [{ didFail, errors, services, visit }, { create, update }] =
      useCreateNurseVisitMachine();

    const handleDismissNotice = () => null;

    const handleClose = () => {
      if (onCancel) {
        onCancel();
      }
    };

    let modal: ReactNode = null;

    if (visit) {
      modal = (
        <SelectNurseVisitServicesModal
          nurseVisit={visit}
          onClose={handleClose}
          onSubmit={update}
          services={services}
          open
        />
      );
    } else {
      modal = (
        <CreateNurseVisitModal onClose={handleClose} onSubmit={create} open />
      );
    }

    return (
      <>
        {modal}
        <NotificationTrigger open={didFail} onClose={handleDismissNotice}>
          <Alert
            message={errors[0] ?? "Generic Error"}
            onDismiss={handleDismissNotice}
          />
        </NotificationTrigger>
      </>
    );
  };
