export enum RoomStateType {
  disconnected = "disconnected",
  connected = "connected",
  connecting = "connecting",
  reconnecting = "reconnecting",
}

export enum RoomParticipantEvent {
  speakerChanged = "dominantSpeakerChanged",
  connected = "participantConnected",
  disconnected = "participantDisconnected",
  qualityChanged = "networkQualityLevelChanged",
  reconnected = "reconnected",
  reconnecting = "reconnecting",
}

export enum RoomPublicationEvent {
  subscribed = "subscribed",
  unsubscribed = "unsubscribed",
}

export enum RoomTrackEvent {
  disabled = "disabled",
  enabled = "enabled",
  published = "trackPublished",
  unpublished = "trackUnpublished",
  switchedOff = "switchedOff",
  switchedOn = "switchedOn",
  failed = "trackPublicationFailed",
}
