import { makeStyles, StepIconProps, SvgIcon } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { accent } from "../styles";

const useSuccessStepStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.success.main,
    borderRadius: "50%",
    display: "flex",
    height: 28,
    width: 28,
  },
  icon: {
    color: theme.palette.common.white,
    height: 20,
    margin: "0 auto",
    width: 20,
  },
}));

export const SuccessIcon: FunctionComponent = () => {
  const classes = useSuccessStepStyles();

  return (
    <div className={classes.root}>
      <Check className={classes.icon} />
    </div>
  );
};

const useActiveStepStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    display: "block",
    stroke: accent,
    height: 28,
    width: 28,
  },
  text: {
    fill: accent,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
  },
}));

export const ActiveIcon: FunctionComponent<StepIconProps> = ({ icon }) => {
  const classes = useActiveStepStyles();

  return (
    <SvgIcon className={classes.root}>
      <circle cx="12" cy="12" r="11" strokeWidth="2" />
      <text className={classes.text} x="12" y="16" textAnchor="middle">
        {icon}
      </text>
    </SvgIcon>
  );
};

const useInactiveStepStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    display: "block",
    stroke: theme.palette.action.disabled,
    height: 28,
    width: 28,
  },
  text: {
    fill: theme.palette.action.disabled,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.caption.fontSize,
  },
}));

export const InactiveIcon: FunctionComponent<StepIconProps> = ({ icon }) => {
  const classes = useInactiveStepStyles();

  return (
    <SvgIcon className={classes.root}>
      <circle cx="12" cy="12" r="11" strokeWidth="2" />
      <text className={classes.text} x="12" y="16" textAnchor="middle">
        {icon}
      </text>
    </SvgIcon>
  );
};
