import {
  Box,
  makeStyles,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  StepProps,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { ActiveIcon, InactiveIcon, SuccessIcon } from "../../components";

export interface OnboardingProps {
  step: number;
}

const ConditionalStepIcon: FunctionComponent<StepIconProps> = ({
  active,
  completed,
  ...props
}) => {
  if (active) {
    return <ActiveIcon {...props} />;
  }

  if (completed) {
    return <SuccessIcon {...props} />;
  }

  return <InactiveIcon {...props} />;
};

const StyledStep: FunctionComponent<StepProps> = ({ children, ...props }) => {
  return (
    <Step {...props}>
      <StepLabel StepIconComponent={ConditionalStepIcon}>{children}</StepLabel>
    </Step>
  );
};

const CustomConnector = () => <Box height="1.75rem" />;

const useStepperStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
  },
}));

export const OnboardingSteps: FunctionComponent<OnboardingProps> = ({
  step = 0,
}) => (
  <Stepper
    activeStep={step}
    classes={useStepperStyles()}
    connector={<CustomConnector />}
    orientation="vertical"
  >
    <StyledStep>Create Login</StyledStep>
    <StyledStep>Verify Mobile</StyledStep>
    <StyledStep>Account Details</StyledStep>
  </Stepper>
);
