import { useEffect, useState } from "react";
import { Participant } from "twilio-video";

import { RoomParticipantEvent } from "./types";

export const useIsParticipantReconnecting = (participant: Participant) => {
  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    if (!participant) {
      return () => null;
    }

    const handleReconnecting = () => setIsReconnecting(true);
    const handleReconnected = () => setIsReconnecting(false);

    participant.on(RoomParticipantEvent.reconnecting, handleReconnecting);
    participant.on(RoomParticipantEvent.reconnected, handleReconnected);

    return () => {
      participant.off(RoomParticipantEvent.reconnecting, handleReconnecting);
      participant.off(RoomParticipantEvent.reconnected, handleReconnected);
    };
  }, [participant]);

  return isReconnecting;
};
