import {
  acceptVideoVisit as acceptVisit,
  getInsuranceProvidersPromise,
  getVideoVisitPromise,
  getVideoVisitsQueue,
  getPatientInsurancePromise,
  getPatientVisitsPromise,
  logger,
  marshallInsurance,
  marshallVideoVisit,
  VideoVisit,
} from "../../../core";

import { VideoVisitDetailsContext, VideoVisitDetailsEvents } from "./types";

export const acceptVideoVisit = async (
  context: VideoVisitDetailsContext,
): Promise<VideoVisit> => {
  try {
    const response = await acceptVisit(context.visit!.id);
    return marshallVideoVisit(response);
  } catch (error) {
    const { messages } = await error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};

const fetchInsurance = async (patientId: string) => {
  try {
    const patienceInsuranceResponse = await getPatientInsurancePromise(
      patientId,
    );
    try {
      const providers = await getInsuranceProvidersPromise();
      const provider = providers.find(
        (maybeProvider) => maybeProvider.id === patienceInsuranceResponse.payer,
      );
      return marshallInsurance(patienceInsuranceResponse, provider);
    } catch (insuranceProvError) {
      logger.warn("Error when fetching for providers", insuranceProvError);
      return undefined;
    }
  } catch (patienceInsuranceError) {
    const { messages } = await patienceInsuranceError.response.json();
    logger.warn(Object.values(messages).flat().join(", "));
    return undefined;
  }
};

const fetchPastVisits = async (patientId: string, visitDate: Date) => {
  try {
    const videoVisitResponse = await getPatientVisitsPromise(patientId);
    return videoVisitResponse.reduce((aggregate: VideoVisit[], visit) => {
      const newVisit = marshallVideoVisit(visit);
      if (newVisit.createdAt >= visitDate) {
        return aggregate;
      }
      return [...aggregate, newVisit];
    }, []);
  } catch (error) {
    logger.warn(`Error is ${error}`);
    return undefined;
  }
};

export const fetchDetails = async (
  context: VideoVisitDetailsContext,
): Promise<VideoVisit> => {
  try {
    const videoVisitResult = await getVideoVisitPromise(context.visit!.id);

    try {
      const videoVisitQueueResult = await getVideoVisitsQueue();
      const queue =
        videoVisitQueueResult.count > 0 ? videoVisitQueueResult.results : [];

      const queueInfo = queue.find(
        (value) => value.visit === videoVisitResult.id,
      );

      const videoVisit = marshallVideoVisit(videoVisitResult, queueInfo);
      const patientId =
        typeof videoVisit.patient === "string"
          ? videoVisit.patient
          : videoVisit.patient?.id ?? false;

      logger.debug("Does Video Visit have a Patient ID", patientId);

      if (!patientId) {
        logger.debug("No patient ID, so returning a success event");
        return videoVisit;
      }

      const insurance = await fetchInsurance(patientId);
      const pastVisits = await fetchPastVisits(patientId, videoVisit.createdAt);

      logger.debug("Received insurance response", insurance);

      const { payment } = videoVisit;
      if (payment && insurance) {
        const { groupId, memberId, planId, provider, type } = insurance;
        payment.active = true;
        payment.groupId = groupId;
        payment.memberId = memberId;
        payment.planId = planId;
        payment.provider = provider;
        if (type) {
          payment.type = type;
        }

        logger.debug("Altering video visit insurance information", payment);
      }

      return {
        ...videoVisit,
        pastVisits,
        payment,
      };
    } catch (error) {
      logger.error("Visist queue error", error);
      throw error;
    }
  } catch (error) {
    const errorBody = await error.response.json();
    return Promise.reject(new Error(errorBody.messages.flat().join(", ")));
  }
};
