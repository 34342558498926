import { Grid, TextField, Box } from "@material-ui/core";
import {
  License,
  Provider,
  validateDob,
  validateExpiration,
  validateMinLength,
  validateState,
} from "@relieftelemed/platform";
import { DropzoneArea } from "material-ui-dropzone";
import React, { FormEventHandler, FunctionComponent, useState } from "react";

import { curryValidator, toBase64 } from "../helpers";
import {
  useValidatedDate,
  useValidatedPhone,
  useValidatedState,
} from "../hooks";

import { ActionButton } from "./action-button";
import { CredentialSelector } from "./credential-selector";
import { DateInput } from "./date-input";
import { FormCard } from "./form-card";
import { StateSelector } from "./state-selector";

export interface ProfileData {
  firstName: string;
  lastName: string;
  dob: Date;
  fax?: string;
  credentials?: string[];
  license: License;
  npiNumber?: string;
  phone: string;
  signatureElectronic?: string;
}

interface ProfileFormProps {
  provider?: Provider;
  onSubmit: (provider: ProfileData) => void;
  actionLabel?: string;
}

export const ProfileForm: FunctionComponent<ProfileFormProps> = ({
  provider,
  onSubmit,
  actionLabel = "Save",
}) => {
  const [firstName, isFirstNameValid, changeFirstName] = useValidatedState(
    provider?.firstName,
    curryValidator<string>(validateMinLength),
  );
  const [lastName, isLastNameValid, changeLastName] = useValidatedState(
    provider?.lastName,
    curryValidator<string>(validateMinLength),
  );
  const [fax, isFaxValid, changeFax] = useValidatedPhone(provider?.fax);
  const [phone, isPhoneValid, changePhone] = useValidatedPhone(provider?.phone);
  const [dob, isDobValid, changeDob] = useValidatedDate(
    provider?.dob,
    curryValidator<Date | undefined>(validateDob),
  );

  const [state, isStateValid, changeState] = useValidatedState(
    provider?.license?.state,
    curryValidator<string>(validateState),
  );
  const [licenseType, isTypeValid, changeType] = useValidatedState(
    provider?.credentials[0]?.id,
    curryValidator<string>(validateMinLength),
  );
  const [licenseNumber, isLicenseNumberValid, changeLicenseNumber] =
    useValidatedState(
      provider?.license.number,
      curryValidator<string>(validateMinLength),
    );

  const [expiration, isExpirationValid, changeExpiration] = useValidatedDate(
    provider?.license.expiration,
    curryValidator<Date | undefined>(validateExpiration),
  );
  const [npiNumber, isNpiNumberValid, changeNpiNumber] = useValidatedState(
    provider?.npId,
    curryValidator<string>(validateMinLength),
  );

  const [signature, setSignature] = useState<string | undefined>();

  const allowSubmit = [
    isFirstNameValid,
    firstName.length > 0,
    isLastNameValid,
    lastName.length > 0,
    isDobValid,
    isFaxValid,
    isTypeValid,
    isStateValid,
    isLicenseNumberValid,
    isExpirationValid,
    isNpiNumberValid,
  ].every(Boolean);

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    onSubmit({
      firstName,
      lastName,
      fax,
      phone,
      dob: dob!,
      credentials: [licenseType],
      license: {
        expiration: expiration!,
        number: licenseNumber,
        state,
      },
      npiNumber,
      signatureElectronic: signature,
    });
  };

  const handleSignatureUploaded = (files: File[]) => {
    if (files.length > 0) {
      const [file] = files;
      toBase64(file).then(setSignature);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <FormCard elevation={0} title="Contact Information" actions={null}>
        <Grid item xs={12} md={6}>
          <TextField
            id="first-name"
            onChange={changeFirstName}
            value={firstName}
            label="First Name"
            required
            autoFocus
            error={!isFirstNameValid}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="last-name"
            onChange={changeLastName}
            value={lastName}
            label="Last Name"
            required
            error={!isLastNameValid}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateInput
            id="dob"
            value={dob ?? null}
            onChange={changeDob}
            autoOk
            disableFuture
            format="MM/dd/yyyy"
            label="Date of Birth"
            error={!isDobValid}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="fax"
            onChange={changeFax}
            value={fax}
            label="Fax Number"
            error={!isFaxValid}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="phone"
            onChange={changePhone}
            value={phone}
            label="Phone Number"
            error={!isPhoneValid}
          />
        </Grid>
      </FormCard>
      <FormCard elevation={0} title="License Information" actions={null}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <StateSelector
              id="license-state"
              onChange={changeState}
              value={state}
              label="State"
              error={!isStateValid}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CredentialSelector
              id="license-type"
              onChange={changeType}
              value={licenseType}
              label="License Type"
              error={!isTypeValid}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="license-number"
              onChange={changeLicenseNumber}
              value={licenseNumber}
              label="License Number"
              error={!isLicenseNumberValid}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              id="license-expiration"
              value={expiration ?? null}
              onChange={changeExpiration}
              autoOk
              disablePast
              format="MM/dd/yyyy"
              label="Expiration Date"
              error={!isExpirationValid}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="npi-number"
              onChange={changeNpiNumber}
              value={npiNumber}
              label="NPI Number"
              error={!isNpiNumberValid}
            />
          </Grid>
        </Grid>
      </FormCard>
      <FormCard
        elevation={0}
        title="Signature"
        actions={
          <ActionButton type="submit" disabled={!allowSubmit}>
            {actionLabel}
          </ActionButton>
        }
      >
        <DropzoneArea
          onChange={handleSignatureUploaded}
          filesLimit={1}
          acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        />
      </FormCard>
    </Box>
  );
};
