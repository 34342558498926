import {
  createNurseVisit as createNurseVisitApi,
  getNurseVisitServices,
  isApiError,
  marshallNurseVisit,
  marshallNurseVisitService,
  NurseVisit,
  unmarshallNurseVisit,
  updateNurseVisit as updateNurseVisitApi,
  NurseVisitService as Service,
} from "../../../core";

import { CreateNurseVisitContext } from "./types";

export const createNurseVisit = async (
  context: CreateNurseVisitContext,
): Promise<NurseVisit> => {
  try {
    const response = await createNurseVisitApi(
      unmarshallNurseVisit(context.data as Partial<NurseVisit>),
    );
    return marshallNurseVisit(response);
  } catch (error) {
    const errorResponse = error.response.json();
    return Promise.reject(
      new Error(Object.values(errorResponse.messages).flat().join(", ")),
    );
  }
};

export const fetchServices = async (): Promise<Service[]> => {
  try {
    const response = await getNurseVisitServices();
    return response.results.map(marshallNurseVisitService);
  } catch (_error) {
    return [];
  }
};

export const updateNurseVisit = async (
  context: CreateNurseVisitContext,
): Promise<NurseVisit> => {
  try {
    const response = await updateNurseVisitApi(context.visit!.id)(
      unmarshallNurseVisit(context.visit!),
    );
    return marshallNurseVisit(response);
  } catch (error) {
    const { messages } = error.response.json();
    return Promise.reject(new Error(Object.values(messages).flat().join(", ")));
  }
};
