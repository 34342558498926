import { Videocam, VideocamOff } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { useVideoToggle } from "../../hooks";

import { ControlButton } from "./control-button";

export const ControlVideo: FunctionComponent = () => {
  const [isVideoEnabled, toggleVideo] = useVideoToggle();

  return (
    <ControlButton
      onClick={toggleVideo}
      tooltip={isVideoEnabled ? "Turn off camera" : "Turn on camera"}
    >
      {isVideoEnabled ? (
        <Videocam color="primary" />
      ) : (
        <VideocamOff color="error" />
      )}
    </ControlButton>
  );
};
