import {
  setAppId,
  setApiBaseUrl,
  setMinLogLevel,
} from "@relieftelemed/platform";

import { appId, minLogLevel, platformUrl } from "../config";

setApiBaseUrl(platformUrl);
setAppId(appId);
setMinLogLevel(minLogLevel);
